import './index.css';
import 'react-toastify/dist/ReactToastify.css';

import { IntercomChat } from 'components/IntercomChat/IntercomChat';
import { CookieBanner } from 'components/layout/cookies/CookieBanner';
import { DesktopApp } from 'DesktopApp';
import { useWindowDimensions } from 'hooks';
import { useGsapConfig } from 'hooks/animations/useGsapConfig';
import { useCryptoDepositPolling } from 'hooks/polling/useCryptoDepositPolling';
import { useConsentManager } from 'hooks/userInteractions/useConsentManager';
import { useUserSessionTracking } from 'hooks/userInteractions/useUserSessionTracking';
import { useUTMParamsToDataLayer } from 'hooks/userInteractions/useUTMParamsToDataLayer';
import { MobileApp } from 'MobileApp';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useAppStore } from 'store/useAppStore';
import { useHistoryStore } from 'store/useHistoryStore';
import { isMobile } from 'utils';
import { shallow } from 'zustand/shallow';

export function App() {
	const location = useLocation();
	const previousURL = location.state?.prevUrl || null; // for specific behavior if prevUrl was a specific one

	const { screenWidth } = useWindowDimensions();
	//usePageTracking(user?._id);

	useGsapConfig();

	useCryptoDepositPolling();

	const { showBanner: showConsentBanner, handleConsent } = useConsentManager();
	useUTMParamsToDataLayer();
	useUserSessionTracking();
	//useUserTracking();

	const restoreScrollPosition = useAppStore((state) => state.restoreScrollPosition, shallow);

	const addToHistory = useHistoryStore((state) => state.addToHistory, shallow);

	useEffect(() => {
		return () => {
			addToHistory(location.pathname); // Add new path to history
		};
	}, [location.pathname, addToHistory]);

	useEffect(() => {
		const key = location.pathname;

		// Restore scroll position when the location changes
		const position = restoreScrollPosition(key);
		if (position !== undefined) {
			window.scrollTo(0, position);
		} else if (
			!location.pathname.includes('boxes/open/') &&
			!previousURL?.includes('boxes/open/') &&
			!previousURL?.includes('transaction')
		) {
			window.scrollTo(0, 0); // Scroll to top for new pages
		}
	}, [location.pathname, previousURL, restoreScrollPosition]);

	return (
		<>
			<IntercomChat />

			{showConsentBanner && (
				<CookieBanner onAccept={() => handleConsent(true)} onDecline={() => handleConsent(false)} />
			)}

			{isMobile(screenWidth) && <MobileApp />}
			{!isMobile(screenWidth) && <DesktopApp />}
		</>
	);
}
