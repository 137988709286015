import { TrophyIcon } from 'assets/images';
import { useWindowDimensions } from 'hooks';
import { LiveDropCard } from 'pages/overview/components/LiveDropCard';
import { LiveDropSkeleton } from 'pages/overview/components/LiveDropSkeleton';
import { useLiveBoxDrops } from 'pages/overview/hooks/liveDrops/useLiveBoxDrops';
import { useLiveDropAnimation } from 'pages/overview/hooks/liveDrops/useLiveDropAnimation';
import { useRef } from 'react';

import { screens } from '../../../../constants';

const DROP_SYNC_DELAY = 20000; // 20 seconds
const NUM_SKELETON_XS = 10;
const NUM_SKELETON_MS = 20;

export function LiveDrops() {
	const { drops, isLoading } = useLiveBoxDrops({ refetchInterval: DROP_SYNC_DELAY });

	const containerRef = useRef<HTMLDivElement>(null);

	useLiveDropAnimation({ containerRef, drops, dropSyncDelay: DROP_SYNC_DELAY });

	const { screenWidth } = useWindowDimensions();
	const numSkeleton = screenWidth >= screens.ms.width ? NUM_SKELETON_MS : NUM_SKELETON_XS;

	return (
		<div className="w-full mt-[22px] xl:mt-[28px]">
			<div
				className="w-full flex gradient-mask-r-[rgba(0,0,0,1.0)_80%] h-[85px] mds:h-[95px] lg:h-[105px] xl:h-[125px]"
				style={{
					WebkitMaskImage: 'linear-gradient(90deg, rgba(0, 0, 0) 75%, rgba(0, 0, 0, 0))',
					maskImage: 'linear-gradient(90deg, rgba(0, 0, 0) 75%, rgba(0, 0, 0, 0))',
				}}
			>
				<div className="relative flex flex-col bg-primary-gradient justify-center items-center gap-y-[7px] h-full border-r-[2px] border-[#85a7e7] rounded-[14px_0_0_14px] shadow-[2px_0_3px_rgba(0,0,0,0.3)] z-10">
					<img src={TrophyIcon} className="h-[28px] mds:h-[30px] lg:h-[33px] xl:h-[36px] w-auto" alt="" />
					<h2 className="font-bold text-white/80 font-inter text-[10px] mds:text-[11px] lg:text-[13px] xl:text-[15px] text-center w-full">
						LIVE WINS
					</h2>
				</div>
				<div className="relative overflow-x-hidden h-full w-full max-w-full ">
					<div ref={containerRef} className="flex items-center gap-x-[1px] justify-start h-full will-change-transform">
						{isLoading
							? Array.from({ length: numSkeleton }).map((_, index) => <LiveDropSkeleton key={index} />)
							: drops && drops.map((dropItem) => <LiveDropCard liveBoxWinning={dropItem} key={dropItem._id} />)}
					</div>
				</div>
			</div>
		</div>
	);
}
