import { useOutsideClick, useScrollLock } from 'hooks';
import { ReactNode, useRef } from 'react';

interface Props {
	children: ReactNode;
	onClose?: () => void;
	shouldCenter?: boolean;
	isDesktop?: boolean; // to move it from left and top depending on topbar and sidebar width
	scrollbarOutside?: boolean; // NEW PROP to toggle scrollbar position
	isScrollLockDisabled?: boolean;
}

export function WithPopup({
	children,
	onClose,
	shouldCenter = false,
	isDesktop = false,
	scrollbarOutside = false,
	isScrollLockDisabled = false,
}: Props) {
	const childrenWrapperRef = useRef<HTMLDivElement>(null);
	const parentRef = useRef<HTMLDivElement>(null);

	useOutsideClick(childrenWrapperRef, onClose);
	useScrollLock({ isDisabled: isScrollLockDisabled });

	const parentClasses = `fixed inset-0 h-dvh z-50 bg-[rgba(0,0,0,0.35)] ${isDesktop ? 'pl-desktop-sidebar-width' : ''}`;

	const containerClasses = [
		'h-screen',
		isDesktop && 'pt-[73px]',
		shouldCenter && 'flex justify-center',
		scrollbarOutside && 'pb-[30px] max-h-screen overflow-y-auto',
	]
		.filter(Boolean)
		.join(' ');

	return (
		<div ref={parentRef} className={parentClasses}>
			<div className={containerClasses}>
				<div ref={childrenWrapperRef} className={`relative w-fit h-fit ${shouldCenter && 'my-auto'}`}>
					{children}
				</div>
			</div>
		</div>
	);
}
