/* eslint-disable complexity */
import { OpenFont, StopFont } from 'assets/images';
import { PrimaryBtn } from 'components/common/buttons/PrimaryBtn';
import { gsap } from 'gsap';
import { useBoxFromDb, useUserContext, useWindowDimensions } from 'hooks';
import { useSound } from 'hooks/utility/useSound';
import { useInventoryBoxes } from 'pages/inventory/hooks/useInventoryBoxes';
import { BoxOpenClickSound } from 'pages/OpenBox/assets';
import { useBoxNameFromParams } from 'pages/OpenBox/hooks/useBoxNameFromParams';
import { useBoxOpeningStoreDesktop } from 'pages/OpenBox/store/useBoxOpeningStoreDesktop';
import { useRef } from 'react';
import { usePopupStore } from 'store/usePopupStore';
import { shallow } from 'zustand/shallow';

import { screens } from '../../../../../../constants';
import { OpenBtnPriceTag } from './OpenBtnPriceTag';

interface Props {
	onClick: () => void;
	isOpenFnDisabled: boolean;
	font: 'stop' | 'open';
}

export function OpenBtn({ onClick, isOpenFnDisabled, font }: Props) {
	const { user } = useUserContext();
	const { setShowRegisterScreen, setAutoSpinCount, autoSpinCount, setIsNotEnoughBalancePopupVisible } =
		useBoxOpeningStoreDesktop((state) => ({
			setShowRegisterScreen: state.setShowRegisterScreen,
			setAutoSpinCount: state.setAutoSpinCount,
			autoSpinCount: state.autoSpinCount,
			setIsNotEnoughBalancePopupVisible: state.setIsNotEnoughBalancePopupVisible,
		}));

	const { isBonusBoxToOpen, setIsBonusBoxToOpen } = usePopupStore(
		(state) => ({
			setIsBonusBoxToOpen: state.setIsBonusBoxToOpen,
			isBonusBoxToOpen: state.isBonusBoxToOpen,
		}),
		shallow
	);

	const { data: inventoryBoxes, isLoading: isInventoryBoxesLoading } = useInventoryBoxes();

	const PriceTagRef = useRef<HTMLDivElement>(null);

	const boxName = useBoxNameFromParams();
	const { data: box } = useBoxFromDb(boxName);

	const isDisabled = (font === 'open' && isOpenFnDisabled && isInventoryBoxesLoading) || autoSpinCount === 0;

	const { play: playBoxOpenSound } = useSound({ src: BoxOpenClickSound });

	function handleBoxClick() {
		if (!user) {
			setShowRegisterScreen(true);
			return;
		}

		if (font === 'stop') {
			setAutoSpinCount(0);
			return;
		}

		if (!box) {
			return;
		} else if (user.balance < box.price && inventoryBoxes?.every((box) => box.boxId.name !== boxName)) {
			animateOpenError();
			setIsNotEnoughBalancePopupVisible(true);
			return;
		}

		if (isBonusBoxToOpen) {
			setIsBonusBoxToOpen(false);
		}

		playBoxOpenSound();
		onClick();
	}

	async function animateOpenError() {
		await new Promise((resolve) => {
			const tl = gsap.timeline({
				onComplete: resolve,
			});

			tl.to(PriceTagRef.current, {
				x: '-=10',
				duration: 0.065,
				ease: 'none',
			})
				.to(PriceTagRef.current, {
					x: '+=20',
					duration: 0.13,
					ease: 'none',
				})
				.to(PriceTagRef.current, {
					x: '-=10',
					duration: 0.065,
					ease: 'none',
				})
				.repeat(1);
		});
	}

	const { screenWidth } = useWindowDimensions();

	const btnHeight =
		screenWidth >= screens.xxl.width
			? '49px'
			: screenWidth >= screens.lg.width
				? '49px'
				: screenWidth >= screens.md.width
					? '41px'
					: '36px';

	const btnWidth =
		screenWidth >= screens.xxl.width
			? '170px'
			: screenWidth >= screens.lg.width
				? '150px'
				: screenWidth >= screens.md.width
					? '130px'
					: '125px';

	return (
		<div className="relative z-10 flex items-center justify-center">
			<PrimaryBtn
				onClick={handleBoxClick}
				isDisabled={isDisabled}
				style={{ height: btnHeight, width: btnWidth }}
				content={
					<img
						alt="Open"
						src={font === 'stop' ? StopFont : OpenFont}
						className="h-[18px] w-auto lg:h-[21px] xl:h-[24px] "
					/>
				}
			/>
			<OpenBtnPriceTag ref={PriceTagRef} isDisabled={isDisabled} boxPrice={box?.price || 0} />
		</div>
	);
}
