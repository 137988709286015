import { CryptoPayCurrency } from 'pages/TransactionManager/deposit.interface';
import { Header } from 'pages/TransactionManager/mobile/components/Header';
import { SecureBatch } from 'pages/TransactionManager/mobile/components/SecureBatch';

import { CryptoPayOptionsList } from './CryptoPayOptionsList';

interface Props {
	onCryptoSelection: (crypto: CryptoPayCurrency) => void;
	onBack: () => void;
}

export function CryptoDepositSelection({ onCryptoSelection, onBack }: Props) {
	return (
		<div className="flex flex-col items-center gap-y-[40px] h-full justify-between min-h-fit pb-[12px] w-full px-[20px]">
			<Header title="Choose Currency" onBackClick={onBack} />

			<CryptoPayOptionsList onSelectedCurrencyChange={onCryptoSelection} />
			<SecureBatch />
		</div>
	);
}
