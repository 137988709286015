import axios from 'axios';
import { useQuery } from 'react-query';

import { QUERY_CACHE_CONFIG } from '../../constants';

const fetchConversionRate = async () => {
	const { data } = await axios.get('https://api.coingecko.com/api/v3/simple/price', {
		params: {
			ids: 'bitcoin,ethereum,solana,dogecoin',
			vs_currencies: 'usd',
		},
	});

	return {
		bitcoin: data.bitcoin.usd,
		etherium: data.ethereum.usd,
		solana: data.solana.usd,
		dogecoin: data.dogecoin.usd,
	};
};

export function useConversionRates() {
	return useQuery({
		queryKey: ['cryptoRates'],
		queryFn: fetchConversionRate,
		cacheTime: QUERY_CACHE_CONFIG.shortTerm.cacheTime,
		staleTime: QUERY_CACHE_CONFIG.shortTerm.staleTime,
	});
}
