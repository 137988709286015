import axios from 'axios';
import { UserInterface } from 'interfaces/UserInterface';
import React, { createContext, PropsWithChildren, useCallback } from 'react';
import { useQuery } from 'react-query';

import { API_DOMAIN, QUERY_CACHE_CONFIG, QUERY_KEYS } from '../constants';

interface UserContextProps {
	user: UserInterface | undefined;
	load: (onSuccess?: (data: any) => void, onError?: (error: unknown) => void) => Promise<void>;
	isLoading: boolean;
}

export const myUserContext = createContext<UserContextProps>({
	user: undefined,
	load: () => Promise.resolve(), // default to a no-op Promise
	isLoading: true,
});

async function getUser() {
	try {
		const response = await axios.get(`${API_DOMAIN.mrlootBackend}/api/user`, {
			withCredentials: true,
		});
		return response.data;
	} catch (err) {
		throw err;
	}
}

export const UserContext: React.FC<PropsWithChildren<unknown>> = ({ children }) => {
	const {
		data: user,
		refetch,
		isLoading,
	} = useQuery<UserInterface>({
		queryKey: QUERY_KEYS.userData,
		queryFn: getUser,
		cacheTime: QUERY_CACHE_CONFIG.longTerm.cacheTime,
		staleTime: QUERY_CACHE_CONFIG.longTerm.staleTime,
	});

	// Memoize the load function to prevent it from being recreated on every render
	const load = useCallback(
		async (onSuccess?: (data: any) => void, onError?: (error: unknown) => void) => {
			try {
				const { data } = await refetch();
				onSuccess?.(data);
			} catch (error) {
				onError?.(error);
			}
		},
		[refetch] // Dependencies of useCallback
	);

	return <myUserContext.Provider value={{ user, load, isLoading }}>{children}</myUserContext.Provider>;
};
