import { IconWalletMoney } from 'assets/icons/IconWalletMoney';
import { AmountDisplayWithCurrency } from 'components/common/AmountDisplayWithCurrency';
import { useUserContext, useWindowDimensions } from 'hooks';
import { useLocation } from 'react-router-dom';
import { usePopupStore } from 'store/usePopupStore';
import { shallow } from 'zustand/shallow';

import { screens } from '../../../../constants';

interface Props {
	clickable?: boolean;
}

const WALLET_ICON_SIZE_DEFAULT = 24;
const WALLET_ICON_SIZE_LGX = 24;

export function MobileWallet({ clickable = true }: Props) {
	const { user } = useUserContext();

	const setIsDepositPopupVisible = usePopupStore((state) => state.setIsDepositPopupVisible, shallow);

	const location = useLocation();

	function handleWalletClick() {
		if (!clickable) {
			return;
		}

		setIsDepositPopupVisible(true);
	}

	const { screenWidth } = useWindowDimensions();

	return (
		<button
			onClick={handleWalletClick}
			className="flex items-center justify-end rounded-[12px] bg-[rgba(0,0,0,0.25)] h-[42px] lgx:h-[52px] gap-x-[10px] lgx:gap-x-[14p] pl-[7px] lgx:pl-[12px] overflow-hidden"
		>
			<AmountDisplayWithCurrency
				amount={user?.balance}
				style={{ margin: 'auto', paddingLeft: '7px' }}
				amountStyle={{
					lineHeight: screenWidth >= screens.lgx.width ? '23px' : '17px',
					fontWeight: 'bold',
					fontSize: screenWidth >= screens.lgx.width ? '24px' : '17px',
				}}
				imgStyle={{ height: screenWidth >= screens.lgx.width ? '23px' : '18px' }}
				abbreviationOption="none"
			/>

			<div
				className={`${location.pathname.includes('transaction') ? 'text-white' : 'text-[#999999DE]'} bg-[rgba(0,0,0,0.55)] hover:bg-[rgba(0,0,0,0,35)] focus-within:text-white rounded-[12px] flex items-center justify-center h-full w-[42px] lgx:w-[50px]`}
			>
				<IconWalletMoney
					height={screenWidth > screens.lgx.width ? WALLET_ICON_SIZE_LGX : WALLET_ICON_SIZE_DEFAULT}
					width={screenWidth > screens.lgx.width ? WALLET_ICON_SIZE_LGX : WALLET_ICON_SIZE_DEFAULT}
				/>
			</div>
		</button>
	);
}
