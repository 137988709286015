import { DesktopChooseCurrencyPopup } from 'components/PopupHandler/CurrencyPopup/DesktopChooseCurrencyPopup';
import { useUserContext } from 'hooks';
import { DepositPopup } from 'pages/TransactionManager/desktop/DepositPopup';
import { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { SIGNUP_OFFER_TIME, usePopupStore } from 'store/usePopupStore';
import { shallow } from 'zustand/shallow';

import { DesktopDepositBonusPopup } from './depositBonus/DesktopDepositBonusPopup';
import { useDepositBonusPopupScheduler } from './depositBonus/useDepositBonusPopupScheduler';
import { DesktopSignupBoxBonusPopup } from './signupBonus/DesktopSignupBoxBonusPopup';
import { StickySignupBonusBoxLink } from './signupBonus/StickySignupBonusBoxLink';
import { StickySignupBonusTimer } from './signupBonus/StickySignupBonusTimer';
import { DesktopUserGiftPopup } from './UserGift/DesktopUserGiftPopup';

export function DesktopPopupHandler() {
	const { setSignupBonusExpiredAt, signupBonusExpiredAt } = usePopupStore(
		(state) => ({
			signupBonusExpiredAt: state.signupBonusExpiredAt,
			setSignupBonusExpiredAt: state.setSignupBonusExpiredAt,
		}),
		shallow
	);

	const { user } = useUserContext();

	useDepositBonusPopupScheduler();

	// create initial Signup bonus offer
	useEffect(() => {
		if (signupBonusExpiredAt === null) {
			const expirationDate = new Date(new Date().getTime() + SIGNUP_OFFER_TIME);
			setSignupBonusExpiredAt(expirationDate);
		}
	}, [setSignupBonusExpiredAt, signupBonusExpiredAt]);

	const portalRoot = document.getElementById('portal-popup-root');

	if (!portalRoot) {
		console.error('Portal root not found. Make sure the element with id "portal-popup-root" exists in your HTML.');
		return null;
	}

	return ReactDOM.createPortal(
		<>
			{!user && (
				<>
					<DesktopSignupBoxBonusPopup />
					<StickySignupBonusTimer />
				</>
			)}
			{user && (
				<>
					<DesktopUserGiftPopup />
					<StickySignupBonusBoxLink />
					<DesktopDepositBonusPopup />
					<DepositPopup />
				</>
			)}

			<DesktopChooseCurrencyPopup />
		</>,
		portalRoot
	);
}
