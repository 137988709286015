import axios from 'axios';
import { API_DOMAIN } from '../../../constants';
import { useMutation } from 'react-query';

/**
 * Interface for login credentials.
 */
interface LoginCredentials {
	email: string;
	password: string;
	recaptchaToken: string;
}

/**
 * Interface for hook configuration options.
 */
interface UseLoginOptions {
	onSuccess?: () => void;
	onError?: (errorMessage: string) => void;
}

/**
 * Performs the login request.
 * @param credentials - The login credentials.
 */
async function loginUser(credentials: LoginCredentials): Promise<void> {
	try {
		const response = await axios.post(`${API_DOMAIN.mrlootBackend}/api/user/login`, credentials, {
			withCredentials: true,
		});

		if (response.data.status !== 'success') {
			throw new Error(response.data.message || 'Unexpected error occurred during login.');
		}
	} catch (error) {
		console.error('Login error:', error);
		throw error;
	}
}

/**
 * Custom hook for handling user login.
 * @param options - Configuration options for success and error handling.
 */
export function useLogin({ onSuccess, onError }: UseLoginOptions = {}) {
	const mutation = useMutation(loginUser, {
		onSuccess: () => onSuccess?.(),
		onError: (error: any) => {
			const errorMessage = error.response?.data?.message || error.message || 'Login failed.';
			onError?.(errorMessage);
		},
	});

	/**
	 * Initiates the login process.
	 * @param credentials - The user's login credentials.
	 */
	function executeLogin(credentials: LoginCredentials) {
		mutation.mutate(credentials);
	}

	return {
		executeLogin,
		isLoading: mutation.isLoading,
		error: mutation.error,
	};
}
