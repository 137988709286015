import { useImageLoadingProgress } from 'hooks/utility/useImageLoadingProgress';
import { PrizeInterface } from 'pages/OpenBox/boxOpening.interface';
import { forwardRef } from 'react';

import { PrizeCardList } from '../../components/PrizeCardList';

interface Props {
	prizes: PrizeInterface[];
	onTotalLoadingFail: () => void;
	onLoadingComplete: () => void;
}

/**
 * Used as a buffer between autospins so normal slot items can load - acts as a loading screen.
 */
export const AutoSpinLoadingBuffer = forwardRef<HTMLDivElement, Props>(
	({ prizes, onTotalLoadingFail, onLoadingComplete }, ref) => {
		const { handleLoadFail: handleLoadFailAutoSpinBuffer, handleLoadSuccess: handleLoadSuccessAutoSpinBuffer } =
			useImageLoadingProgress({
				totalImages: prizes.length,
				failThreshold: 0,
				onTotalFailure: onTotalLoadingFail,
				onCompleteLoading: onLoadingComplete,
				resetAfterCompleteOrFail: true,
			});

		return (
			<div
				ref={ref}
				className="absolute left-0 top-0 opacity-0 h-full flex items-center justify-center w-full space-x-[30px]"
			>
				<PrizeCardList
					prizes={prizes}
					onImageError={handleLoadFailAutoSpinBuffer}
					onImageLoad={handleLoadSuccessAutoSpinBuffer}
				/>
			</div>
		);
	}
);

AutoSpinLoadingBuffer.displayName = 'AutoSpinLoadingBuffer';
