/* eslint-disable max-lines-per-function */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable complexity */
import { BoxCardWithItemPreview, Skeleton } from 'components';
import { LoadSpinner } from 'components/common/loading/LoadSpinner';
import { useInfiniteScroll } from 'hooks';
import { useSearchBoxes } from 'hooks/data/box/useSearchBoxes';
import { useWindowDimensions } from 'hooks/utility/layout-measurements/useWindowDimensions';
import { MobileBoxCard } from 'pages/overview/mobile/components/MobileBoxCard';
import { useCallback, useMemo, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { useAppStore } from 'store/useAppStore';
import { getBoxLink, isMobile } from 'utils';
import { shallow } from 'zustand/shallow';

import { screens } from '../../../constants';
import { useSearchStore } from '../store/SearchStore';

const BOXES_PER_ROW_XL = 8;
const BOXES_PER_ROW_LG = 7;
const BOXES_PER_ROW_MD = 6;
const BOXES_PER_ROW_SM = 5;
const BOXES_PER_ROW_SS = 4;
const BOXES_PER_ROW_DEFAULT = 3;
const LOADED_ROWS = 5;

export function GridSearchBoxes() {
	const containerRef = useRef<HTMLDivElement>(null);
	const lastBoxRef = useRef<HTMLDivElement>(null);

	const setSavedSearchBoxes = useSearchStore((state) => state.setSavedSearchBoxes, shallow);

	const searchQuery = useSearchStore((state) => state.searchQuery, shallow);

	const { priceFilterMax, priceFilterMin, sortOption } = useSearchStore(
		(state) => ({
			priceFilterMin: state.priceFilterMin,
			priceFilterMax: state.priceFilterMax,
			sortOption: state.sortOption,
		}),
		shallow
	);

	const { screenWidth } = useWindowDimensions();

	const includeBoxItemsInRequest = useMemo(() => !isMobile(screenWidth), [screenWidth]);

	// eslint-disable-next-line no-magic-numbers
	const loadedBoxPerPage = useMemo(
		() =>
			screenWidth > screens.xl.width
				? LOADED_ROWS * BOXES_PER_ROW_XL
				: screenWidth > screens.lg.width
					? LOADED_ROWS * BOXES_PER_ROW_LG
					: screenWidth > screens.md.width
						? LOADED_ROWS * BOXES_PER_ROW_MD
						: screenWidth > screens.sm.width
							? LOADED_ROWS * BOXES_PER_ROW_SM
							: screenWidth > screens.ss.width
								? LOADED_ROWS * BOXES_PER_ROW_SS
								: LOADED_ROWS * BOXES_PER_ROW_DEFAULT,
		[screenWidth]
	);

	const {
		data: searchBoxes,
		rawData: rawSearchBoxes,
		isLoading: isSearchBoxesReqLoading,
		hasNextPage: hasSearchBoxesNextPage,
		loadMore: loadMoreSearchBoxes,
		isFetchingNextPage: isNextPageSearchBoxLoading,
	} = useSearchBoxes({
		query: searchQuery,
		limit: loadedBoxPerPage,
		minPrice: priceFilterMin,
		maxPrice: priceFilterMax,
		sortOption: sortOption,
		includeItems: includeBoxItemsInRequest,
	});

	const handleLoadOnInfiniteScroll = useCallback(() => {
		if (hasSearchBoxesNextPage && !isSearchBoxesReqLoading) {
			loadMoreSearchBoxes();
		}
	}, [hasSearchBoxesNextPage, isSearchBoxesReqLoading, loadMoreSearchBoxes]);

	useInfiniteScroll(lastBoxRef, handleLoadOnInfiniteScroll, searchBoxes?.length);

	const saveScrollPosition = useAppStore((state) => state.saveScrollPosition, shallow);

	const navigate = useNavigate();
	const location = useLocation();

	function handleBoxCardClick(boxName: string) {
		// Save current state before navigating
		const pathname = location.pathname;
		const scrollY = window.scrollY;
		setSavedSearchBoxes(rawSearchBoxes);
		saveScrollPosition(pathname, scrollY);
		const link = getBoxLink(boxName);
		navigate(link);
	}

	return (
		<>
			<div
				ref={containerRef}
				className="w-full mb-4 grid gap-[20px] grid-cols-3 ss:grid-cols-4 sm:grid-cols-5 md:grid-cols-6 lg:grid-cols-7 xl:grid-cols-8 justify-between min-h-[calc(100vh-350px)] auto-rows-min"
			>
				{searchBoxes &&
					searchBoxes.map((box) => (
						<div key={box._id} className="h-fit">
							{isMobile(screenWidth) && <MobileBoxCard box={box} style={{ width: `100%`, height: 'auto' }} />}
							{!isMobile(screenWidth) && (
								<BoxCardWithItemPreview
									onClick={() => handleBoxCardClick(box.name)}
									box={box}
									styles={{ width: `100%`, height: 'auto', aspectRatio: '0.65/1' }}
								/>
							)}
						</div>
					))}

				{isSearchBoxesReqLoading &&
					Array(loadedBoxPerPage)
						.fill(null)
						.map((_, index) => (
							<div key={index} className="">
								<Skeleton width="100%" style={{ aspectRatio: '0.65/1' }} />
							</div>
						))}
			</div>
			<div className="w-full flex items-center justify-center" ref={lastBoxRef}>
				{isNextPageSearchBoxLoading && <LoadSpinner size="35px" />}
			</div>
		</>
	);
}
