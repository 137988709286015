import { useAutoAnimate } from '@formkit/auto-animate/react';
import { useUserContext } from 'hooks';
import { useWindowDimensions } from 'hooks/utility/layout-measurements/useWindowDimensions';
import { useQueryParam } from 'hooks/utility/useQueryParam';
import { ItemRarity } from 'interfaces/ItemInterfaces';
import { useCallback, useEffect, useState } from 'react';
import { useAppStore } from 'store/useAppStore';
import { shallow } from 'zustand/shallow';

import { screens } from '../../../constants';
import { InventoryOrderOptions } from '../inventoryUI.interface';
import { NoSessionDemoItems } from '../mobile/components/NoSessionDemoItems';
import { NoSessionPopup } from '../popups/NoSessionPopup';
import { useInventoryStore } from '../store/InventoryStore';
import { BoxesGrid } from './components/BoxesGrid';
import { FloatingBottomBar } from './components/FloatingBottomBar/FloatingBottomBar';
import { ItemGrid } from './components/ItemGrid';
import { ItemsPresentationMode } from './components/ItemsPresentationMode';
import Sidebar from './components/Sidebar/Sidebar';
import { Topbar } from './components/Topbar/Topbar';
import { UnlockAllFeaturesPopup } from './popups/UnlockAllFeaturePopup';

export function DesktopInventory() {
	const { screenWidth } = useWindowDimensions();

	const { user } = useUserContext();

	const [excludedRarities, setExcludedRarities] = useState<ItemRarity[]>([]);
	const [selectedOrderOption, setSelectedOrderOption] = useState<InventoryOrderOptions>(InventoryOrderOptions.NEWEST);
	const [searchInput, setSearchInput] = useState('');
	const { isPresentationMode, setIsPresentationMode, isBoxView, setIsBoxView } = useInventoryStore(
		(state) => ({
			isPresentationMode: state.isPresentationMode,
			setIsPresentationMode: state.setIsPresentationMode,
			isBoxView: state.isBoxView,
			setIsBoxView: state.setIsBoxView,
		}),
		shallow
	);

	const [selectedItemIds, setSelectedItemIds] = useState<Set<string>>(new Set());

	const handleSearch = useCallback(
		(searchValue: string) => {
			setSearchInput(searchValue);
			if (searchValue.length > 0) {
				setIsPresentationMode(false);
			}
		},
		[setIsPresentationMode]
	);

	const onItemSelect = useCallback((id: string) => {
		setSelectedItemIds((prevItems) => {
			const newItems = new Set(prevItems);

			if (newItems.has(id)) {
				newItems.delete(id);
			} else {
				newItems.add(id);
			}

			return newItems;
		});
	}, []);

	const handleSelectedItemIds = useCallback((ids: Set<string>) => setSelectedItemIds(ids), []);

	const showSidebar = screenWidth > screens.md.width;

	const [parent] = useAutoAnimate();

	const clearNewWonItemIds = useAppStore((state) => state.clearNewWonItemIds, shallow);

	useEffect(() => {
		return () => {
			clearNewWonItemIds();
		};
	}, [clearNewWonItemIds]);

	const handleParams = useCallback(
		(param: string) => {
			if (param === 'winboxes') {
				setIsBoxView(true);
			} else if (param === 'products') {
				setIsBoxView(false);
			}
		},
		[setIsBoxView]
	);

	useQueryParam('view', handleParams);

	return (
		<div className="w-[100%] relative">
			<div className={`${!user && 'opacity-70 blur-[2px]'} block md:grid grid-cols-[auto_275px] gap-x-[15px]`}>
				{/**main */}
				<div className={`relative space-y-[15px] pb-[10px] flex-grow`}>
					<div className={`w-full overflow-visible`}>
						<Topbar
							selectedOrderOption={selectedOrderOption}
							onSelectedOrderOptionChange={(v) => setSelectedOrderOption(v as InventoryOrderOptions)}
							searchInput={searchInput}
							onSearchInputChange={handleSearch}
						/>

						<div
							ref={parent}
							className="overflow-y-auto md:overflow-y-visible max-h-[calc(100vh-235px)] md:max-h-none  min-h-[calc(100vh-230px)] md:min-h-[calc(100vh-169px)] mt-3 h-fit w-full rounded-[20px] bg-secondary-gradient md:bg-none md:border-none md:outline-none border-[2px] border-darkBlue outline outline-[2px] outline-white"
						>
							{user ? (
								<>
									{isBoxView ? (
										<BoxesGrid searchInput={searchInput} orderOption={selectedOrderOption} />
									) : (
										<>
											{!isPresentationMode && (
												<ItemGrid
													searchInput={searchInput}
													excludedRarities={excludedRarities}
													orderOption={selectedOrderOption}
													selectedItems={selectedItemIds}
													onItemSelect={onItemSelect}
												/>
											)}
											{isPresentationMode && (
												<ItemsPresentationMode
													excludedRarities={excludedRarities}
													orderOption={selectedOrderOption}
													selectedItems={selectedItemIds}
													onItemSelect={onItemSelect}
												/>
											)}
										</>
									)}
								</>
							) : (
								<NoSessionDemoItems />
							)}
						</div>
					</div>
					{screenWidth <= screens.md.width && (
						<FloatingBottomBar selectedItemIds={selectedItemIds} onItemIdsChange={(ids) => setSelectedItemIds(ids)} />
					)}
				</div>

				{showSidebar && (
					<Sidebar
						excludedRarities={excludedRarities}
						onExcludedRarityChange={(v) => setExcludedRarities(v)}
						selectedItemIds={selectedItemIds}
						onSelectedItemIdsChange={handleSelectedItemIds}
					/>
				)}
			</div>
			{!user && <NoSessionPopup isMobile={false} />}
			<UnlockAllFeaturesPopup />
		</div>
	);
}
