import { useAutoAnimate } from '@formkit/auto-animate/react';
import { AmountDisplayWithCurrency } from 'components/common/AmountDisplayWithCurrency';
import { LoadSpinner } from 'components/common/loading/LoadSpinner';
import { useStretchUpAnimation } from 'hooks/animations/useStretchUpAnimation';
import { DBUserItemWithItemObj } from 'interfaces/InventoryInterface';
import { forwardRef } from 'react';

import { rarities } from '../../../../../constants';

interface Props {
	populatedSelectedItems?: DBUserItemWithItemObj[];
	onItemIdsChange: (ids: Set<string>) => void;
	onLoadMore: () => void;
	isLoading: boolean;
	isCompleteLoaded: boolean;
}

export const ExpandedSellSummary = forwardRef<HTMLDivElement, Props>(
	({ onItemIdsChange, populatedSelectedItems, isLoading, isCompleteLoaded, onLoadMore }, ref) => {
		const [parent] = useAutoAnimate();

		useStretchUpAnimation({ ref: ref });

		function handleRemove(itemId: string) {
			const newItemIds = new Set(populatedSelectedItems?.map((el) => el._id));
			newItemIds.delete(itemId);
			onItemIdsChange(newItemIds);
		}

		const showLoadMoreBtn =
			!isLoading && !isCompleteLoaded && populatedSelectedItems && populatedSelectedItems?.length > 0;

		return (
			<div
				ref={ref}
				className="absolute bottom-[65px] z-30 w-full h-[calc(100%-64px)] rounded-[20px] border-[2px] border-darkBlue outline outline-white outline-[2px] bg-mrLootBlue"
			>
				<div className="relative border-[1px] rounded-[4px] border-white01 w-full h-full shadow-[0_4px_4px_rgba(0,0,0,0.25)]">
					<div
						ref={parent}
						style={{ maxHeight: '-webkit-fill-available' }}
						className="overflow-y-auto h-full space-y-[10px] px-[15px] my-[15px]"
					>
						{populatedSelectedItems?.map((el) => (
							<div
								key={el._id}
								className="mt-[1px] pl-[10px] w-full h-[32px] flex items-center justify-between border-[2px] rounded-[24px]"
								style={{ borderColor: `${rarities[el.itemId.rarity].color}` }}
							>
								<span className="font-semibold text-[12px] text-white">{el.itemId.name}</span>

								<div className="flex items-stretch space-x-[10px] h-full">
									<div className="flex items-center text-white space-x-[5px] text-[12px]">
										<AmountDisplayWithCurrency
											useDivWrapper={false}
											amount={el.itemId.price}
											imgStyle={{ height: '19px' }}
										/>
									</div>

									<button
										onClick={() => handleRemove(el._id)}
										className="hover:bg-[var(--hoverBgColor)] relative right-[-1px] top-[-0.5px] box-content text-white text-[12px] border-[1px] h-full px-[16px] font-semibold rounded-full"
										style={
											{
												borderColor: `${rarities[el.itemId.rarity].color}`,
												'--hoverBgColor': rarities[el.itemId.rarity].color10,
											} as React.CSSProperties
										}
									>
										Remove
									</button>
								</div>
							</div>
						))}
						{showLoadMoreBtn && (
							<button
								onClick={onLoadMore}
								className="mx-auto flex items-center justify-center w-[110px] h-[30px] text-[14px] rounded-[20px] text-white border-[2px] border-mrLootBlue"
							>
								Load more
							</button>
						)}
						{isLoading && <LoadSpinner size="35px" />}
					</div>
					{(!populatedSelectedItems || populatedSelectedItems.length === 0) && (
						<h1 className="font-semibold text-[15px] text-white absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[50%] tracking-[1px]">
							Selected Items will be displayed here!
						</h1>
					)}
				</div>
			</div>
		);
	}
);
