import { FontClaim } from 'assets/images';
import { PrimaryBtn } from 'components/common/buttons/PrimaryBtn';
import { WithPrimaryPopup } from 'components/common/popups/WithPrimaryPopup';
import { useUnclaimedUserGifts } from 'hooks/data/userGifts/useUnclaimedUserGifts';
import { useBoxOpeningStoreMobile } from 'pages/OpenBox/store/useBoxOpeningStoreMobile';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { shallow } from 'zustand/shallow';

import { CLOUDFRONT_IMG_URL } from '../../../constants';
import { useDelayedUnblock } from '../hooks/useDelayedUnblock';

export function MobileUserGiftPopup() {
	const [isVisible, setIsVisible] = useState(false);

	const { data: unclaimedUserGifts } = useUnclaimedUserGifts();
	const isBoxOpening = useBoxOpeningStoreMobile((state) => state.isBoxOpening, shallow);

	useEffect(() => {
		// Check if there are any unclaimed gifts available
		if (unclaimedUserGifts && unclaimedUserGifts.length > 0) {
			const firstUnclaimedGift = unclaimedUserGifts[0];

			// Gifts are pre-sorted such that the first item with showPopup = true appears first.
			// If the first item does not have showPopup = true, then no subsequent items will either.
			if (firstUnclaimedGift.showPopup) {
				setIsVisible(true);
			}
		}
	}, [unclaimedUserGifts]);

	const navigate = useNavigate();
	const location = useLocation();

	const handlePopupClose = useCallback(() => {
		setIsVisible(false);
	}, [setIsVisible]);

	const handleClaim = useCallback(() => {
		setIsVisible(false);
		navigate('/inventory?view=winboxes');
	}, [navigate]);

	const isPopupUnblocked = useDelayedUnblock({ isBlocked: location.pathname.includes('auth') || isBoxOpening });

	const isComponentVisible = useMemo(() => isVisible && isPopupUnblocked, [isPopupUnblocked, isVisible]);

	return (
		<WithPrimaryPopup
			onClose={handlePopupClose}
			isExternallyVisible={isComponentVisible}
			useAnimation={true}
			childrenWrapperStyle={{ maxHeight: '85svh' }}
		>
			<div className="flex flex-col items-center gap-y-[25px] w-[280px] ms:w-[430px] sm:w-[600px]">
				<div className="flex flex-col gap-y-[10px]">
					<h1 className="font-inter font-bold text-[24px] ms:text-[26px] sm:text-[31px] text-center w-full ">
						New Year, New Loot Awaits! 🎁
					</h1>
					<span className="text-white/70 font-semibold font-inter text-[15px] ms:text-[16px] sm:text-[21px] text-center">
						As a valued customer, you’re receiving a FREE "New Year New Loot" WinBox
					</span>
				</div>
				<div className="relative aspect-[1.5/1] w-full rounded-[18px] outline outline-[1px] outline-white overflow-hidden">
					<img
						src={`${CLOUDFRONT_IMG_URL}/one-time-use/new-year-free-box.webp`}
						alt=""
						className="h-full w-full object-cover"
					/>
				</div>

				<div className="flex flex-col items-center gap-y-[12px] w-full">
					<PrimaryBtn
						style={{ height: 'fit-content', borderRadius: '24px' }}
						innerContentStyle={{ borderRadius: '24px' }}
						content={
							<div className="h-[50px] ms:h-[53px] sm:h-[59px] flex items-center justify-center ">
								<img
									src={FontClaim}
									alt="claim your bonus now!"
									className="h-[23px] ms:h-[25px] sm:h-[30px] object-contain"
								/>
							</div>
						}
						onClick={handleClaim}
					/>
				</div>
			</div>
		</WithPrimaryPopup>
	);
}
