import { useFallbackIfCqhUnsupported } from 'hooks/utility/useFallbackIfCqhUnsupported';
import { ItemInterface } from 'interfaces/ItemInterfaces';
import { forwardRef, useRef } from 'react';
import { getBgCardUrl } from 'utils';

import { CLOUDFRONT_IMG_URL, rarities } from '../../../../../constants';
import { CardHeader } from './CardHeader';
import { PriceContainer } from './PriceContainer';

interface Props {
	item: ItemInterface;
	style?: React.CSSProperties;
	isSelected?: boolean;
	imgQuali?: 'high' | 'medium' | 'low';
	onExpandClick: () => void;
	onClick?: () => void;
	onImageLoad?: () => void;
	onImageError?: () => void;
}

export const InventoryItemCard = forwardRef<HTMLDivElement, Props>(
	({ item, isSelected, style, imgQuali = 'medium', onClick, onExpandClick, onImageError, onImageLoad }: Props, ref) => {
		const parentRef = useRef<HTMLDivElement>(null);
		const headerRef = useRef<HTMLDivElement>(null);
		const probabilityRef = useRef<HTMLSpanElement>(null);
		const imageRef = useRef<HTMLImageElement>(null);
		const nameRef = useRef<HTMLDivElement>(null);
		const priceContainerRef = useRef<HTMLDivElement>(null);

		useFallbackIfCqhUnsupported({
			parentRef,
			refs: [
				{ ref: headerRef, proportion: 0.105, cssProperty: 'height' },
				{ ref: probabilityRef, proportion: 0.065, cssProperty: 'fontSize' },
				{ ref: imageRef, proportion: 0.41, cssProperty: 'height' },
				{ ref: nameRef, proportion: 0.05, cssProperty: 'fontSize' },
				{ ref: priceContainerRef, proportion: 0.16, cssProperty: 'height' },
			],
		});

		const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
			if (event.key === 'Enter' || event.key === ' ') {
				onClick?.();
			}
		};

		const itemImgPath =
			imgQuali === 'high'
				? item.imageUrls?.qualityHigh
				: imgQuali === 'medium'
					? item.imageUrls?.qualityMedium
					: item.imageUrls?.qualityLow;

		return (
			<div
				ref={ref || parentRef}
				onClick={onClick}
				onKeyDown={onClick ? handleKeyDown : undefined}
				role="button"
				tabIndex={0}
				style={
					{
						'--item-color': rarities[item.rarity].color,
						transition: 'transform 0.25s ease-out',
						...style,
					} as React.CSSProperties
				}
				className={`size-container overflow-hidden group w-full focus:drop-shadow-[0_0_4px_var(--item-color)] hover:drop-shadow-[0_0_4px_var(--item-color)] rounded-[3cqh] relative flex flex-col aspect-[0.65/1] flex-shrink-0 pb-[12px] pt-[6px] xxl:pt-3 items-center justify-between
                      ${isSelected ? 'outline-white outline outline-[2px]' : 'outline-none'} z-0`}
			>
				<div className="z-[-2] rounded-[15px] absolute inset-0 bg-black w-full h-full" />
				<img
					alt={`Card background color ${rarities[item.rarity].label}`}
					src={getBgCardUrl(item.rarity)}
					className="z-[-1] h-full w-full object-fill absolute inset-0 rounded-[3cqh]"
				/>
				<CardHeader itemRarity={item.rarity} onExpandTooggle={onExpandClick} />
				<div className={`mt-1 flex flex-col px-3 justify-between flex-grow w-full space-y-1 z-10 my-auto`}>
					<img
						ref={imageRef}
						src={`${CLOUDFRONT_IMG_URL}/${itemImgPath}`}
						onLoad={onImageLoad}
						onError={onImageError}
						alt={item.name}
						className="max-w-full mx-auto object-scale-down h-[41cqh]"
					/>
					<div
						ref={nameRef}
						className="flex flex-col justify-center items-center text-white"
						style={{ fontSize: '5cqh' }}
					>
						{item.brandName !== 'undefined' && (
							<span className={`text-center font-[400] line-clamp-1`}>{item.brandName}</span>
						)}
						<span className="text-center font-[600] line-clamp-2">{item.name}</span>
					</div>
					<PriceContainer item={item} />
				</div>
			</div>
		);
	}
);
