import { SeparatorWithText } from 'components/Separator/SeparatorWithText';
import OAuthBtnsHorizontal from '../../components/OAuthBtnsHorizontal';
import { MobileRegisterForm } from './MobileRegisterForm';

export function MobileRegister() {
	return (
		<div className="flex flex-col gap-y-[30px] pb-[40px] font-poppins">
			<MobileRegisterForm />

			<SeparatorWithText
				text="Or continue"
				textLine2="with"
				lineColor="rgba(255,255,255,0.6)"
				propStyle={{ color: 'rgba(255,255,255,0.6)', marginTop: '15px', fontFamily: 'poppins' }}
			/>

			<OAuthBtnsHorizontal isRequestLoading={false} />
		</div>
	);
}
