interface AmountButtonProps {
	amount: number;
	imgSrc?: string;
	isSelected?: boolean;
	onClick: () => void;
}

export const AmountButton = ({ amount, imgSrc, isSelected, onClick }: AmountButtonProps) => {
	return (
		<button
			onClick={onClick}
			type="button"
			className={`flex items-center justify-center min-w-[80px] h-[52px] border-[2px] border-darkBlue outline outline-white rounded-[20px] ${
				isSelected ? 'bg-primary-gradient outline-[4px]' : 'bg-primary-gradient outline-[2px]'
			}`}
		>
			{imgSrc && <img src={imgSrc} alt={`${amount}`} className="h-[18px] w-auto object-contain" />}
		</button>
	);
};
