import { BoxIcon } from 'assets/images';
import { Skeleton } from 'components';

import { SkeletonMobileBoxCard } from './SkeletonMobileBoxCard';

export function SkeletonMobileBoxCarousel() {
	return (
		<div className="flex flex-col gap-y-[5px] sm:gap-y-[10px]">
			<div className="flex items-center">
				<img src={BoxIcon} alt="" className="h-[20px] sm:h-[25px]" /> {/* Adjust the class for size as necessary */}
				<Skeleton style={{ height: '28px', width: '130px', marginLeft: '6px' }} />
				<div className="text-sm text-mrLootBlue ml-auto">See All</div>
			</div>
			<div className="flex items-stretch space-x-[14px] overflow-x-auto relative w-[100vw] -left-mobile-side-padding px-mobile-side-padding py-[12px]">
				{Array.from({ length: 10 }).map((el, index) => (
					<SkeletonMobileBoxCard key={index} />
				))}
			</div>
			;
		</div>
	);
}
