import 'swiper/css';

import { useWindowDimensions } from 'hooks';
import { FeatureBannersSessionTypes, FeatureBannerTypes } from 'interfaces/FeatureBannerInterface';
import { useLoadFeatureBanners } from 'pages/overview/hooks/useLoadFeatureBanner';
import { useState } from 'react';
import { Swiper as SwiperCore } from 'swiper'; // Import SwiperCore type
import { Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import { Skeleton } from '../Skeleton';
import { getAspectRatio } from './banner-constants';
import { FeatureBoxBanner } from './components/FeatureBoxBanner';
import { FeatureImageBanner } from './components/FeatureImageBanner';

export function FeatureBanners() {
	const { data: featureBanners, isLoading } = useLoadFeatureBanners({
		sessionTypeToShow: FeatureBannersSessionTypes.USER_SESSION,
	});

	const [activeIndex, setActiveIndex] = useState(0); // Track the active slide
	const { screenWidth } = useWindowDimensions();
	const aspectRatioImgSkeleton = getAspectRatio(screenWidth);

	return (
		<div className="relative">
			<Swiper
				modules={[Autoplay]}
				spaceBetween={0}
				slidesPerView={1}
				loop={true}
				autoplay={{
					delay: 5500,
					pauseOnMouseEnter: true,
				}}
				onSlideChange={(swiper: SwiperCore) => setActiveIndex(swiper.realIndex)} // Add type annotation
			>
				{!isLoading &&
					featureBanners?.map((banner) => (
						<SwiperSlide key={banner._id}>
							{banner.type === FeatureBannerTypes.BOX && <FeatureBoxBanner box={banner.boxId} />}
							{banner.type === FeatureBannerTypes.BANNER && <FeatureImageBanner featureBanner={banner} />}
						</SwiperSlide>
					))}
			</Swiper>
			{isLoading && <Skeleton style={{ aspectRatio: aspectRatioImgSkeleton, width: '100%', borderRadius: '0px' }} />}

			{/* Custom Pagination */}
			<div className="absolute z-20 bottom-2 right-2 flex gap-[6px]">
				{featureBanners?.map((_, index) => (
					<span
						key={index}
						className={`h-1 rounded-full transition-all ${index === activeIndex ? 'bg-white w-2' : 'bg-[rgba(255,255,255,0.7)] w-1'}`}
					></span>
				))}
			</div>
		</div>
	);
}
