import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

import { CookieMrloot } from './assets';
import { CookieAcceptBtn } from './components/CookieAcceptBtn';
import { CookieDenyBtn } from './components/CookieDenyBtn';

interface Props {
	onAccept: () => void;
	onDecline: () => void;
}

export function MobileCookieBanner({ onAccept, onDecline }: Props) {
	return (
		<div className="fixed z-40 bottom-[120px] lgx:bottom-[150px] left-1/2 -translate-x-1/2 rounded-[24px] border-[3px] border-white  right-[9px] bg-mrLootBlue max-w-[500px] w-[92%] pt-[15px] pb-[33px] lgx:pb-[40px] pr-[15px] pl-[90px] xxs:pl-[100px] h-fit">
			<button
				onClick={onDecline}
				className="absolute right-[7px] top-[7px] translate-x-[50%] translate-y-[-50%] text-white border-[2px] bg-mrLootBlue h-[32px] w-[32px] text-[18px] lgx:text-[20px] rounded-full border-white flex items-center justify-center"
			>
				<FontAwesomeIcon icon={faXmark} />
			</button>

			<img src={CookieMrloot} alt="" className="absolute bottom-0 left-[10px] h-[90%] object-contain" />
			<div className="text-white text-center text-[14px] lgx:text-[16px] block text-wrap font-semibold">
				Our website uses cookies to improve user experience. See our{' '}
				<Link className="underline" to="cookie-policy">
					Privacy Policy
				</Link>
				{`.`}
			</div>

			<div className="absolute bottom-0 left-0 right-0 flex justify-center gap-x-[20px] items-center translate-y-[50%]">
				<CookieDenyBtn onClick={onDecline} />
				<CookieAcceptBtn onClick={onAccept} />
			</div>
		</div>
	);
}
