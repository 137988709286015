import axios from 'axios';
import { API_DOMAIN } from '../../../constants';
import { useMutation } from 'react-query';

async function requestResetPasswordMail(email: string, recaptchaToken: string) {
	try {
		const response = await axios.post(
			`${API_DOMAIN.mrlootBackend}/api/user/send-reset-pw-mail`,
			{ email: email, recaptchaToken },
			{ withCredentials: true }
		);
		if (response.data.status === 'success') {
			return response.data.codeExpirationTime;
		}
	} catch (err) {
		if (axios.isAxiosError(err)) {
			const message = err.response?.data?.message || err.message || 'Something unexpected went wrong';
			throw new Error(message);
		}
		throw err;
	}
}

interface UseResetPasswordMailProps {
	onSuccess?: (codeExpirationTime: Date) => void;
	onError?: (message: string) => void;
}

export function useResetPasswordMail({ onSuccess, onError }: UseResetPasswordMailProps = {}) {
	return useMutation(
		({ email, recaptchaToken }: { email: string; recaptchaToken: string }) =>
			requestResetPasswordMail(email, recaptchaToken),
		{
			onSuccess,
			onError: (err: Error) => onError?.(err.message),
		}
	);
}
