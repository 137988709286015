import { WithPrimaryPopup } from 'components/common/popups/WithPrimaryPopup';
import { Font1X, Font3X, Font5X, Font10X, Font25X, Font50X } from 'pages/OpenBox/assets';
import { useBoxOpeningStoreMobile } from 'pages/OpenBox/store/useBoxOpeningStoreMobile';
import { shallow } from 'zustand/shallow';

const OPTIONS = [
	{
		amount: 1,
		img: Font1X,
	},
	{
		amount: 3,
		img: Font3X,
	},
	{
		amount: 5,
		img: Font5X,
	},
	{
		amount: 10,
		img: Font10X,
	},
	{
		amount: 25,
		img: Font25X,
	},
	{
		amount: 50,
		img: Font50X,
	},
];

export function PopupSpinAmountChooser() {
	const { setIsSpinAmountChooserPopupVisible, setAutoSpinCount, autoSpinCount, isSpinAmountChooserPopupVisible } =
		useBoxOpeningStoreMobile(
			(state) => ({
				setIsSpinAmountChooserPopupVisible: state.setIsSpinAmountChooserPopupVisible,
				setAutoSpinCount: state.setAutoSpinCount,
				isSpinAmountChooserPopupVisible: state.isSpinAmountChooserPopupVisible,
				autoSpinCount: state.autoSpinCount,
			}),
			shallow
		);

	function handleClick(amount: number) {
		setAutoSpinCount(amount);
		setIsSpinAmountChooserPopupVisible(false);
	}

	return (
		<WithPrimaryPopup
			onClose={() => setIsSpinAmountChooserPopupVisible(false)}
			isDesktop={false}
			isExternallyVisible={isSpinAmountChooserPopupVisible}
			useAnimation={true}
			isScrollLockDisabled={true}
		>
			<div className="flex flex-col gap-y-[22px] items-center">
				<h1 className="font-bold text-[21px] text-white text-center">Choose Number of Spins</h1>

				<div className="grid grid-cols-3 gap-[15px]">
					{OPTIONS.map((option, index) => (
						<button
							onClick={() => handleClick(option.amount)}
							key={index}
							className={`${autoSpinCount === option.amount ? 'shadow-primaryInset' : 'hover:shadow-primaryInset'} mx-auto text-white self-center bg-primary-gradient text-[21px] xxs:text-[24px] font-bold rounded-[42px] border-[2px] border-white bg-mrLootBlue flex items-center justify-center h-[58px] w-[71px] xxs:h-[70px] xxs:w-[88px]`}
						>
							<img src={option.img} alt={option.amount.toString()} className="h-[22px] object-contain" />
						</button>
					))}
				</div>
			</div>
		</WithPrimaryPopup>
	);
}
