import { PrimaryBtn } from 'components/common/buttons/PrimaryBtn';

import { CookieIcon } from '../assets';

interface Props {
	onClick: () => void;
}

export function CookieAcceptBtn({ onClick }: Props) {
	return (
		<PrimaryBtn
			onClick={onClick}
			content={
				<div className="flex items-center gap-x-[4px] justify-center w-[140px] lgx:w-[180px] h-[42px] lgx:h-[50px]">
					<img src={CookieIcon} alt="" className="h-[13px] lgx:h-[15px] object-contain" />
					<span className="font-bold text-[14px] lgx:text-[16px]">Accept cookies</span>
				</div>
			}
			style={{ width: 'fit-content', height: 'fit-content', borderRadius: '24px' }}
		/>
	);
}
