import { useBoxOpeningStoreMobile } from 'pages/OpenBox/store/useBoxOpeningStoreMobile';
import { shallow } from 'zustand/shallow';

import { PopupBoxPrizes } from './PopupBoxPrizes';
import { PopupOpenWithoutUserSession } from './PopupOpenWithoutUserSession';
import { PopupSpinAmountChooser } from './PopupSpinAmountChooser';

interface Props {
	onDemoBoxOpen: () => void;
}

export function PopupHandler({ onDemoBoxOpen }: Props) {
	const { isBoxPrizesPopupVisible, isOpenWithoutSessionPopupVisible } = useBoxOpeningStoreMobile(
		(state) => ({
			isBoxPrizesPopupVisible: state.isBoxPrizesPopupVisible,
			isOpenWithoutSessionPopupVisible: state.isOpenWithoutSessionPopupVisible,
		}),
		shallow
	);

	return (
		<>
			<PopupSpinAmountChooser />
			{isBoxPrizesPopupVisible && <PopupBoxPrizes />}
			{isOpenWithoutSessionPopupVisible && <PopupOpenWithoutUserSession onDemoBoxOpen={onDemoBoxOpen} />}
		</>
	);
}
