import { Skeleton } from 'components';
import { FeatureBannersSessionTypes, FeatureBannerTypes } from 'interfaces/FeatureBannerInterface';
import { useAutoSlideAnimation } from 'pages/overview/hooks/useAutoSlideAnimation';
import { useLoadFeatureBanners } from 'pages/overview/hooks/useLoadFeatureBanner';
import React, { useRef } from 'react';

import { FeatureBannerCard } from './FeatureBannerCard';
import { FeatureBoxCard } from './FeatureBoxCard';
import { SlidePagination } from './SlidePagination';

export function FeatureBanners() {
	const slideWrapperRef = useRef<HTMLDivElement>(null);

	const { data: featureBanners, isLoading: isFeatureBannersLoading } = useLoadFeatureBanners({
		sessionTypeToShow: FeatureBannersSessionTypes.USER_SESSION,
	});

	const { activeIndex, handleManualSlideChange } = useAutoSlideAnimation({
		slideWrapperRef,
		slideLength: featureBanners?.length || 0,
	});

	return (
		<div className="relative">
			<div className="w-full overflow-hidden flex items-center rounded-[12px] border-[3px] border-[rgba(255,255,255,0.1)] hover:border-white">
				<div className="z-[-1] w-[calc(100%-4px)] h-[calc(100%-4px)] absolute left-[2px] top-[2px] border-[1px] border-[rgba(0,0,0,0.1)] rounded-[10px]" />
				<div
					ref={slideWrapperRef}
					className="w-full flex items-center aspect-[3/1] lg:aspect-[3.3/1] xl:aspect-[3.6/1]"
					style={{ transition: 'all 0.1s ease' }}
				>
					{featureBanners?.map((banner) => (
						<React.Fragment key={banner._id}>
							{banner.type === FeatureBannerTypes.BOX && <FeatureBoxCard box={banner.boxId} />}
							{banner.type === FeatureBannerTypes.BANNER && <FeatureBannerCard featureBanner={banner} />}
						</React.Fragment>
					))}

					{isFeatureBannersLoading && (
						<div className="w-full aspect-[3/1] lg:aspect-[3.3/1] xl:aspect-[3.6/1]">
							<Skeleton style={{ width: '100%', aspectRatio: '1/3', maxHeight: '400px' }} />
						</div>
					)}
				</div>

				<SlidePagination
					numSlides={featureBanners?.length || 0}
					activeIndex={activeIndex}
					onActiveIndexChange={handleManualSlideChange}
				/>
			</div>
		</div>
	);
}
