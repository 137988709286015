import { Skeleton } from 'components';

import { rarities } from '../../../constants';

export function LiveDropSkeleton() {
	return (
		<div
			className="h-full aspect-[0.83/1] px-[11px] lgx:px-[13px] py-[8px]"
			style={{ backgroundColor: rarities.lightBlue.color75 }}
		>
			<Skeleton
				style={{
					borderRadius: '15px',
				}}
				color="#2a68b0"
			/>
		</div>
	);
}
