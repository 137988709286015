import { MobileSearchInput } from 'components/common/inputs/MobileSearchInput';
import { useUserContext } from 'hooks';
import { useQueryParam } from 'hooks/utility/useQueryParam';
import { SortOptionsSlideupSheet } from 'pages/inventory/mobile/popups/SortOptionsSlideupSheet';
import { useCallback, useEffect, useState } from 'react';
import { useAppStore } from 'store/useAppStore';
import { shallow } from 'zustand/shallow';

import { NoSessionPopup } from '../popups/NoSessionPopup';
import { useInventoryStore } from '../store/InventoryStore';
import { BoxViewSwitch } from './components/BoxViewSwitch';
import { FloatingActionMenu } from './components/FloatingActionMenu/FloatingActionMenu';
import { MobileInventoryBoxGrid } from './components/MobileInventoryBoxGrid';
import { MobileInventoryItemsGrid } from './components/MobileInventoryItemsGrid';
import { NoSessionDemoItems } from './components/NoSessionDemoItems';
import { ItemInspectPopup } from './popups/ItemInspectPopup';
import { UnlockAllFeaturesPopup } from './popups/UnlockAllFeaturesPopup';

export function MobileInventory() {
	const {
		searchValue,
		setSearchValue,
		selectedUserItems,
		resetSelectedUserItems,
		isBoxView,
		itemOfInspectPopup,
		setIsBoxView,
	} = useInventoryStore(
		(state) => ({
			searchValue: state.searchValue,
			setSearchValue: state.setSearchValue,
			resetSelectedUserItems: state.resetSelectedUserItems,
			selectedUserItems: state.selectedUserItems,
			itemOfInspectPopup: state.itemOfInspectPopup,
			isBoxView: state.isBoxView,
			setIsBoxView: state.setIsBoxView,
		}),
		shallow
	);

	const [ownsUserItems, setOwnsUserItems] = useState(false);
	const [isOverlayVisible, setIsOverlayVisible] = useState(false);

	const { user } = useUserContext();

	const clearNewWonItemIds = useAppStore((state) => state.clearNewWonItemIds, shallow);

	useEffect(() => {
		return () => {
			clearNewWonItemIds();
		};
	}, [clearNewWonItemIds]);

	useEffect(() => {
		resetSelectedUserItems();
	}, [resetSelectedUserItems]);

	const handleSortOverlayClose = useCallback(() => {
		setIsOverlayVisible(false);
	}, []);

	const handleItemsInInventory = useCallback((v: boolean) => setOwnsUserItems(v), []);

	const handleParams = useCallback(
		(param: string) => {
			if (param === 'winboxes') {
				setIsBoxView(true);
			} else if (param === 'products') {
				setIsBoxView(false);
			}
		},
		[setIsBoxView]
	);

	useQueryParam('view', handleParams);

	return (
		<>
			{itemOfInspectPopup && <ItemInspectPopup item={itemOfInspectPopup} />}
			<SortOptionsSlideupSheet isVisible={isOverlayVisible} onClose={handleSortOverlayClose} />
			<UnlockAllFeaturesPopup />

			<div className={`relative pb-[30px] min-h-svh mt-[5px]`}>
				<div className={`flex flex-col space-y-[40px] ${!user && 'opacity-70 blur-[2px]'}`}>
					<div className="flex flex-col gap-y-[30px]">
						<BoxViewSwitch />
						<MobileSearchInput
							value={searchValue}
							onChange={setSearchValue}
							placeholder="Search..."
							onFilterClick={() => setIsOverlayVisible(true)}
							showFilterBtn={true}
						/>
					</div>

					{user ? (
						<>
							{isBoxView ? (
								<MobileInventoryBoxGrid />
							) : (
								<MobileInventoryItemsGrid onItemsInInventory={handleItemsInInventory} />
							)}
						</>
					) : (
						<NoSessionDemoItems />
					)}
				</div>
				{!user && <NoSessionPopup />}

				{ownsUserItems && selectedUserItems.size > 0 && <FloatingActionMenu />}
			</div>
		</>
	);
}
