import { useUserContext } from 'hooks';
import { useInventoryBoxes } from 'pages/inventory/hooks/useInventoryBoxes';
import { DESKTOP_NUM_PRE_SLOT_PRIZES } from 'pages/OpenBox/box-opening.constants';
import { useBoxFromParams } from 'pages/OpenBox/hooks/useBoxFromParams';
import { useBoxSlotItems } from 'pages/OpenBox/hooks/useBoxSlotItems';
import { useWonBoxItem } from 'pages/OpenBox/hooks/useWonBoxItem';
import { useCallback, useEffect } from 'react';
import { usePopupStore } from 'store/usePopupStore';
import { shallow } from 'zustand/shallow';

import { useBoxOpeningStoreDesktop } from '../../../store/useBoxOpeningStoreDesktop';
import { CenterBtns } from './btns/CenterBtns';
import { LeftBtns } from './btns/LeftBtns';
import { RightBtns } from './btns/RightBtns';
import { FullScreenBalanceDisplay } from './FullScreenBalanceDisplay';
import { SupportFriend } from './SupportFriend';

export default function Settingsbar() {
	const {
		isFullScreen,
		setIsAutoSpin,
		autoSpinCount,
		setIsDemoSpin,
		setSlotPrizesPreWon,
		setIsBoxOpening,
		clientSeed,
		setWonPrize,
		isBoxOpening,
		setSlotPrizesSurroundingWon,
		decreaseAutoSpinCount,
	} = useBoxOpeningStoreDesktop(
		(state) => ({
			setWonPrize: state.setWonPrize,
			isBoxOpening: state.isBoxOpening,
			isFullScreen: state.isFullScreen,
			isFairnessPopupVisible: state.isFairnessPopupVisible,
			setIsAutoSpin: state.setIsAutoSpin,
			autoSpinCount: state.autoSpinCount,
			setIsDemoSpin: state.setIsDemoSpin,
			setSlotPrizesPreWon: state.setSlotPrizesPreWon,
			setIsFullScreen: state.setIsFullScreen,
			setIsBoxOpening: state.setIsBoxOpening,
			clientSeed: state.clientSeed,
			setSlotPrizesSurroundingWon: state.setSlotPrizesSurroundingWon,
			decreaseAutoSpinCount: state.decreaseAutoSpinCount,
			resetStore: state.resetStore,
		}),
		shallow // Prevents re-renders if the values are shallowly equal
	);

	useEffect(() => {
		if (autoSpinCount > 1 && isBoxOpening) {
			setIsAutoSpin(true);
		}
	}, [autoSpinCount, isBoxOpening, setIsAutoSpin]);

	const { load: reloadUser } = useUserContext();

	const { data: box, isLoading: isBoxFromParamsLoading } = useBoxFromParams();

	const handleBoxOpenSuccess = useCallback(() => {
		reloadUser();
	}, [reloadUser]);

	const { requestWinningPrize, isBoxOpenRequestLoading } = useWonBoxItem({
		useMobileStore: false,
		box,
		onBoxOpenSuccess: handleBoxOpenSuccess,
	});

	const { generatePreAndPostSlotItems, generateDemoSpinPrize } = useBoxSlotItems({
		numPreSlotPrizes: DESKTOP_NUM_PRE_SLOT_PRIZES,
	});

	const { data: inventoryBoxes } = useInventoryBoxes();
	const isBonusBoxToOpen = usePopupStore((state) => state.isBonusBoxToOpen, shallow);

	const handleBoxOpen = useCallback(
		(isDemoSpin: boolean) => {
			if (!box || box.name.length === 0 || box.items.length === 0) {
				return;
			}

			const { preSlotItems, surroundingSlotItems } = generatePreAndPostSlotItems(box, !!isBonusBoxToOpen);
			setSlotPrizesPreWon(preSlotItems);
			setSlotPrizesSurroundingWon(surroundingSlotItems);

			if (isDemoSpin) {
				const demoSpinPrize = generateDemoSpinPrize(box);
				setWonPrize(demoSpinPrize);
				setIsBoxOpening(true);
			} else {
				setIsAutoSpin(autoSpinCount > 1);
				decreaseAutoSpinCount();

				requestWinningPrize({
					boxName: box?.name,
					clientSeed,
					useFreeBox: !!inventoryBoxes && inventoryBoxes.length > 0,
				});
				setIsBoxOpening(true);
			}

			setIsDemoSpin(isDemoSpin);
		},
		[
			autoSpinCount,
			box,
			clientSeed,
			decreaseAutoSpinCount,
			generateDemoSpinPrize,
			generatePreAndPostSlotItems,
			inventoryBoxes,
			isBonusBoxToOpen,
			requestWinningPrize,
			setIsAutoSpin,
			setIsBoxOpening,
			setIsDemoSpin,
			setSlotPrizesPreWon,
			setSlotPrizesSurroundingWon,
			setWonPrize,
		]
	);

	const isOpenDisabled = isBoxOpening || isBoxOpenRequestLoading || isBoxFromParamsLoading;

	return (
		<div className={`${isFullScreen && 'z-[110]'} w-full flex items-center relative z-10`}>
			<div className="flex justify-between items-center w-full">
				{isFullScreen ? (
					<>
						<SupportFriend />
						<CenterBtns onBoxOpen={handleBoxOpen} isOpenFnDisabled={isOpenDisabled} />
						<FullScreenBalanceDisplay />
					</>
				) : (
					<>
						<LeftBtns />
						<CenterBtns onBoxOpen={handleBoxOpen} isOpenFnDisabled={isOpenDisabled} />
						<RightBtns />
					</>
				)}
			</div>
		</div>
	);
}
