import { FontRegister } from 'assets/images';
import { PrimaryBtn } from 'components/common/buttons/PrimaryBtn';
import { useWindowDimensions } from 'hooks';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppStore } from 'store/useAppStore';
import { shallow } from 'zustand/shallow';

import { screens } from '../../../../constants';

export function MobileRegisterBtn() {
	const navigate = useNavigate();
	const location = useLocation();

	const setLastLocationBeforeLogin = useAppStore((state) => state.setLastLocationBeforeLogin, shallow);

	function handleSignUpNavigate() {
		const prevUrl = location.pathname;
		setLastLocationBeforeLogin(prevUrl);
		navigate('/auth/register');
	}

	const { screenWidth } = useWindowDimensions();

	return (
		<PrimaryBtn
			onClick={handleSignUpNavigate}
			style={{
				width: 'fit-content',
				height: 'fit-content',
				borderRadius: screenWidth >= screens.lgx.width ? '25px' : '20px',
			}}
			content={
				<span className="text-white text-[12px] font-bold w-[100px] ms:w-[110px] lgx:w-[152px] h-[34px] ms:h-[40px] lgx:h-[52px] flex items-center justify-center">
					<img src={FontRegister} alt="register" className="h-[20px] lgx:h-[28px]" />
				</span>
			}
		/>
	);
}
