import { TOTAL_SLOT_PRIZES } from 'pages/OpenBox/box-opening.constants';
import { PrizeInterface } from 'pages/OpenBox/boxOpening.interface';
import { PrizeCardList } from 'pages/OpenBox/components/PrizeCardList';
import { useTranslatePrizeWrapper } from 'pages/OpenBox/hooks/useTranslatePrizeWrapper';
import { useBoxOpeningStoreDesktop } from 'pages/OpenBox/store/useBoxOpeningStoreDesktop';
import { forwardRef, memo, useCallback, useEffect, useImperativeHandle, useLayoutEffect, useMemo, useRef } from 'react';
import { shallow } from 'zustand/shallow';

import { AutospinTransitionBuffer } from './AutospinTransitionBuffer';
import { WonPrizeCard } from './WonPrizeCard';

interface Props {
	autoSpinLoadingBufferPrizes?: PrizeInterface[];
	onSpinPrizesLoaded: () => void; // Triggered when spin prizes (excluding won prize) are loaded
	onAutoSpinTransitionPrizesLoaded: () => void;
	onImageLoadingFailed: () => void;
}

interface SlotPrizesWrapperRef {
	getContainerElement: () => HTMLDivElement | null;
}

// Separate isEqual function
const isEqual = (prevProps: Props, nextProps: Props): boolean => {
	// Check autoSpinLoadingBufferPrizes length
	if (prevProps.autoSpinLoadingBufferPrizes?.length !== nextProps.autoSpinLoadingBufferPrizes?.length) {
		return false;
	}

	// Compare array contents
	if (prevProps.autoSpinLoadingBufferPrizes && nextProps.autoSpinLoadingBufferPrizes) {
		for (let i = 0; i < prevProps.autoSpinLoadingBufferPrizes.length; i++) {
			const prevPrize = prevProps.autoSpinLoadingBufferPrizes[i];
			const nextPrize = nextProps.autoSpinLoadingBufferPrizes[i];

			if (prevPrize.key !== nextPrize.key || prevPrize.type !== nextPrize.type) {
				return false;
			}
		}
	}

	// Check individual callback functions
	const callbacks = ['onSpinPrizesLoaded', 'onAutoSpinTransitionPrizesLoaded', 'onImageLoadingFailed'] as const;

	for (const callback of callbacks) {
		if (prevProps[callback] !== nextProps[callback]) {
			return false;
		}
	}

	return true;
};

// Component definition
const SlotPrizesWrapperComponent = forwardRef<SlotPrizesWrapperRef, Props>(
	(
		{ autoSpinLoadingBufferPrizes, onAutoSpinTransitionPrizesLoaded, onSpinPrizesLoaded, onImageLoadingFailed },
		forwardRef
	) => {
		useImperativeHandle(
			forwardRef,
			() => ({
				getContainerElement: () => slotItemsWrapperRef.current,
			}),
			[]
		);

		const { slotPrizesSurroundingWon, slotPrizesPreWon, isBoxOpening } = useBoxOpeningStoreDesktop(
			(state) => ({
				slotPrizesSurroundingWon: state.slotPrizesSurroundingWon,
				slotPrizesPreWon: state.slotPrizesPreWon,
				isBoxOpening: state.isBoxOpening,
			}),
			shallow
		);

		const { leftSidePrizes, rightSidePrizes } = useMemo(() => {
			const preWon = slotPrizesPreWon ?? [];
			const surroundingWon = slotPrizesSurroundingWon ?? [];

			return {
				leftSidePrizes: [...preWon, ...surroundingWon.slice(0, Math.floor(surroundingWon.length / 2))],
				rightSidePrizes: surroundingWon.slice(Math.floor(surroundingWon.length / 2)),
			};
		}, [slotPrizesPreWon, slotPrizesSurroundingWon]);

		const slotItemsWrapperRef = useRef<HTMLDivElement>(null);
		const hasAdjustedSlotTranslation = useRef(false);
		const hasWonPrizeImgLoaded = useRef(false);

		const { translatePrizeWrapperByCards } = useTranslatePrizeWrapper({ itemsWrapperRef: slotItemsWrapperRef });

		useLayoutEffect(() => {
			if (autoSpinLoadingBufferPrizes && !hasAdjustedSlotTranslation.current) {
				translatePrizeWrapperByCards(autoSpinLoadingBufferPrizes.length);
				hasAdjustedSlotTranslation.current = true;
			}
		}, [autoSpinLoadingBufferPrizes, translatePrizeWrapperByCards]);

		useEffect(() => {
			if (!isBoxOpening) {
				hasAdjustedSlotTranslation.current = false;
			}
		}, [isBoxOpening]);

		const slotPrizeLoadCounter = useRef(0);

		const checkAllAssetsLoaded = useCallback(() => {
			if (!hasWonPrizeImgLoaded.current || slotPrizeLoadCounter.current < TOTAL_SLOT_PRIZES - 1) {
				return;
			}

			onSpinPrizesLoaded();

			slotPrizeLoadCounter.current = 0;
			hasWonPrizeImgLoaded.current = false;
		}, [onSpinPrizesLoaded]);

		const handleSlotPrizesLoaded = useCallback(() => {
			slotPrizeLoadCounter.current += 1;
			checkAllAssetsLoaded();
		}, [checkAllAssetsLoaded]);

		const handleWonPrizeLoaded = useCallback(() => {
			hasWonPrizeImgLoaded.current = true;

			checkAllAssetsLoaded();
		}, [checkAllAssetsLoaded]);

		return (
			<div
				ref={slotItemsWrapperRef}
				className="h-full flex items-center justify-start w-full gap-x-[30px] will-change-transform"
			>
				{autoSpinLoadingBufferPrizes && (
					<AutospinTransitionBuffer
						transitionPrizes={autoSpinLoadingBufferPrizes}
						onCompleteLoading={onAutoSpinTransitionPrizesLoaded}
						onTotalLoadingFail={onImageLoadingFailed}
					/>
				)}
				{/*<SpinLoadingBuffer onLoadingComplete={handleBufferPrizesLoaded} onTotalLoadingFail={onImageLoadingFailed} />*/}
				{leftSidePrizes.length > 0 && rightSidePrizes.length > 0 && (
					<>
						<PrizeCardList
							prizes={leftSidePrizes}
							onImageLoad={handleSlotPrizesLoaded}
							onImageError={onImageLoadingFailed}
						/>
						<WonPrizeCard onImageLoad={handleWonPrizeLoaded} onImageError={onImageLoadingFailed} />
						<PrizeCardList
							prizes={rightSidePrizes}
							onImageLoad={handleSlotPrizesLoaded}
							onImageError={onImageLoadingFailed}
						/>
					</>
				)}
			</div>
		);
	}
);

SlotPrizesWrapperComponent.displayName = 'SlotPrizesWrapper';

// Memoized export
export const SlotPrizesWrapper = memo(SlotPrizesWrapperComponent, isEqual);
