import axios from 'axios';
import { useGTMBoxOpenTracker } from 'hooks/userInteractions/useGTMBoxOpenTracker';
import { PopulatedBoxInterface } from 'interfaces/BoxInterface';
import { useBoxOpeningStoreDesktop } from 'pages/OpenBox/store/useBoxOpeningStoreDesktop';
import { useBoxOpeningStoreMobile } from 'pages/OpenBox/store/useBoxOpeningStoreMobile';
import { useCallback } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { getHighestValueTag } from 'utils';
import { v4 as uuidv4 } from 'uuid';
import { shallow } from 'zustand/shallow';

import { API_DOMAIN, QUERY_KEYS } from '../../../constants';
import { PrizeInterface, PrizeType, WonItem } from '../boxOpening.interface';

async function handleBoxOpen(boxName: string, clientSeed: string, useFreeBox: boolean) {
	const response = await axios.post(
		`${API_DOMAIN.mrlootBackend}/api/boxes/open`,
		{ boxName, clientSeed, useFreeBox },
		{ withCredentials: true }
	);

	return response.data.data;
}

interface Props {
	onBoxOpenSuccess?: (wonPrize: PrizeInterface) => void;
	useMobileStore?: boolean;
	box?: PopulatedBoxInterface;
}

export function useWonBoxItem({ onBoxOpenSuccess: onBoxOpenComplete, useMobileStore = true, box }: Props) {
	const setWonPrizeMobile = useBoxOpeningStoreMobile((state) => state.setWonPrize, shallow);
	const { setWonPrizeDesktop } = useBoxOpeningStoreDesktop(
		(state) => ({ setWonPrizeDesktop: state.setWonPrize }),
		shallow
	);

	const trackBoxOpen = useGTMBoxOpenTracker();

	const queryClient = useQueryClient();

	const { mutate: requestWinningPrize, isLoading: isBoxOpenRequestLoading } = useMutation(
		({ boxName, clientSeed, useFreeBox }: { boxName: string; clientSeed: string; useFreeBox: boolean }) =>
			handleBoxOpen(boxName, clientSeed, useFreeBox),
		{
			onSuccess: useCallback(
				(data: WonItem) => {
					queryClient.invalidateQueries(QUERY_KEYS.inventoryBoxData);
					queryClient.invalidateQueries(QUERY_KEYS.inventoryItemData);
					const key = uuidv4();

					const newWonPrize = { data: data, key: key, type: PrizeType.ITEM };
					if (box) {
						const boxCategory = box.tags.length > 0 ? getHighestValueTag(box.tags) : '';

						trackBoxOpen({
							amountSpent: box.price,
							boxCategory,
							boxId: box.name,
							numBoxesOpened: 1,
							amountWon: newWonPrize.data.price,
						});
					}

					if (useMobileStore) {
						setWonPrizeMobile(newWonPrize);
					} else {
						setWonPrizeDesktop(newWonPrize);
					}
					onBoxOpenComplete && onBoxOpenComplete(newWonPrize);
				},
				[box, onBoxOpenComplete, queryClient, setWonPrizeDesktop, setWonPrizeMobile, trackBoxOpen, useMobileStore]
			),
		}
	);

	return { requestWinningPrize, isBoxOpenRequestLoading };
}
