/* eslint-disable complexity */
import { Icon } from '@iconify/react';
import { useUserContext } from 'hooks';
import { Link, useLocation } from 'react-router-dom';
import { useAppStore } from 'store/useAppStore';
import { shallow } from 'zustand/shallow';

const NAVIGATION_ICONS = [
	{
		defaultIcon: 'ant-design:home-outlined',
		activeIcon: 'ant-design:home-filled',
		label: 'Home',
		noSessionLink: '/',
		sessionLink: '/',
	},
	{
		defaultIcon: 'mdi:compass-outline',
		activeIcon: 'mdi:compass',
		label: 'Discover',
		noSessionLink: '/discover',
		sessionLink: '/discover',
	},
	{
		defaultIcon: 'lets-icons:box-open',
		activeIcon: 'lets-icons:box-open-fill',
		label: 'Inventory',
		noSessionLink: '/inventory',
		sessionLink: '/inventory',
	},
	{
		defaultIcon: 'ri:user-line',
		activeIcon: 'ri:user-fill',
		label: 'User Settings',
		noSessionLink: '/auth/login',
		sessionLink: '/my-account',
	},
];

export function MobileNavBar() {
	const location = useLocation();

	const { user } = useUserContext();

	const newWonItemIds = useAppStore((state) => state.newWonItemIds, shallow);

	const showNewItemInInvDot = newWonItemIds && newWonItemIds.length > 0;

	const isPathMatching = (sessionLink: string, noSessionLink: string): boolean => {
		const currentPath = location.pathname;

		// Handle exact match for root path
		if ((sessionLink === '/' || noSessionLink === '/') && currentPath === '/') {
			return true;
		}

		// Match paths exactly or as prefixes with a trailing slash
		return (
			currentPath === sessionLink ||
			currentPath.startsWith(`${sessionLink}/`) ||
			currentPath === noSessionLink ||
			currentPath.startsWith(`${noSessionLink}/`)
		);
	};

	return (
		<div className="fixed h-[56px] lgx:h-[74px] max-w-[700px] flex justify-center left-1/2 -translate-x-1/2 bottom-[18px] w-full px-[24px] z-40">
			<div className="text-[18px] h-full w-full flex items-center justify-around border-[1px] border-white rounded-[21px] bg-primary-gradient overflow-hidden">
				{NAVIGATION_ICONS.map((el, index) => (
					<Link
						key={index}
						to={user ? el.sessionLink : el.noSessionLink}
						aria-label={el.label}
						className={`relative text-[24px] lgx:text-[30px] h-full text-center flex items-center text-white`}
					>
						<div className="relative h-fit w-fit">
							{showNewItemInInvDot && el.sessionLink === '/inventory' && (
								<div className="absolute z-10 -right-[2px] -top-[2px] rounded-full bg-mrLootBlue h-[8px] w-[8px] animate-pulse" />
							)}

							<Icon icon={isPathMatching(el.sessionLink, el.noSessionLink) ? el.activeIcon : el.defaultIcon} />
						</div>
						{isPathMatching(el.sessionLink, el.noSessionLink) && (
							<span className="absolute  bottom-0 w-[24px] lgx:w-[32px] h-[6px] rounded-full bg-white translate-y-1/2"></span>
						)}
					</Link>
				))}
			</div>
		</div>
	);
}
