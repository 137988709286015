import { persist } from 'zustand/middleware';
import { createWithEqualityFn } from 'zustand/traditional';

interface PopupState {
	isDepositPopupVisible: boolean;
	setIsDepositPopupVisible: (isVisible: boolean | ((isVisible: boolean) => boolean)) => void;

	signupBonusExpiredAt: Date | null;
	setSignupBonusExpiredAt: (date: Date | null) => void;

	isSignupBonusPopupVisible: boolean;
	setIsSignupBonusPopupVisible: (isVisible: boolean) => void;

	isBonusBoxToOpen: boolean;
	setIsBonusBoxToOpen: (isToOpen: boolean) => void;

	isCurrencyPopupVisible: boolean;
	setIsCurrencyPopupVisible: (isVisible: boolean) => void;

	isDepositBonusPopupVisible: boolean;
	setIsDepositBonusPopupVisible: (isVisible: boolean) => void;
}

// eslint-disable-next-line no-magic-numbers
export const SIGNUP_OFFER_TIME = 30 * 60 * 1000; // 30 minutes

export const usePopupStore = createWithEqualityFn<PopupState>()(
	persist(
		(set) => ({
			isDepositPopupVisible: false,
			setIsDepositPopupVisible: (isVisible) =>
				set((state) => ({
					isDepositPopupVisible: typeof isVisible === 'function' ? isVisible(state.isDepositPopupVisible) : isVisible,
				})),
			isSignupBonusPopupVisible: false,
			setIsSignupBonusPopupVisible: (isVisible) => set({ isSignupBonusPopupVisible: isVisible }),
			signupBonusExpiredAt: null,
			setSignupBonusExpiredAt: (date) => set({ signupBonusExpiredAt: date }),
			isCurrencyPopupVisible: false,
			setIsCurrencyPopupVisible: (isVisible) => set({ isCurrencyPopupVisible: isVisible }),
			isBonusBoxToOpen: false,
			setIsBonusBoxToOpen: (isToOpen) => set({ isBonusBoxToOpen: isToOpen }),
			isDepositBonusPopupVisible: false,
			setIsDepositBonusPopupVisible: (isVisible) => set({ isDepositBonusPopupVisible: isVisible }),
		}),
		{
			name: 'sales-store',
			partialize: (state) => ({
				signupBonusExpiredAt: state.signupBonusExpiredAt,
				isBonusBoxToOpen: state.isBonusBoxToOpen,
			}),
			merge: (persistedState, currentState) => {
				const castState = persistedState as Partial<PopupState>;
				return {
					...currentState,
					signupBonusExpiredAt: castState.signupBonusExpiredAt ? new Date(castState.signupBonusExpiredAt) : null,
					isBonusBoxToOpen: castState.isBonusBoxToOpen ?? currentState.isBonusBoxToOpen,
				};
			},
		}
	),
	Object.is
);
