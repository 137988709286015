import { faArrowDown19, faArrowDown91, faCheck, faFire, faStar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SecondaryBtn } from 'components/common/buttons/SecondaryBtn';
import { useEffect, useState } from 'react';
import { BottomSheet } from 'react-spring-bottom-sheet';
import { useAppStore } from 'store/useAppStore';
import { shallow } from 'zustand/shallow';

import { SearchSortOptions } from '../seach.interface';
import { useSearchStore } from '../store/SearchStore';
import { AmountRangeFilter } from './AmountRangeFilter';

const OPTIONS = [
	{
		label: 'Popular',
		value: SearchSortOptions.POPULAR,
		icon: faFire,
	},
	{
		label: 'Newest',
		value: SearchSortOptions.NEWEST,
		icon: faStar,
	},
	{
		label: 'Price Low',
		value: SearchSortOptions.PRICE_LOW,
		icon: faArrowDown19,
	},
	{
		label: 'Price High',
		value: SearchSortOptions.PRICE_HIGH,
		icon: faArrowDown91,
	},
];

const SNAP_POINT = 450;

interface Props {
	isVisible: boolean;
	onClose: () => void;
}

export function FilterSlideupSheet({ isVisible, onClose }: Props) {
	const [showBackground, setShowBackground] = useState(false);

	const { priceFilterMax, priceFilterMin, setPriceFilterMax, setPriceFilterMin, setSortOption, sortOption } =
		useSearchStore(
			(state) => ({
				priceFilterMin: state.priceFilterMin,
				priceFilterMax: state.priceFilterMax,
				setPriceFilterMin: state.setPriceFilterMin,
				setPriceFilterMax: state.setPriceFilterMax,
				sortOption: state.sortOption,
				setSortOption: state.setSortOption,
			}),
			shallow
		);

	useEffect(() => {
		if (isVisible) {
			setShowBackground(true);
		} else {
			window.setTimeout(() => setShowBackground(false), 100);
		}
	}, [isVisible]);

	function handleOptionClick(option: SearchSortOptions) {
		setSortOption(option);
		onClose();
	}

	const selectedCurrency = useAppStore((state) => state.selectedCurrency, shallow);

	return (
		<div className={`fixed z-40 inset-0 w-screen h-screen ${showBackground ? 'block' : 'hidden'}`}>
			<BottomSheet
				style={{ zIndex: 40, position: 'absolute' }}
				open={isVisible}
				onDismiss={onClose}
				snapPoints={() => SNAP_POINT}
				header={<div className="h-[7px] "></div>}
			>
				<div className="w-screen h-full rounded-[12px_12px_0_0] flex flex-col pt-[10px] px-[20px] pb-[30px]">
					<h1 className="text-white font-bold text-[20px]">Sort By</h1>
					<div className="flex flex-wrap items-center justify-center gap-x-[20px] gap-y-[20px] mt-[15px]">
						{OPTIONS?.map((option, index) => (
							<SecondaryBtn
								key={index}
								onClick={() => handleOptionClick(option.value)}
								style={{
									width: '150px',
									height: '55px',
									padding: '0px',
									boxShadow: 'none',
								}}
								content={
									<div
										className={`h-full w-full grid grid-cols-[20px_auto_20px] rounded-[19px] items-center justify-items-center px-[12px] ${option.value === sortOption ? 'shadow-secondaryInset' : 'hover:shadow-secondaryInset'}`}
									>
										{option.icon && <FontAwesomeIcon icon={option.icon} />}
										<span className="text-[15px] font-bold text-center">{option.label}</span>
										{option.value === sortOption && <FontAwesomeIcon icon={faCheck} className="" />}
									</div>
								}
							/>
						))}
					</div>

					<h1 className="text-white font-bold mb-[15px] mt-[30px] text-[20px]">Price Range</h1>
					<AmountRangeFilter
						defaultMinValue={priceFilterMin}
						defaultMaxValue={priceFilterMax}
						currency={selectedCurrency}
						onMinPriceChange={(v) => setPriceFilterMin(v)}
						onMaxPriceChange={(v) => setPriceFilterMax(v)}
					/>
				</div>
			</BottomSheet>
		</div>
	);
}
