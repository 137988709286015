import { GridSearchBoxes } from './components/GridSearchBoxes';
import { SearchInputField } from './components/SeachInputField';
import { SearchKeywordSuggestions } from './components/SearchKeywordSuggestions';

export function DesktopSearch() {
	return (
		<>
			<div className="pb-10 mt-[63px] flex flex-col">
				<SearchInputField />

				<SearchKeywordSuggestions />

				<GridSearchBoxes />
			</div>
		</>
	);
}
