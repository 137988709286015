import axios from 'axios';
import { UserGift } from 'interfaces/UserGift.interface';
import { useQuery } from 'react-query';

import { API_DOMAIN, ONE_MINUTE_IN_MS, QUERY_KEYS } from '../../../constants';

async function requestUnclaimedUserGifts() {
	try {
		const response = await axios.get(`${API_DOMAIN.mrlootBackend}/api/user/get-and-redeem-unclaimed-gifts`, {
			withCredentials: true,
		});
		if (response.data.status === 'success') {
			return response.data.data;
		}
	} catch (err) {
		if (axios.isAxiosError(err)) {
			const message = err.response?.data?.message || err.message || 'Something unexpected went wrong';
			throw new Error(message);
		}
		throw err;
	}
}

export function useUnclaimedUserGifts() {
	return useQuery<UserGift[]>({
		queryKey: [QUERY_KEYS.unclaimedUserGifts],
		queryFn: requestUnclaimedUserGifts,
		// eslint-disable-next-line no-magic-numbers
		refetchInterval: 5 * ONE_MINUTE_IN_MS,
	});
}
