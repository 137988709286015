import { DollarAndLootieGroup, FontWalletRefill } from 'assets/images';
import { WithPrimaryPopup } from 'components/common/popups/WithPrimaryPopup';
import { ToastErrorNotification } from 'components/toasts/ToastErrorNotification';
import ReactDOM from 'react-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { usePopupStore } from 'store/usePopupStore';
import { shallow } from 'zustand/shallow';

import { useStripeCheckoutCreator } from '../hooks/useStripeCheckoutCreator';
import { PaymentMethod } from '../transaction.interface';
import { CryptoPanel } from './components/CryptoPanel';
import { DepositAmountPanel } from './components/DepositAmountPanel/DepositAmountPanel';
import { PaymentMethodsList } from './components/PaymentMethodsList';

export interface FormData {
	affiliateCode: string;
	giftCode: string;
	paymentMethod: PaymentMethod;
	amount: number;
	currency: string;
}

export function DepositPopup() {
	const { isDepositPopupVisible, setIsDepositPopupVisible } = usePopupStore(
		(state) => ({
			setIsDepositPopupVisible: state.setIsDepositPopupVisible,
			isDepositPopupVisible: state.isDepositPopupVisible,
		}),
		shallow
	);

	const methods = useForm<FormData>({
		defaultValues: {
			affiliateCode: '',
			giftCode: '',
			paymentMethod: PaymentMethod.CREDIT_CARD,
			amount: 50,
		},
	});

	const { watch } = methods;

	const paymentMethod = watch('paymentMethod');

	const { mutate: createStripeCheckout, isLoading: isStripeCheckoutCreationLoading } = useStripeCheckoutCreator({
		onError: (error: any) => {
			console.error('Error creating Stripe Checkout:', error.message);
			toast(<ToastErrorNotification message="Something went wrong" />);
		},
	});

	const onSubmit = async (data: FormData) => {
		if (isStripeCheckoutCreationLoading) {
			return;
		}

		try {
			createStripeCheckout({ amount: data.amount, currency: data.currency, method: data.paymentMethod });
		} catch (error) {
			console.error('Error:', error);
		}
	};

	const portalRoot = document.getElementById('portal-popup-root');

	if (!portalRoot) {
		console.error('Portal root not found. Make sure the element with id "portal-popup-root" exists in your HTML.');
		return null;
	}

	return ReactDOM.createPortal(
		<WithPrimaryPopup
			onClose={() => setIsDepositPopupVisible(false)}
			useAnimation={true}
			isExternallyVisible={isDepositPopupVisible}
			isDesktop={true}
			scrollbarOutside={true}
		>
			<FormProvider {...methods}>
				<form onSubmit={methods.handleSubmit(onSubmit)} className="w-[630px] mds:w-[740px] lg:w-[810px] min-h-[690px]">
					<div className="flex items-center w-full gap-x-[5px]">
						<img src={DollarAndLootieGroup} alt="" className="h-[35px] w-auto object-scale-down" />
						<img src={FontWalletRefill} alt="" className="h-[30px] w-auto" />
					</div>

					<div className="flex items-start mt-[20px] justify-between gap-[25px] lg:gap-[40px] overflow-y-auto ">
						<PaymentMethodsList />
						{paymentMethod === PaymentMethod.CRYPTO && <CryptoPanel />}
						{paymentMethod !== PaymentMethod.CRYPTO && (
							<DepositAmountPanel isStripeCheckoutLoading={isStripeCheckoutCreationLoading} />
						)}
					</div>
				</form>
			</FormProvider>
		</WithPrimaryPopup>,
		portalRoot
	);
}
