/* eslint-disable max-lines-per-function */
/* eslint-disable complexity */
import { faUpRightAndDownLeftFromCenter } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BlueMFont } from 'assets/images';
import { AmountDisplayWithCurrency } from 'components/common/AmountDisplayWithCurrency';
import { useFallbackIfCqhUnsupported } from 'hooks/utility/useFallbackIfCqhUnsupported';
import { ItemInterface } from 'interfaces/ItemInterfaces';
import { CSSProperties, forwardRef, useRef } from 'react';
import { CurrencyOptions, useAppStore } from 'store/useAppStore';
import { formatProbability, getBgCardUrl, getBgCardUrlLandscape } from 'utils';
import { shallow } from 'zustand/shallow';

import { CLOUDFRONT_IMG_URL, rarities } from '../../../constants';

export type MinimalItemInterface = Pick<ItemInterface, '_id' | 'name' | 'brandName' | 'price' | 'rarity' | 'imageUrls'>;

interface Props {
	item: MinimalItemInterface;
	showBrandname?: boolean;
	style?: React.CSSProperties;
	isHoverable?: boolean;
	isSelected?: boolean;
	probability?: number;
	imgQuali?: 'high' | 'medium' | 'low';
	showExpandBtn?: boolean;
	onExpandTooggle?: () => void;
	onClick?: () => void;
	onImageLoad?: () => void;
	onImageError?: () => void;
}

export const ItemCard = forwardRef<HTMLDivElement, Props>(
	(
		{
			item,
			showBrandname,
			isSelected,
			style,
			probability,
			isHoverable,
			imgQuali = 'medium',
			showExpandBtn,
			onClick,
			onImageError,
			onExpandTooggle,
			onImageLoad,
		}: Props,
		ref
	) => {
		const parentRef = useRef<HTMLDivElement>(null);
		const headerRef = useRef<HTMLDivElement>(null);
		const probabilityRef = useRef<HTMLSpanElement>(null);
		const imageRef = useRef<HTMLImageElement>(null);
		const nameRef = useRef<HTMLDivElement>(null);
		const priceContainerRef = useRef<HTMLDivElement>(null);

		const selectedCurrency = useAppStore((state) => state.selectedCurrency, shallow);

		useFallbackIfCqhUnsupported({
			parentRef,
			refs: [
				{ ref: headerRef, proportion: 0.105, cssProperty: 'height' },
				{ ref: probabilityRef, proportion: 0.065, cssProperty: 'fontSize' },
				{ ref: imageRef, proportion: 0.41, cssProperty: 'height' },
				{ ref: nameRef, proportion: 0.05, cssProperty: 'fontSize' },
				{ ref: priceContainerRef, proportion: 0.16, cssProperty: 'height' },
			],
		});

		const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
			if (event.key === 'Enter' || event.key === ' ') {
				onClick?.();
			}
		};

		const itemImgPath =
			imgQuali === 'high'
				? item.imageUrls?.qualityHigh
				: imgQuali === 'medium'
					? item.imageUrls?.qualityMedium
					: item.imageUrls?.qualityLow;

		return (
			<div
				ref={ref || parentRef}
				role={onClick ? 'button' : undefined}
				tabIndex={onClick ? 0 : undefined}
				onClick={onClick}
				onKeyDown={onClick ? handleKeyDown : undefined}
				className={`size-container ${isSelected ? 'border-white' : 'border-transparent'} hover:drop-shadow-[0_0_4px_var(--shadow-color)] border-[2px] rounded-[16px] relative flex flex-col aspect-[0.64/1] flex-shrink-0 pb-[12px] pt-[6px] xxl:pt-3 items-center justify-between`}
				style={
					{
						...style,
						'--shadow-color': isHoverable ? rarities[item.rarity].color : 'transparent',
					} as CSSProperties
				}
			>
				<div className="z-[-2] rounded-[15px] absolute inset-0 bg-black w-full h-full" />
				<img
					alt={`Card background color ${rarities[item.rarity].label}`}
					src={getBgCardUrl(item.rarity)}
					className="z-0 h-full w-full object-fill absolute inset-0 rounded-[15px]"
				/>
				<div ref={headerRef} className="flex z-10 justify-between items-center w-full px-3 h-[10.5cqh]">
					<img alt="" src={BlueMFont} className="h-full" />
					{probability && (
						<span ref={probabilityRef} className="font-[600] font-inter tracking-[0.5px] text-white text-[6cqh]">
							{formatProbability(probability)}
						</span>
					)}
					{showExpandBtn && (
						<button
							onClick={(event) => {
								event.stopPropagation();
								onExpandTooggle?.();
							}}
							className={`relative active:shadow-[inset_0px_-1px_7px_rgba(0,0,0,0.5)] transition-all flex items-center justify-center h-full aspect-[1.5/1] text-[5.5cqh] active:drop-shadow-[0_0_4px_rgba(255,255,255,0.6)] rounded-[8px]`}
							style={{
								background: `url(${getBgCardUrlLandscape(item.rarity)})`,
								backgroundSize: 'cover',
								backgroundPosition: 'center',
							}}
						>
							<FontAwesomeIcon icon={faUpRightAndDownLeftFromCenter} className="text-white" />
						</button>
					)}
				</div>
				<div className={`mt-1 flex flex-col px-3 justify-between flex-grow w-full space-y-1 z-10 my-auto`}>
					{itemImgPath ? (
						<img
							ref={imageRef}
							src={`${CLOUDFRONT_IMG_URL}/${itemImgPath}`}
							onLoad={onImageLoad}
							onError={onImageError}
							alt={item.name}
							className="max-w-full mx-auto object-scale-down h-[41cqh]"
						/>
					) : (
						<div className="max-w-full mx-auto h-[41cqh] bg-slate-200 rounded-[8px]" />
					)}

					<div
						ref={nameRef}
						className="flex flex-col justify-center items-center text-white"
						style={{ fontSize: '5cqh' }}
					>
						{showBrandname && item.brandName !== 'undefined' && (
							<span className={`text-center font-[400] line-clamp-1`}>{item.brandName}</span>
						)}
						<span className="text-center font-[600] line-clamp-2">{item.name}</span>
					</div>

					<div
						ref={priceContainerRef}
						className="relative grid grid-cols-[1fr_2fr_1fr] items-center px-[6px] font-semibold w-full mx-auto text-white h-[16cqh]"
					>
						<img
							alt={`Card background color is ${rarities[item.rarity].label}`}
							src={getBgCardUrl(item.rarity)}
							className="z-[-1] absolute w-full h-full object-fill left-0 top-0 rotate-180 rounded-[8px]"
						/>
						<AmountDisplayWithCurrency
							amount={item.price}
							useDivWrapper={false}
							amountStyle={{ fontSize: '5.8cqh', textAlign: 'center' }}
							imgStyle={{ height: '6.8cqh' }}
							FirstElementWrapper={({ children }) => (
								<div
									className={`${selectedCurrency === CurrencyOptions.LOOTIES ? 'col-start-2 col-end-3 ' : 'col-start-1 col-end-2'} flex items-center justify-center  `}
								>
									{children}
								</div>
							)}
							SecondElementWrapper={({ children }) => (
								<span
									className={`${selectedCurrency === CurrencyOptions.LOOTIES ? 'col-start-3 col-end-4' : 'col-start-2 col-end-3 '} flex  items-center justify-center `}
								>
									{children}
								</span>
							)}
						/>
					</div>
				</div>
			</div>
		);
	}
);
