/* eslint-disable max-lines-per-function */
import { useBoxFromDb, useScrollLock, useUserContext } from 'hooks';
import { usePaymentTransactions } from 'hooks/data/transactions/usePaymentTransactions';
import { useKeepBackgroundScrollPos } from 'hooks/layout/useKeepBackgroundScrollPos';
import { useSound } from 'hooks/utility/useSound';
import { useInventoryBoxes } from 'pages/inventory/hooks/useInventoryBoxes';
import { useCallback, useEffect } from 'react';
import { usePopupStore } from 'store/usePopupStore';
import { v4 as uuidv4 } from 'uuid';
import { shallow } from 'zustand/shallow';

import { BoxOpenBackgroundSound } from '../assets';
import { FADE_OUT_DURATION_BG_SPIN_SOUND, MOBILE_FADE_IN_DURATION_BG_SPIN_SOUND } from '../box-opening.constants';
import { useBoxClientSeed } from '../hooks/useBoxClientSeed';
import { useBoxNameFromParams } from '../hooks/useBoxNameFromParams';
import { useBoxScreenLeaveCleanUp } from '../hooks/useBoxScreenLeaveCleanUp';
import { useBoxSlotItems } from '../hooks/useBoxSlotItems';
import { useWonBoxItem } from '../hooks/useWonBoxItem';
import { useBoxOpeningStoreMobile } from '../store/useBoxOpeningStoreMobile';
import { MobileBoxStack } from './MobileBoxStack';
import { PopupHandler } from './popups/PopupHandler';
import { MobileBoxOpenSlot } from './slot/MobileBoxOpenSlot';
import { MobileWonPrizeScreen } from './wonPrizeScreen/MobileWonPrizeScreen';

export function MobileBoxOpen() {
	const { addBoxToPopupStack, boxPopupStack } = useBoxOpeningStoreMobile(
		(state) => ({
			addBoxToPopupStack: state.addBoxToPopupStack,
			boxPopupStack: state.boxPopupStack,
		}),
		shallow
	);

	const boxNameFromParams = useBoxNameFromParams();

	useEffect(() => {
		if (boxNameFromParams) {
			const id = uuidv4();
			addBoxToPopupStack({
				id,
				boxName: boxNameFromParams,
				isSimiliarBoxesPopupVisible: false,
				prizeInspectPopup: null,
			});
		}
	}, [addBoxToPopupStack, boxNameFromParams]);

	const currentBoxname = boxPopupStack.length > 0 ? boxPopupStack[boxPopupStack.length - 1].boxName : undefined;
	const { data: box } = useBoxFromDb(currentBoxname);

	const {
		isBoxOpening,
		setIsBoxOpening,
		clientSeed,
		showWonScreen,
		setIsDemoSpin,
		autoSpinCount,
		setAutoSpinCount,
		setIsAutoSpin,
		setSlotPrizesPreWon,
		setSlotPrizesSurroundingWon,
		setWonPrize,
		setShowLoadingScreen,
	} = useBoxOpeningStoreMobile(
		(state) => ({
			isBoxOpening: state.isBoxOpening,
			setIsBoxOpening: state.setIsBoxOpening,
			clientSeed: state.clientSeed,
			showWonScreen: state.showWonScreen,
			setIsDemoSpin: state.setIsDemoSpin,
			autoSpinCount: state.autoSpinCount,
			setAutoSpinCount: state.setAutoSpinCount,
			setIsAutoSpin: state.setIsAutoSpin,
			setSlotPrizesPreWon: state.setSlotPrizesPreWon,
			setSlotPrizesSurroundingWon: state.setSlotPrizesSurroundingWon,
			setWonPrize: state.setWonPrize,
			setShowLoadingScreen: state.setShowLoadingScreen,
		}),
		shallow
	);

	const { load: reloadUser } = useUserContext();

	const { play: playBoxSpinBackgroundSound, stop: stopBoxSpinBackgroundSound } = useSound({
		src: BoxOpenBackgroundSound,
		volume: 0.6,
		rate: 1,
		loop: true,
	});

	const { requestWinningPrize, isBoxOpenRequestLoading } = useWonBoxItem({
		box,
		onBoxOpenSuccess: () => {
			if (isBonusBoxToOpen) {
				setIsBonusBoxToOpen(false);
			}

			playBoxSpinBackgroundSound(MOBILE_FADE_IN_DURATION_BG_SPIN_SOUND);

			reloadUser().then(() => {
				setShowLoadingScreen(false);
			});
		},
	});

	useEffect(() => {
		if (!showWonScreen && !isBoxOpening) {
			stopBoxSpinBackgroundSound(FADE_OUT_DURATION_BG_SPIN_SOUND);
		}
	}, [isBoxOpening, showWonScreen, stopBoxSpinBackgroundSound]);

	const { isBonusBoxToOpen, setIsBonusBoxToOpen } = usePopupStore(
		(state) => ({
			isBonusBoxToOpen: state.isBonusBoxToOpen,
			setIsBonusBoxToOpen: state.setIsBonusBoxToOpen,
		}),
		shallow
	);
	const { generatePreAndPostSlotItems, generateDemoSpinPrize } = useBoxSlotItems();

	// optimze box open speed because we can skip check for boxes when no boxes in inventory
	const { data: inventoryBoxes } = useInventoryBoxes();

	const handleBoxOpen = useCallback(
		(isDemoSpin: boolean) => {
			if (!box || box.name.length === 0 || box.items.length === 0) {
				return;
			}

			const { preSlotItems, surroundingSlotItems } = generatePreAndPostSlotItems(box, isBonusBoxToOpen);
			setSlotPrizesPreWon(preSlotItems);
			setSlotPrizesSurroundingWon(surroundingSlotItems);

			if (isDemoSpin) {
				const demoSpinPrize = generateDemoSpinPrize(box);
				setWonPrize(demoSpinPrize);
				setIsBoxOpening(true);
			} else {
				setIsAutoSpin(autoSpinCount > 1);
				requestWinningPrize({
					boxName: box?.name,
					clientSeed,
					useFreeBox: !!inventoryBoxes && inventoryBoxes.length > 0,
				});
				setAutoSpinCount(Math.max(autoSpinCount - 1, 0));
				setIsBoxOpening(true);
				setShowLoadingScreen(true);
			}
			setIsDemoSpin(isDemoSpin);
		},
		[
			autoSpinCount,
			box,
			clientSeed,
			generateDemoSpinPrize,
			generatePreAndPostSlotItems,
			inventoryBoxes,
			isBonusBoxToOpen,
			requestWinningPrize,
			setAutoSpinCount,
			setIsAutoSpin,
			setIsBoxOpening,
			setIsDemoSpin,
			setShowLoadingScreen,
			setSlotPrizesPreWon,
			setSlotPrizesSurroundingWon,
			setWonPrize,
		]
	);

	useBoxClientSeed({ isMobile: true });
	useScrollLock();
	useKeepBackgroundScrollPos();
	useBoxScreenLeaveCleanUp();

	const { data: paymentHistory } = usePaymentTransactions();

	return (
		<div className="fixed z-40 inset-0 min-h-dvh text-[#ffd52d]">
			<div className="bg-[rgba(0,0,0,0.4)] absolute inset-0 h-dvh" />
			<PopupHandler onDemoBoxOpen={() => handleBoxOpen(true)} />
			<MobileBoxStack onBoxOpen={handleBoxOpen} isBoxOpenRequestLoading={isBoxOpenRequestLoading} />

			{isBoxOpening && box && <MobileBoxOpenSlot box={box} />}
			{showWonScreen && <MobileWonPrizeScreen hideSellBtn={!paymentHistory || paymentHistory.length === 0} />}
		</div>
	);
}
