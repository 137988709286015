import { DepositBonusBoxes, FontFreeWinboxesWithDeposit } from 'assets/images';
import { PrimaryBtn } from 'components/common/buttons/PrimaryBtn';
import { useBoxOpeningStoreDesktop } from 'pages/OpenBox/store/useBoxOpeningStoreDesktop';
import { DepositFont } from 'pages/TransactionManager/assets';
import { useCallback, useMemo } from 'react';
import { useLocation } from 'react-router';
import { usePopupStore } from 'store/usePopupStore';
import { shallow } from 'zustand/shallow';

import { WithPrimaryPopup } from '../../common/popups/WithPrimaryPopup';
import { useDelayedUnblock } from '../hooks/useDelayedUnblock';

export function DesktopDepositBonusPopup() {
	const { isDepositBonusPopupVisible, setIsDepositBonusPopupVisible, setIsDepositPopupVisible, isDepositPopupVisible } =
		usePopupStore(
			(state) => ({
				isDepositBonusPopupVisible: state.isDepositBonusPopupVisible,
				setIsDepositBonusPopupVisible: state.setIsDepositBonusPopupVisible,
				setIsDepositPopupVisible: state.setIsDepositPopupVisible,
				isDepositPopupVisible: state.isDepositPopupVisible,
			}),
			shallow
		);

	const isBoxOpening = useBoxOpeningStoreDesktop((state) => state.isBoxOpening, shallow);
	const location = useLocation();

	// block display with a delay after box open and deposit popup open
	const isPopupUnblocked = useDelayedUnblock({
		isBlocked: isBoxOpening || isDepositPopupVisible || location.pathname.includes('inventory'),
	});

	const isComponentVisible = useMemo(
		() => isDepositBonusPopupVisible && isPopupUnblocked,
		[isDepositBonusPopupVisible, isPopupUnblocked]
	);

	const handlePopupClose = useCallback(() => {
		setIsDepositBonusPopupVisible(false);
	}, [setIsDepositBonusPopupVisible]);

	return (
		<WithPrimaryPopup
			onClose={handlePopupClose}
			isExternallyVisible={isComponentVisible}
			useAnimation={true}
			childrenWrapperStyle={{ maxHeight: '85svh', maxWidth: '95vw' }}
		>
			<div className="flex items-center justify-center gap-x-[30px] min-w-[650px]  overflow-y-auto max-h-full">
				<div className="flex flex-col h-full items-center justify-center gap-y-[50px]">
					<img
						src={FontFreeWinboxesWithDeposit}
						alt="claim your free box"
						className="h-[125px] lg:h-[135px] w-auto object-contain"
					/>
					<PrimaryBtn
						style={{ height: 'fit-content', borderRadius: '24px', width: '220px' }}
						onClick={() => {
							handlePopupClose();
							setIsDepositPopupVisible(true);
						}}
						innerContentStyle={{ borderRadius: '24px' }}
						content={
							<div className="flex items-center justify-center w-full h-[48px] xxs:h-[58px]">
								<img src={DepositFont} alt="claim your bonus now!" className="h-[27px] object-contain" />
							</div>
						}
					/>
				</div>

				<img src={DepositBonusBoxes} alt="" className="h-[365px] lg:h-[410px]" />
			</div>
		</WithPrimaryPopup>
	);
}
