import { Howl } from 'howler';
import { useCallback, useEffect, useRef } from 'react';

type UseHowlOptions = {
	src: string;
	volume?: number;
	loop?: boolean;
	autoplay?: boolean;
	rate?: number; // Optional: default playback speed
	useCleanUp?: boolean;
	fadeOutDuration?: number; // Optional: fade-out duration in milliseconds
	fadeInDuration?: number; // Optional: fade-in duration in milliseconds
	cleanupMode?: CleanupMode; // New option to specify cleanup mode
};

type CleanupMode = 'instant' | 'afterFinish';

type UseHowlReturn = {
	play: (fadeInDuration?: number) => void; // Modified play to accept fadeInDuration
	pause: () => void;
	stop: (fadeOutDuration?: number) => void; // Modified stop to accept fadeOutDuration
	setVolume: (volume: number) => void;
	setSpeed: (rate: number) => void; // Add setSpeed to control playback speed
};

export const useSound = (options: UseHowlOptions): UseHowlReturn => {
	const soundRef = useRef<Howl | null>(null);
	const fadeOutTimeoutRef = useRef<NodeJS.Timeout | null>(null); // Ref to track the fadeout timeout

	useEffect(() => {
		const {
			src,
			volume = 1,
			loop = false,
			autoplay = false,
			rate = 1,
			useCleanUp = true,
			cleanupMode = 'instant',
		} = options;

		soundRef.current = new Howl({
			src,
			volume,
			loop,
			autoplay,
			rate,
		});

		return () => {
			if (useCleanUp) {
				if (cleanupMode === 'instant') {
					soundRef.current?.unload();
				} else if (cleanupMode === 'afterFinish') {
					const sound = soundRef.current;
					if (sound) {
						const onEndHandler = () => {
							sound.unload();
							sound.off('end', onEndHandler); // Clean up the event listener
						};
						sound.on('end', onEndHandler);
					}
				}
			}
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [options.src, options.volume, options.loop, options.autoplay, options.rate, options.useCleanUp]);

	const play = useCallback(
		(fadeInDuration: number = 0) => {
			if (!soundRef.current) {
				return;
			}

			if (soundRef.current.playing()) {
				soundRef.current.stop(); // Stop the sound if it's currently playing
			}

			// Clear any existing fadeout timeout when play is triggered
			if (fadeOutTimeoutRef.current) {
				clearTimeout(fadeOutTimeoutRef.current);
				fadeOutTimeoutRef.current = null; // Reset the timeout ref
				soundRef.current.volume(options.volume || 1); // Reset to original or default volume
			}

			if (fadeInDuration > 0) {
				// Set initial volume to 0 before fading in
				soundRef.current.volume(0);
				soundRef.current.play();
				// Fade in to the target volume over the given duration
				soundRef.current.fade(0, options.volume || 1, fadeInDuration);
			} else {
				soundRef.current.play(); // Play the sound without fading in
				soundRef.current.volume(options.volume || 1); // Set to the original or default volume
			}
		},
		[options.volume]
	);

	const pause = useCallback(() => {
		if (soundRef.current) {
			soundRef.current.pause();
		}
	}, []);

	const stop = useCallback(
		(fadeOutDuration: number = 0) => {
			if (soundRef.current) {
				if (fadeOutDuration > 0) {
					// Fade out to volume 0 over the given duration
					soundRef.current.fade(soundRef.current.volume(), 0, fadeOutDuration);

					// Set a timeout to stop the sound after fade is complete
					fadeOutTimeoutRef.current = setTimeout(() => {
						soundRef.current?.stop();
					}, fadeOutDuration);
				} else {
					// Immediately stop if no fade-out duration is provided
					soundRef.current.stop();

					soundRef.current.volume(options.volume || 1);
				}
			}
		},
		[options.volume]
	);

	const setVolume = useCallback((volume: number) => {
		if (soundRef.current) {
			soundRef.current.volume(volume);
		}
	}, []);

	const setSpeed = useCallback((rate: number) => {
		if (soundRef.current) {
			soundRef.current.rate(rate); // Set playback speed
		}
	}, []);

	return {
		play,
		pause,
		stop,
		setVolume,
		setSpeed,
	};
};
