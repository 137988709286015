import {
	AMEX,
	ApplePay,
	Bitcoin,
	Discover,
	Etherium,
	GooglePay,
	KlarnaBadge,
	Mastercard,
	Polygon,
	USDCoin,
	Visa,
} from 'assets/images';
import { useCurrencyDetection } from 'hooks/currency/useCurrencyPreferences';
import { PaymentMethod } from 'pages/TransactionManager/transaction.interface';
import { isKlarnaAvailable } from 'pages/TransactionManager/transaction.utils';

import { Header } from '../components/Header';
import { SecureBatch } from '../components/SecureBatch';

const buttonConfigs = [
	{
		content: (
			<div className="flex items-center gap-x-[18px] ms:gap-x-[22px]">
				<img src={ApplePay} alt="Apple Pay" className="h-[23px]" />
				<img src={GooglePay} alt="GooglePay" className="h-[23px]" />
			</div>
		),
		paymentMethod: PaymentMethod.APPLE_GOOGLE_PAY,
	},
	{
		content: (
			<div className="flex items-center gap-x-[10px] ms:gap-x-[14px]">
				<img src={Visa} alt="Visa" className="h-[16px]" />
				<img src={Mastercard} alt="Mastercard" className="h-[18px]" />
				<img src={Discover} alt="Discover" className="h-[18px]" />
				<img src={AMEX} alt="AMEX" className="h-[16px]" />
			</div>
		),
		paymentMethod: PaymentMethod.CREDIT_CARD,
	},
	{
		paymentMethod: PaymentMethod.KLARNA,
		content: <img src={KlarnaBadge} alt="Klarna" className="h-[29px]" />,
	},
	{
		paymentMethod: PaymentMethod.CRYPTO,
		content: (
			<div className="flex items-center gap-x-[18px] ms:gap-x-[23px]">
				<img src={Bitcoin} alt="Bitcoin" className="h-[24px]" />
				<img src={Etherium} alt="Etherium" className="h-[24px]" />
				<img src={USDCoin} alt="USD Coin" className="h-[24px]" />
				<img src={Polygon} alt="Polygon" className="h-[24px]" />
			</div>
		),
	},
	/*{
		paymentMethod: PaymentMethod.GIFT_CARD,
		content: (
			<div className="flex items-center gap-x-[8px] ms:gap-x-[12px] text-white text-[12px]">
				<img src={LogoPaypalPay} className="h-[20px]" alt="paypal" />
				<div className="flex items-center gap-x-[4px]">
					<span>(</span>
					<img src={BlueMFont} alt="Mrloot" className="h-[12px]" />
					<span className="font-bold text-white">Gift Card</span>
					<span>)</span>
				</div>
			</div>
		),
	},*/
];

interface Props {
	onMethodSelection: (method: PaymentMethod) => void;
}

export function PaymentMethodSelection({ onMethodSelection }: Props) {
	const { data: detectedCurrency } = useCurrencyDetection();

	// Filter Klarna if the user's country is not supported
	const filteredButtonConfigs = buttonConfigs.filter((method) => {
		if (method.paymentMethod === PaymentMethod.KLARNA) {
			return isKlarnaAvailable(detectedCurrency ?? 'usd');
		}
		return true;
	});

	return (
		<div className="flex flex-col items-center gap-y-[35px] h-full justify-between min-h-fit pb-[12px] px-[20px]">
			<Header title="Choose Payment Method" showBackArrow={false} />
			<div className="flex flex-col items-center gap-y-[27px] w-full">
				{filteredButtonConfigs.map((payMethod) => (
					<button
						onClick={() => onMethodSelection(payMethod.paymentMethod)}
						key={payMethod.paymentMethod}
						className="flex items-center justify-center border-[2px] border-darkBlue outline outline-[4px] outline-white rounded-[20px] h-[58px] ms:h-[72px] max-w-[380px] w-[250px]"
					>
						{payMethod.content}
					</button>
				))}
			</div>
			<SecureBatch />
		</div>
	);
}
