import { gsap } from 'gsap';
import { LiveBoxWinning } from 'pages/overview/interfaces/LiveDropsInterface';
import { useCallback, useEffect, useLayoutEffect, useRef } from 'react';

const TRANSLATE_DURATION_MS = 700;

interface Props {
	drops: LiveBoxWinning[];
	containerRef: React.RefObject<HTMLDivElement>;
	dropSyncDelay: number;
}

function getTranslateDistance(container: HTMLDivElement) {
	const itemWidth = container.children[0]?.clientWidth || 0;
	const columnGap = parseFloat(window.getComputedStyle(container).getPropertyValue('column-gap'));
	return itemWidth + columnGap;
}

function getTimeToTranslate(createdAt: string, dropSyncDelay: number) {
	const now = Date.now() - dropSyncDelay;
	const dropTime = new Date(createdAt).getTime();
	return Math.max((dropTime - now) / 1000, 0);
}

function groupFutureDrops(futureDrops: LiveBoxWinning[], translateDurationMs: number) {
	const grouped: LiveBoxWinning[][] = [];
	let tempGroup: LiveBoxWinning[] = [];

	// Reverse so we can push groups in chronological order at the end
	futureDrops.reverse().forEach((drop, idx) => {
		const isFirstOrBigGap =
			idx === 0 ||
			new Date(drop.createdAt).getTime() - new Date(futureDrops[idx - 1].createdAt).getTime() > translateDurationMs;

		if (isFirstOrBigGap) {
			if (tempGroup.length > 0) {
				grouped.push(tempGroup);
			}
			tempGroup = [drop];
		} else {
			tempGroup.push(drop);
		}
	});

	if (tempGroup.length > 0) {
		grouped.push(tempGroup);
	}
	return grouped;
}

function getImmediateTranslateData(
	allDrops: LiveBoxWinning[],
	futureDrops: LiveBoxWinning[],
	lastDropId: string | null,
	now: number,
	stepDistance: number
) {
	// By default, assume we need to start at the length of futureDrops
	// (i.e., everything before that is "old" and can be immediately translated)
	let startIndex = futureDrops.length;
	let count = 0;

	if (!lastDropId) {
		return { startIndex, count, distance: 0 };
	}

	// Find the last drop from previous state in the new list
	const indexInNewList = allDrops.findIndex((drop) => drop._id === lastDropId);

	// If the last drop isn’t in the new list, we stick to the default
	if (indexInNewList === -1) {
		return { startIndex, count, distance: 0 };
	}

	// Otherwise, use the index of that last drop
	startIndex = indexInNewList;

	// Count how many drops before startIndex happened in the past
	count = allDrops.reduce((acc, drop, i) => {
		const dropTime = new Date(drop.createdAt).getTime();
		return i < startIndex && dropTime < now ? acc + 1 : acc;
	}, 0);

	return { startIndex, count, distance: count * stepDistance };
}

export function useLiveDropAnimation({ drops, containerRef, dropSyncDelay }: Props) {
	const timelineRef = useRef<gsap.core.Timeline | null>(null);
	const lastDropId = useRef<string | null>(null);

	const processAndAnimateDrops = useCallback(
		(newDrops: LiveBoxWinning[], ignoreLastDrops = false) => {
			const container = containerRef.current;
			if (!container) {
				return;
			}

			// Prepare timeline
			if (!timelineRef.current) {
				timelineRef.current = gsap.timeline({ defaults: { ease: 'power2.out' } });
			} else {
				timelineRef.current.clear();
			}

			const now = Date.now() - dropSyncDelay;
			const stepDistance = getTranslateDistance(container);
			const futureDrops = newDrops.filter((d) => new Date(d.createdAt).getTime() >= now);
			const groupedFutureDrops = groupFutureDrops(futureDrops, TRANSLATE_DURATION_MS);

			// Calculate immediate offset info
			const { startIndex, count, distance } = getImmediateTranslateData(
				newDrops,
				futureDrops,
				ignoreLastDrops ? null : lastDropId.current,
				now,
				stepDistance
			);

			// Set initial offset
			const initialOffset = -(startIndex * stepDistance);
			timelineRef.current.set(container, { x: initialOffset });

			// Animate immediate moves
			if (distance > 0 && count > 0) {
				const perElementDuration = TRANSLATE_DURATION_MS / 1000 / count;
				let offset = initialOffset;
				for (let i = 0; i < count; i++) {
					const delay = i * perElementDuration;
					offset += stepDistance;
					timelineRef.current.to(container, { x: offset, duration: perElementDuration }, delay);
				}
			}

			// Animate grouped future drops
			let remainingSteps = futureDrops.length;
			groupedFutureDrops.forEach((group) => {
				const firstDropDelay = getTimeToTranslate(group[0].createdAt, dropSyncDelay);
				const groupDuration = TRANSLATE_DURATION_MS / 1000 / group.length;

				group.forEach((drop, i) => {
					remainingSteps = Math.max(remainingSteps - 1, 0);
					const distanceToMove = remainingSteps * -stepDistance;
					const dropDelay = firstDropDelay + i * groupDuration;
					timelineRef.current?.to(
						container,
						{
							x: distanceToMove,
							duration: groupDuration,
							onComplete: () => {
								lastDropId.current = drop._id;
							},
						},
						dropDelay
					);
				});
			});
		},
		[containerRef, dropSyncDelay]
	);

	// Resize listener: reprocess and reanimate on resize
	useLayoutEffect(() => {
		if (!drops.length) {
			return;
		}
		const handleResize = () => {
			timelineRef.current?.clear();
			processAndAnimateDrops(drops, true);
		};
		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, [drops, processAndAnimateDrops]);

	// Normal animation on drops change
	useLayoutEffect(() => {
		if (!drops.length) {
			return;
		}
		processAndAnimateDrops(drops);
	}, [drops, processAndAnimateDrops]);

	// Cleanup
	useEffect(() => {
		return () => {
			timelineRef.current?.kill();
		};
	}, []);
}
