import { MobileEmailInput } from 'components/common/inputs/MobileEmailInput';
import { MobilePasswordInput } from 'components/common/inputs/MobilePasswordInput';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useUserContext } from 'hooks';
import { PrimaryBtn } from 'components/common/buttons/PrimaryBtn';
import { FontLogin } from 'assets/images';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { ToastErrorNotification } from 'components/toasts/ToastErrorNotification';
import { useLogin } from 'pages/authentication/hooks/useLogin';

interface LoginFormCredentials {
	email: string;
	password: string;
}

export function MobileLoginForm() {
	const navigate = useNavigate();
	const { load: loadUser } = useUserContext();

	const { executeRecaptcha } = useGoogleReCaptcha();

	const { isLoading, executeLogin } = useLogin({
		onSuccess: () => {
			loadUser();
			navigate('/successful-login');
		},
		onError: (message) => {
			toast.error('Incorrect email or password. Please try again or reset your password.', {
				icon: false,
			});
		},
	});

	async function handleSignIn(data: LoginFormCredentials) {
		if (!executeRecaptcha) {
			toast(<ToastErrorNotification message="reCAPTCHA is temporarily unavailable. Please try again later" />);
			return;
		}

		try {
			const recaptchaToken = await executeRecaptcha('login');
			executeLogin({ ...data, recaptchaToken });
		} catch (error) {
			console.error('reCAPTCHA error:', error);
			toast(<ToastErrorNotification message="reCAPTCHA is temporarily unavailable. Please try again later" />);
		}
	}

	const {
		register,
		handleSubmit,
		formState: { errors },
		resetField,
		watch,
	} = useForm<LoginFormCredentials>();

	return (
		<>
			<form onSubmit={handleSubmit(handleSignIn)} className="w-full space-y-[40px]">
				<div className="space-y-[16px] flex flex-col">
					<div className="flex flex-col items-start mb-[10px]">
						<h1 className="text-white font-bold text-[26px] font-poppins ">Login to your account</h1>
						<span className="text-white text-[14px] font-semibold">
							<span className="font-poppins text-white/80">Don't have an account?</span>
							<Link to="/auth/register" className="text-[17px] font-bold ml-[5px] font-poppins">
								Register
							</Link>
						</span>
					</div>

					<div className="flex flex-col gap-y-[5px]">
						<span className="text-white/80 text-[14px] font-semibold font-poppins">Email</span>
						<MobileEmailInput register={register} resetField={resetField} watch={watch} errors={errors} />
					</div>
					<div className="flex flex-col gap-y-[5px]">
						<span className="text-white/80 font-semibold text-[14px] font-poppins">Password</span>
						<MobilePasswordInput register={register} errors={errors} placeholder="Password" />
					</div>

					<Link
						to="/auth/reset-password/enter-email"
						className="ml-auto text-white/80 focus-within:text-white text-[14px] tracking-[0.09em] font-poppins font-semibold"
					>
						Forgot Password?
					</Link>
				</div>
				<PrimaryBtn
					content={<img src={FontLogin} alt="register" className="h-[24px] object-contain" />}
					type="submit"
					isDisabled={isLoading}
					style={{ borderRadius: '40px' }}
					innerContentStyle={{ borderRadius: '40px' }}
				/>
			</form>
		</>
	);
}
