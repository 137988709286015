import './index.css';

import React from 'react';
import { createRoot } from 'react-dom/client';
import { ErrorBoundary } from 'react-error-boundary';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';

import { App } from './App';
import HistoryContext from './context/HistoryContext';
import { UserContext } from './context/UserContext';
import { ErrorFallback } from './ErrorFallback';

const queryClient = new QueryClient();

const root = createRoot(document.getElementById('root'));

const logError = (error, info) => {
	console.error('ErrorBoundary caught an error:', error);
	console.error('Error info:', info);
	//TODO: send to external error logging service
};

root.render(
	<QueryClientProvider client={queryClient}>
		<BrowserRouter>
			<ErrorBoundary FallbackComponent={ErrorFallback} onError={logError}>
				<GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}>
					<UserContext>
						<HistoryContext>
							<App />
						</HistoryContext>
					</UserContext>
				</GoogleReCaptchaProvider>
			</ErrorBoundary>
		</BrowserRouter>
	</QueryClientProvider>
);
