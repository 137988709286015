/* eslint-disable max-lines */
/* eslint-disable complexity */
/* eslint-disable max-lines-per-function */
import { faChevronLeft, faChevronRight, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PresentationModeBg, PresentationModeMrLootLeft, PresentationModeMrLootRight } from 'assets/images';
import { CheckBox, ItemCard3D, LoadingCard3D } from 'components';
import gsap from 'gsap';
import { useEnterOrSpacePress } from 'hooks/utility/event-listener/useEnterOrSpacePress';
import { useWindowDimensions } from 'hooks/utility/layout-measurements/useWindowDimensions';
import { ItemRarity } from 'interfaces/ItemInterfaces';
import debounce from 'lodash/debounce';
import { useInventoryItems } from 'pages/inventory/hooks/useInventoryItems';
import { InventoryOrderOptions } from 'pages/inventory/inventoryUI.interface';
import { useInventoryStore } from 'pages/inventory/store/InventoryStore';
import { useCallback, useEffect, useRef, useState } from 'react';
import { shallow } from 'zustand/shallow';

import { screens } from '../../../../constants';

function getCheckboxSize(screenWidth: number) {
	return screenWidth > screens.xxl.width
		? { width: '40px', height: '40px', checkSize: '30px', borderWidth: '5px' }
		: screenWidth > screens.xl.width
			? {
					width: '30px',
					height: '30px',
					checkSize: '23px',
					borderWidth: '3px',
				}
			: screenWidth > screens.lg.width
				? {
						width: '25px',
						height: '25px',
						checkSize: '20px',
						borderWidth: '2px',
					}
				: {
						width: '23px',
						height: '23px',
						checkSize: '17px',
						borderWidth: '2px',
					};
}

interface Props {
	selectedItems: Set<string>;
	onItemSelect: (id: string) => void;
	excludedRarities: ItemRarity[];
	orderOption: InventoryOrderOptions;
}

const debouncedZIndexReset = debounce((leftMrLoot, rightMrLoot) => {
	gsap.set(leftMrLoot, {
		zIndex: 0,
	});
	gsap.set(rightMrLoot, {
		zIndex: 0,
	});
	// eslint-disable-next-line no-magic-numbers
}, 800);

const MIN_CARD_PLACEHOLDER = 10;

export function ItemsPresentationMode({ selectedItems, onItemSelect, excludedRarities, orderOption }: Props) {
	const parentRef = useRef<HTMLDivElement>(null);
	const wrapperRef = useRef<HTMLDivElement>(null);
	const leftMrLootRef = useRef<HTMLImageElement>(null);
	const rightMrLootRef = useRef<HTMLImageElement>(null);

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const { activeIndexPresentationMode, setActiveIndexPresentationMode, setIsPresentationMode } = useInventoryStore(
		(state) => ({
			activeIndexPresentationMode: state.activeIndexPresentationMode,
			setActiveIndexPresentationMode: state.setActiveIndexPresentationMode,
			setIsPresentationMode: state.setIsPresentationMode,
		})
	);

	const numLoadedInventoryItems = useInventoryStore((state) => state.numLoadedInventoryItems, shallow);

	const {
		data: inventoryItems,
		isLoading: isInventoryLoading,
		loadMore,
	} = useInventoryItems({
		excludedRarities: excludedRarities,
		limit: numLoadedInventoryItems,
		order: orderOption,
	});

	const currentItemIndex = useRef(activeIndexPresentationMode);
	const [isLeftArrowDisabled, setIsLeftArrowDisabled] = useState(activeIndexPresentationMode === 0);
	const [isRightArrowDisabled, setIsRightArrowDisabled] = useState(
		activeIndexPresentationMode >= numLoadedInventoryItems
	);
	const [isCheckboxChecked, setIsCheckboxChecked] = useState(
		inventoryItems &&
			inventoryItems.length >= activeIndexPresentationMode &&
			selectedItems.has(inventoryItems[activeIndexPresentationMode]._id)
	);

	const { screenWidth } = useWindowDimensions();

	useEffect(() => {
		window.scroll({ top: 0 });
		const wrapperEl = wrapperRef.current;
		const parentEl = parentRef.current;

		// Function to calculate itemCardWidth
		const updateItemCardWidth = () => {
			if (parentEl && wrapperEl) {
				const parentWidth = parentEl.clientWidth;

				const translateX = parentWidth * activeIndexPresentationMode;

				gsap.set(wrapperEl, {
					translateX: `${-translateX}px`,
				});
			}
		};

		// Set up ResizeObserver on the parent element
		const observer = new ResizeObserver(updateItemCardWidth);
		if (parentEl) {
			observer.observe(parentEl);
		}

		// Call update function initially in case dimensions are already set
		updateItemCardWidth();

		// Clean up observer on component unmount
		return () => observer.disconnect();
	}, [activeIndexPresentationMode]);

	const translateToPrevItem = useCallback(() => {
		currentItemIndex.current = Math.max(currentItemIndex.current - 1, 0);

		if (currentItemIndex.current === 0) {
			setIsLeftArrowDisabled(true);
		}
		setIsRightArrowDisabled(false);

		if (inventoryItems && inventoryItems.length > currentItemIndex.current) {
			setIsCheckboxChecked(selectedItems.has(inventoryItems[currentItemIndex.current]._id));
		}

		const wrapperEl = wrapperRef.current;
		const parentEL = parentRef.current;

		if (wrapperEl && parentEL) {
			const containerWidth = parentEL.clientWidth;
			const translateX = containerWidth * currentItemIndex.current;
			const tl = gsap.timeline();

			tl.set(leftMrLootRef.current, {
				zIndex: 30,
			});
			tl.set(rightMrLootRef.current, {
				zIndex: 30,
			});
			tl.to(wrapperEl, {
				translateX: -translateX,
				duration: 0.5,
				ease: 'power1.in',
			});
		}
		debouncedZIndexReset(leftMrLootRef.current, rightMrLootRef.current);
	}, [inventoryItems, selectedItems]);

	const translateToNextItem = useCallback(() => {
		const inventoryLength = inventoryItems?.length ?? 0;
		currentItemIndex.current = Math.min(currentItemIndex.current + 1, inventoryLength);

		if (currentItemIndex.current >= inventoryLength) {
			setIsRightArrowDisabled(true);
		}
		setIsLeftArrowDisabled(false);

		if (inventoryItems && inventoryItems.length > currentItemIndex.current) {
			setIsCheckboxChecked(selectedItems.has(inventoryItems[currentItemIndex.current]._id));
		}

		if (inventoryItems && currentItemIndex.current === inventoryItems.length - 2) {
			loadMore();
		}

		const wrapperEl = wrapperRef.current;
		const parentEL = parentRef.current;

		if (wrapperEl && parentEL) {
			const containerWidth = parentEL.clientWidth;
			const translateX = containerWidth * currentItemIndex.current;
			const tl = gsap.timeline();

			tl.set(leftMrLootRef.current, {
				zIndex: 30,
			});
			tl.set(rightMrLootRef.current, {
				zIndex: 30,
			});
			tl.to(wrapperEl, {
				translateX: -translateX,
				duration: 0.5,
				ease: 'power1.in',
			});
		}
		debouncedZIndexReset(leftMrLootRef.current, rightMrLootRef.current);
	}, [inventoryItems, loadMore, selectedItems]);

	function handleSelect() {
		setIsCheckboxChecked(true);
		if (inventoryItems && currentItemIndex.current < inventoryItems.length) {
			onItemSelect(inventoryItems[currentItemIndex.current]._id);
		}
	}

	const handleKeyPress = useEnterOrSpacePress(() => handleSelect());

	const checkBoxSizes = getCheckboxSize(screenWidth);

	return (
		<div
			ref={parentRef}
			className="size-container overflow-hidden flex relative w-full  h-[calc(100vh-230px)] md:h-[calc(100vh-165px)] bg-cover md:rounded-[20px] bg-center md:border-[2px] md:border-white"
			style={{ backgroundImage: `url(${PresentationModeBg})` }}
		>
			<button
				className="z-20 absolute right-5 top-5 w-[40px] h-[40px] bg-[rgba(255,255,255,0.3)] hover:bg-[rgba(255,255,255,0.5)] text-white rounded-[20px]"
				onClick={() => setIsPresentationMode(false)}
			>
				<FontAwesomeIcon icon={faXmark} className="h-[15px]" />
			</button>
			<img
				src={PresentationModeMrLootLeft}
				ref={leftMrLootRef}
				alt="Left MrLoot"
				className="absolute left-0 top-0 h-full w-full z-0 object-cover"
			/>
			<img
				src={PresentationModeMrLootRight}
				ref={rightMrLootRef}
				alt="Right MrLoot"
				className="absolute left-0 top-0 h-full w-full z-0 object-cover"
			/>

			<button
				onClick={translateToPrevItem}
				disabled={isLeftArrowDisabled}
				className={`${isLeftArrowDisabled && 'opacity-50'} absolute z-40 left-4 top-[50%] translate-y-[-50%] text-white text-[26px]`}
			>
				<FontAwesomeIcon icon={faChevronLeft} />
			</button>
			<button
				onClick={translateToNextItem}
				disabled={isRightArrowDisabled}
				className={`${isRightArrowDisabled && 'opacity-50'} absolute z-40 right-4 top-[50%] translate-y-[-50%] text-white text-[26px]`}
			>
				<FontAwesomeIcon icon={faChevronRight} />
			</button>

			<div className=" absolute inset-0 h-full flex flex-col items-start justify-around w-full">
				<div ref={wrapperRef} className="z-10 flex justify-start items-center">
					{inventoryItems &&
						inventoryItems.length > 0 &&
						inventoryItems.map((el) => (
							<div key={el._id} className="w-[100cqw] flex items-center justify-center">
								<ItemCard3D
									item={el.itemId}
									isSelected={selectedItems.has(el._id)}
									style={{
										width: 'clamp(20cqw, 30cqw, calc(0.65 * 60cqh))', // Dynamically calculated width
										aspectRatio: '0.65 / 1',
									}}
								/>
							</div>
						))}
					{isInventoryLoading &&
						Array.from({ length: Math.max(activeIndexPresentationMode, MIN_CARD_PLACEHOLDER) }).map((_, index) => (
							<div key={index} className="w-[100cqw] flex items-center justify-center">
								<LoadingCard3D
									style={{
										width: 'clamp(20cqw, 30cqw, calc(0.65 * 60cqh))', // Dynamically calculated width
										aspectRatio: '0.65 / 1',
									}}
								/>
							</div>
						))}
				</div>
				<div
					onKeyDown={(event) => handleKeyPress(event)}
					role="button"
					tabIndex={0}
					onClick={handleSelect}
					className="z-10 flex items-center space-x-3 xl:space-x-[20px] xxl:space-x-[25px] w-full justify-center"
				>
					<CheckBox
						checked={isCheckboxChecked}
						borderColor="white"
						strokeColor="white"
						borderRadius="6px"
						size={checkBoxSizes}
						borderWidth={checkBoxSizes.borderWidth}
						checkSize={checkBoxSizes.checkSize}
					/>
					<span className="text-white font-bold text-[18px] xl:text-[24px] xxl:text-[30px]">Select</span>
				</div>
			</div>
		</div>
	);
}
