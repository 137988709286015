import { faCheck, faRotate } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PrimaryBtn } from 'components/common/buttons/PrimaryBtn';
import { generateRandomSeed } from 'utils';

interface Props {
	isBtnDisabled: boolean;
	isInputDisabled: boolean;
	value: string;
	onChange: (v: string) => void;
	onUpdate: () => void;
}

export function ClientSeedInput({ isBtnDisabled, isInputDisabled, value, onChange, onUpdate }: Props) {
	function handleUpdate() {
		if (isBtnDisabled) {
			const newSeed = generateRandomSeed();
			onChange(newSeed);
		} else {
			onUpdate();
		}
	}

	return (
		<div className="flex flex-col space-y-1">
			<span className="text-white font-semibold text-white/80 text-[14px] xl:text-[16px] xxl:text-[19px]">
				Active Client Seed:
			</span>
			<div
				className={`${isInputDisabled && 'opacity-50 pointer-events-none'} h-[45px] xl:h-[50px] xxl:h-[58px] border-white border-[2px] rounded-[20px]`}
			>
				<div
					className="h-full w-full relative flex items-center border-[2px] border-darkBlue rounded-[18px]"
					style={{
						background: ' linear-gradient(331.41deg, rgba(35, 109, 244, 0.7) 6.78%, rgba(51, 240, 167, 0.7) 204.87%)',
					}}
				>
					<input
						placeholder="Please login to generate a value"
						value={value}
						onChange={(e) => onChange(e.target.value)}
						type="text"
						className={`text-white font-semibold bg-transparent text-[14px] xxl:text-[19px] pl-3 pr-8 h-full w-full rounded-[20px] focus-visible:outline-none`}
					/>
					<div className="absolute right-[-4px] top-[50%] translate-y-[-50%] h-[calc(100%+6px)]">
						<PrimaryBtn
							onClick={handleUpdate}
							renderEllipse={false}
							style={{ height: '100%', width: 'auto', borderWidth: '2px' }}
							content={<FontAwesomeIcon icon={isBtnDisabled ? faRotate : faCheck} />}
						/>
					</div>
				</div>
			</div>
		</div>
	);
}
