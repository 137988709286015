import axios from 'axios';
import { useQuery } from 'react-query';

import { API_DOMAIN, QUERY_CACHE_CONFIG, QUERY_KEYS } from '../../../constants';
import { BonusBundle } from '../deposit.interface';

async function requestDepositBonus(amount: number) {
	try {
		const response = await axios.get(`${API_DOMAIN.mrlootBackend}/api/payment/get-deposit-bonus`, {
			params: { amount }, // Pass 'amount' as a query parameter
			withCredentials: true, // Include credentials
		});

		if (response.data.status === 'success') {
			return response.data.data;
		}
	} catch (err) {
		if (axios.isAxiosError(err)) {
			const message = err.response?.data?.message || err.message || 'Something unexpected went wrong';
			throw new Error(message);
		}
		throw err;
	}
}

interface Props {
	amount: number;
}

export function useDepositBonus({ amount }: Props) {
	return useQuery<BonusBundle>({
		queryFn: () => requestDepositBonus(amount),
		queryKey: [QUERY_KEYS.depositBonus, amount],
		staleTime: QUERY_CACHE_CONFIG.shortTerm.staleTime,
		cacheTime: QUERY_CACHE_CONFIG.shortTerm.cacheTime,
		enabled: amount > 0,
	});
}
