import { PopulatedBoxInterface, PopulatedBoxItemInterface } from 'interfaces/BoxInterface';
import { ItemInterface } from 'interfaces/ItemInterfaces';
import { v4 as uuidv4 } from 'uuid';

import { DICE_SIDES, MAX_NEAR_MISSES_CANDIDATES, POS_WON_ITEM } from './box-opening.constants';
import { RefObject } from 'react';
import { PrizeInterface, PrizeType, WonItem } from './boxOpening.interface';

function adjustProbabilities(items: PopulatedBoxItemInterface[], boxPrice: number) {
	// Constants for easy adjustment
	const MAX_REDUCED_PROB_PER_STEP = 0.2; // 10%
	const MAX_TOTAL_REDUCED_PROB = 0.25; // 25%
	const MIN_PROBABILITY_THRESHOLD = 0.001; // 0.1% (for low-probability items)
	const MIN_COLLECTIVE_PROBABILITY = 0.015; // 1.5% (for the collective of low-probability items)
	const TOTAL_PROBABILITY = 1.0; // 100%
	const PRECISION = 1e-15; // Precision level to consider
	const MAX_ADDED_PROBABILITY = 0.04; // 2% cap on how much probability can be added per item

	// Step 1: Identify items with price higher than box price and lower than box price
	const higherPricedItems = items.filter((item) => item.itemId.price > boxPrice);
	const lowerPricedItems = items.filter((item) => item.itemId.price <= boxPrice);

	// Step 2: Sort items by probability
	higherPricedItems.sort((a, b) => b.probability - a.probability);
	lowerPricedItems.sort((a, b) => b.probability - a.probability);

	let savedProbability = 0;

	// Step 3: Adjust probabilities for lower-priced items
	let adjustedLowerPriceItems = lowerPricedItems.map((item, index) => {
		const maxReduction = item.probability - MAX_REDUCED_PROB_PER_STEP;

		let standardReduction;
		if (index < lowerPricedItems.length - 1) {
			standardReduction = lowerPricedItems[index + 1].probability;
		} else if (higherPricedItems.length > 0) {
			standardReduction = higherPricedItems[0].probability;
		} else {
			// Fallback value when higherPricedItems is empty
			standardReduction = Math.min(MAX_REDUCED_PROB_PER_STEP, item.probability);
		}

		const newProb = Math.max(maxReduction, standardReduction);

		const reduction = item.probability - newProb;

		// Limit the savedProbability to MAX_TOTAL_REDUCED_PROB
		if (savedProbability + reduction > MAX_TOTAL_REDUCED_PROB) {
			const allowedReduction = MAX_TOTAL_REDUCED_PROB - savedProbability;
			savedProbability += allowedReduction;
			return { ...item, probability: item.probability - allowedReduction };
		} else {
			savedProbability += reduction;
			return { ...item, probability: newProb };
		}
	});

	const totalInitialProbability = higherPricedItems.reduce((total, item) => total + item.probability, 0);
	let adjustedHigherPriceItems = higherPricedItems.map((item) => {
		const proportion = item.probability / totalInitialProbability;
		let addedProbability = proportion * savedProbability;

		// Apply the cap to the added probability
		if (addedProbability > MAX_ADDED_PROBABILITY) {
			addedProbability = MAX_ADDED_PROBABILITY;
		}

		return { ...item, probability: item.probability + addedProbability };
	});

	// Step 5: Ensure higher-priced items with prob < 0.1% have at least 1% collectively
	const lowProbabilityItems = adjustedHigherPriceItems.filter((item) => item.probability < MIN_PROBABILITY_THRESHOLD);
	const lowProbabilityTotal = lowProbabilityItems.reduce((total, item) => total + item.probability, 0);

	if (lowProbabilityItems.length > 0 && lowProbabilityTotal < MIN_COLLECTIVE_PROBABILITY) {
		const deficit = MIN_COLLECTIVE_PROBABILITY - lowProbabilityTotal;
		const adjustmentPerItem = deficit / lowProbabilityItems.length;

		adjustedHigherPriceItems = adjustedHigherPriceItems.map((item) => {
			if (item.probability < MIN_PROBABILITY_THRESHOLD) {
				return { ...item, probability: item.probability + adjustmentPerItem };
			} else {
				// Reduce others proportionally to compensate
				const reduction = adjustmentPerItem * lowProbabilityItems.length * (item.probability / totalInitialProbability);
				return { ...item, probability: item.probability - reduction };
			}
		});
	}

	// Step 6: Ensure total probability equals 100%
	const totalProbabilities = [...adjustedHigherPriceItems, ...adjustedLowerPriceItems].reduce(
		(a, b) => a + b.probability,
		0
	);

	if (totalProbabilities !== TOTAL_PROBABILITY) {
		const adjustmentFactor = TOTAL_PROBABILITY / totalProbabilities;

		adjustedHigherPriceItems = adjustedHigherPriceItems.map((item) => ({
			...item,
			probability: item.probability * adjustmentFactor,
		}));

		adjustedLowerPriceItems = adjustedLowerPriceItems.map((item) => ({
			...item,
			probability: item.probability * adjustmentFactor,
		}));
	}

	// Correcting any residual tiny discrepancies directly
	let finalTotalProbability = [...adjustedHigherPriceItems, ...adjustedLowerPriceItems].reduce(
		(a, b) => a + b.probability,
		0
	);
	const discrepancy = TOTAL_PROBABILITY - finalTotalProbability;

	if (Math.abs(discrepancy) > PRECISION) {
		adjustedLowerPriceItems[0].probability += discrepancy;
	}

	return [...adjustedHigherPriceItems, ...adjustedLowerPriceItems];
}

function applyBonusProbabilities(
	items: PopulatedBoxItemInterface[],
	minProb = 0.02,
	skipNItems = 0
): PopulatedBoxItemInterface[] {
	const totalProbability = 1; // 100% in decimal

	// Step 1: Skip the first `skipNItems` items
	const skippedItems = items.slice(0, skipNItems);
	const itemsToAdjust = items.slice(skipNItems);

	// Step 2: Adjust items with less than `minProb` probability
	let adjustedItems = itemsToAdjust.map((item) => {
		return {
			...item,
			probability: item.probability < minProb ? minProb : item.probability,
		};
	});

	// Step 3: Calculate the total adjusted probability
	const adjustedTotal = adjustedItems.reduce((sum, item) => sum + item.probability, 0);
	const skippedTotal = skippedItems.reduce((sum, item) => sum + item.probability, 0);
	const excessProbability = adjustedTotal + skippedTotal - totalProbability;

	// Step 4: Distribute the excess proportionally to items with probability >= `minProb`
	if (excessProbability > 0) {
		const adjustableItems = adjustedItems.filter((item) => item.probability > minProb);
		const totalAdjustableProbability = adjustableItems.reduce((sum, item) => sum + item.probability, 0);

		adjustedItems = adjustedItems.map((item) => {
			if (item.probability > minProb) {
				const reduction = (item.probability / totalAdjustableProbability) * excessProbability;
				return { ...item, probability: item.probability - reduction };
			}
			return item;
		});
	}

	// Step 5: Normalize any floating-point errors
	const normalizedTotal =
		skippedItems.reduce((sum, item) => sum + item.probability, 0) +
		adjustedItems.reduce((sum, item) => sum + item.probability, 0);

	const adjustmentFactor = totalProbability / normalizedTotal;

	const normalizedSkippedItems = skippedItems.map((item) => ({
		...item,
		probability: item.probability * adjustmentFactor,
	}));

	const normalizedAdjustedItems = adjustedItems.map((item) => ({
		...item,
		probability: item.probability * adjustmentFactor,
	}));

	// Combine skipped items and adjusted items back into a single list
	return [...normalizedSkippedItems, ...normalizedAdjustedItems];
}

export function generateSlotItems(
	box: PopulatedBoxInterface,
	numberOfItems = 50,
	isBonusSpin?: boolean,
	minProb = 0.02,
	skipNItems = 0
) {
	const adjustedItems = isBonusSpin
		? applyBonusProbabilities(box.items, minProb, skipNItems)
		: adjustProbabilities(box.items, box.price);

	const selectedPrizes: PrizeInterface[] = [];
	let cumulative = 0;

	const items = adjustedItems.map((el) => ({
		...el,
		type: PrizeType.ITEM,
		data: el.itemId,
	}));
	const slotPrizes = [...items];

	const cumulativeItems = slotPrizes.map((item, index) => {
		index === slotPrizes.length - 1 ? (cumulative = 1) : (cumulative += item.probability);
		return {
			...item,
			upperBound: cumulative * DICE_SIDES,
		};
	});

	for (let i = 0; i < numberOfItems; i++) {
		const diceRoll = Math.random() * DICE_SIDES;

		// Find the selected item based on the dice roll
		const selectedItem = cumulativeItems.find((item) => diceRoll <= item.upperBound);
		if (selectedItem) {
			const key = uuidv4();
			selectedPrizes.push({
				data: selectedItem.data,
				type: selectedItem.type,
				key: key,
			});
		}
	}

	const enhancedSelectedPrizes = enhanceItems(items, selectedPrizes);

	return enhancedSelectedPrizes;
}

function enhanceItems(
	items: {
		type: PrizeType;
		data: WonItem;
		itemId: WonItem;
		probability: number;
	}[],
	selectedPrizes: PrizeInterface[]
) {
	const sortedItems = [...items].sort((a, b) => b.itemId.price - a.itemId.price);
	let cumulativeProbability = 0;
	const qualifiedCandidates = [];

	// Identify all qualified candidates
	const maxProbForNearMissCandidates = 0.1;
	for (const item of sortedItems) {
		if (cumulativeProbability < maxProbForNearMissCandidates && item.probability < maxProbForNearMissCandidates) {
			qualifiedCandidates.push(item);
			cumulativeProbability += item.probability;
		} else {
			break; // Stop once we've accumulated 10% probability
		}
	}

	const maxEnhancedItems = Math.min(MAX_NEAR_MISSES_CANDIDATES, qualifiedCandidates.length);
	const maxProb = maxEnhancedItems === 1 ? 0.1 : maxEnhancedItems === 2 ? 0.05 : 0.025;

	// Randomly select a subset of qualified candidates for enhancement
	const shuffledCandidates = qualifiedCandidates.sort(() => 0.5 - Math.random());
	const itemsToEnhance = shuffledCandidates.slice(0, maxEnhancedItems);

	// Apply the enhancement factor to the randomly selected items
	itemsToEnhance.forEach((item) => {
		const factor = item.probability < 0.0001 ? 500 : item.probability < 0.01 ? 5 : 2;
		item.probability = Math.min(item.probability * factor, maxProb);
	});

	const enhancedSelectedPrizes = [...selectedPrizes];

	itemsToEnhance.forEach((item) => {
		const dice = Math.random();
		if (item.probability > dice) {
			const bias = Math.random() - 0.5; // Creates a bias range [-0.5, 0.5]
			const distance = Math.round(bias * 20);
			let randomIndex = POS_WON_ITEM + distance;
			randomIndex = Math.max(POS_WON_ITEM - 3, Math.min(randomIndex, selectedPrizes.length - 10));

			enhancedSelectedPrizes[randomIndex] = {
				data: item.data,
				type: item.type,
				key: uuidv4(),
			};
		}
	});

	return enhancedSelectedPrizes;
}

export const getScaleOfNodeEl = (element: RefObject<HTMLDivElement>) => {
	if (element.current) {
		const transform = window.getComputedStyle(element.current).transform;
		if (transform !== 'none') {
			const matrix = new WebKitCSSMatrix(transform);
			return matrix.a; // 'a' is the horizontal scale (scaleX)
		}
	}
	return 1;
};

export const getCurrentTranslateX = (element: HTMLElement | null): number => {
	if (!element) return 0;

	const computedStyle = window.getComputedStyle(element);
	const transform = computedStyle.transform;

	if (transform === 'none' || !transform) {
		return 0; // Default if no transform is applied
	}

	const matrix = transform.match(/matrix.*\((.+)\)/);

	if (matrix && matrix[1]) {
		const values = matrix[1].split(', ').map(parseFloat);
		return values[4] || 0; // The 5th value in the matrix represents translateX
	}

	return 0;
};

/**Calculate the offset required to center an item in the slot item picker. */
interface CalculateOffsetProps {
	scaledItemContainer?: RefObject<HTMLDivElement>;
	itemsWrapperRef: RefObject<HTMLDivElement>;
	slotPickerRef: RefObject<HTMLImageElement>;
	targetPos: number;
}

export const calculateOffset = ({
	itemsWrapperRef,
	scaledItemContainer,
	slotPickerRef,
	targetPos,
}: CalculateOffsetProps) => {
	if (
		!slotPickerRef.current ||
		!itemsWrapperRef.current ||
		!itemsWrapperRef.current.children ||
		itemsWrapperRef.current.children.length < 1
	) {
		// using len 1 because we need to calc gap
		return null;
	}

	const slotPickerEl = slotPickerRef.current;
	const firstItemCardEl = itemsWrapperRef.current.children[0] as HTMLElement;
	const secondItemCardEl = itemsWrapperRef.current.children[1] as HTMLElement;

	const threshold = 0.0001; // prevents at round number (1,2,3,..) to jump to next card

	const cardWidth = firstItemCardEl.getBoundingClientRect().width;
	const scale = scaledItemContainer ? getScaleOfNodeEl(scaledItemContainer) : 1;
	const normalizedCardWidth = Math.max(scale > 0 ? cardWidth / scale : cardWidth, cardWidth);

	const slotPickerWidth = slotPickerEl.getBoundingClientRect().width;
	const normalizedSlotPickerWidth = Math.max(scale > 0 ? slotPickerWidth / scale : slotPickerWidth, slotPickerWidth);

	const gapBetweenItems = secondItemCardEl.offsetLeft - (firstItemCardEl.offsetLeft + firstItemCardEl.offsetWidth);

	const slotPickerPadding = (normalizedSlotPickerWidth - normalizedCardWidth) / 2; // left and right there is space so card can be in exact center
	const offsetGapAndPickerPadding = gapBetweenItems - slotPickerPadding;

	const pickerPos =
		(slotPickerEl.offsetLeft - gapBetweenItems - offsetGapAndPickerPadding) / normalizedCardWidth + threshold;

	const adjustedPosition = slotPickerEl.offsetLeft - gapBetweenItems - offsetGapAndPickerPadding;
	const normalizedPosition = adjustedPosition / normalizedCardWidth + threshold;

	const percentOfCardToFit = 1 - (normalizedPosition % 1);
	const extraTranslateToFitInPicker =
		percentOfCardToFit * normalizedCardWidth + (Math.floor(pickerPos) - 1) * gapBetweenItems;
	const relativePos = targetPos - Math.ceil(pickerPos);
	const offsetCards = relativePos * normalizedCardWidth;

	const offsetSpace = relativePos * gapBetweenItems;
	const offSetSlotPicker = offsetCards + offsetSpace + extraTranslateToFitInPicker;

	return -offSetSlotPicker;
};
