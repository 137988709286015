import { useMemo } from 'react';
import { CurrencyOptions } from 'store/useAppStore';
import { convertCurrency } from 'utils';

import { useConversionRates } from './useConversionRates';

export const useCryptoConversion = (amountInDollars: number, targetCurrency: CurrencyOptions) => {
	const { data: conversionRates, isLoading, isError } = useConversionRates();

	const convertedAmount = useMemo(
		() => convertCurrency(amountInDollars, targetCurrency, conversionRates),
		[amountInDollars, conversionRates, targetCurrency]
	);

	return {
		convertedAmount,
		isLoading,
		isError,
	};
};
