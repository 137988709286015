import { PrimaryBtn } from 'components/common/buttons/PrimaryBtn';
import { MobilePasswordInput } from 'components/common/inputs/MobilePasswordInput';
import { COLORS } from '../../../../../constants';
import { useResetPassword } from 'pages/authentication/hooks/useResetPassword';
import { useAuthenticationStore } from 'pages/authentication/store/useAuthenticationStore';
import { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import OtpInput from 'react-otp-input';
import { useNavigate } from 'react-router';
import { ToastErrorNotification } from 'components/toasts/ToastErrorNotification';
import { toast } from 'react-toastify';
import { ToastSuccessNotification } from 'components/toasts/ToastSuccessNotification';

interface PasswordResetInterface {
	password: string;
	passwordRepeat: string;
}

export function MobileResetPasswordFinalStep() {
	const { emailForPasswordReset, verificationCodeForPasswordReset } = useAuthenticationStore();

	const navigate = useNavigate();

	const {
		register,
		handleSubmit,
		formState: { errors },
		setError,
	} = useForm<PasswordResetInterface>();

	const { isLoading: isPasswordResetLoading, mutate: requestPasswordReset } = useResetPassword({
		onSuccess: () => {
			toast(<ToastSuccessNotification message="Your password has been updated successfully" />, {
				style: { background: COLORS.blue90 },
			});
			navigate('/auth/login');
		},
		onError: (message) => {
			toast(<ToastErrorNotification message={message} />, {
				style: { backgroundColor: COLORS.blue90 },
			});
		},
	});

	const onSubmit: SubmitHandler<PasswordResetInterface> = (data) => {
		if (data.password !== data.passwordRepeat) {
			setError('passwordRepeat', {
				type: 'manual',
				message: 'Passwords do not match',
			});
			return;
		}
		if (!emailForPasswordReset || emailForPasswordReset.length === 0) {
			toast(<ToastErrorNotification message="An error occured. Please request another verification code" />, {
				style: { backgroundColor: COLORS.blue90 },
			});
			navigate('/auth/reset-password/enter-email');
			return;
		}
		if (!verificationCodeForPasswordReset || verificationCodeForPasswordReset.length !== 5) {
			toast(<ToastErrorNotification message="An error occured. Please re-enter verification code" />, {
				style: { backgroundColor: COLORS.blue90 },
			});
			navigate('/auth/reset-password/verification-code');
			return;
		}
		requestPasswordReset({
			email: emailForPasswordReset,
			code: verificationCodeForPasswordReset,
			password: data.password,
		});
	};

	return (
		<form onSubmit={handleSubmit(onSubmit)} className="w-full flex flex-col items-center gap-y-[25px]">
			<div className="flex flex-col gap-y-[15px] items-center">
				<h1 className="text-white font-bold text-[20px] text-center">Reset your password</h1>
				<span className="text-[15px] text-[rgba(255,255,255,0.7)] font-semibold">Please enter your new password</span>
			</div>
			<MobilePasswordInput<PasswordResetInterface> errors={errors} register={register} registerFieldName="password" />
			<MobilePasswordInput<PasswordResetInterface>
				errors={errors}
				register={register}
				placeholder="Repeat Password"
				registerFieldName="passwordRepeat"
			/>

			<PrimaryBtn
				isDisabled={isPasswordResetLoading}
				type="submit"
				content="Reset Password"
				style={{ height: '46px', marginTop: '10px' }}
			/>
		</form>
	);
}
