import { BlueMFont } from 'assets/images';
import { AmountDisplayWithCurrency } from 'components/common/AmountDisplayWithCurrency';
import { ItemRarity } from 'interfaces/ItemInterfaces';
import { LiveBoxWinning } from 'pages/overview/interfaces/LiveDropsInterface';
import { useNavigate } from 'react-router';
import { CurrencyOptions, useAppStore } from 'store/useAppStore';
import { getBgCardUrl, getBoxLink } from 'utils';
import { shallow } from 'zustand/shallow';

import { CLOUDFRONT_IMG_URL, rarities } from '../../../constants';
import { LiveDropSkeleton } from './LiveDropSkeleton';

interface Props {
	liveBoxWinning: LiveBoxWinning;
}

export function LiveDropCard({ liveBoxWinning }: Props) {
	const navigate = useNavigate();

	function handleCardClick(boxName: string) {
		const link = getBoxLink(boxName);
		navigate(link);
	}

	const selectedCurrency = useAppStore((state) => state.selectedCurrency, shallow);

	if (
		liveBoxWinning.wonItems.length === 0 ||
		!liveBoxWinning.wonItems[0] ||
		!liveBoxWinning.wonItems[0].imageUrls ||
		!liveBoxWinning.wonItems[0].imageUrls.qualityMedium
	) {
		return <LiveDropSkeleton />;
	}

	const backgroundColor =
		liveBoxWinning.wonItems[0].rarity === ItemRarity.GOLD || liveBoxWinning.wonItems[0].rarity === ItemRarity.RED
			? rarities[liveBoxWinning.wonItems[0].rarity].color75
			: rarities[liveBoxWinning.wonItems[0].rarity].color50;

	return (
		<button
			className="h-full aspect-[0.83/1] px-[11px] lgx:px-[13px] py-[8px] group"
			style={{ backgroundColor: backgroundColor }}
			onClick={() => handleCardClick(liveBoxWinning.box.name)}
		>
			<div className="size-container overflow-hidden flex flex-col justify-between relative h-full w-full rounded-[15px] pt-[4px]">
				<img
					alt={`Card background color ${rarities[liveBoxWinning.wonItems[0].rarity].label}`}
					src={getBgCardUrl(liveBoxWinning.wonItems[0].rarity)}
					className="z-0 h-full w-full object-fill absolute inset-0 rounded-[15px]"
				/>
				<div className="group-hover:-translate-y-[150%] transition-transform duration-300 flex flex-col items-center justify-center gap-y-[2px]">
					<img src={BlueMFont} alt="" className="z-10 mr-auto ml-[8px] h-[13cqh] w-auto object-contain" />

					<img
						src={`${CLOUDFRONT_IMG_URL}/${liveBoxWinning.wonItems[0].imageUrls?.qualityMedium}`}
						alt=""
						className="object-contain max-w-[80%] h-[48cqh] z-10 w-auto my-auto"
					/>
				</div>
				<div className=" z-10 w-full px-[7px] pt-[3px] pb-[5px] h-[27cqh] bg-black/30">
					<div className="group-hover:translate-y-[30cqh] transition-transform duration-300 relative grid grid-cols-[1fr_2fr_1fr] items-center font-semibold h-full w-full text-white">
						<img
							alt={`Card background color is ${rarities[liveBoxWinning.wonItems[0].rarity].label}`}
							src={getBgCardUrl(liveBoxWinning.wonItems[0].rarity)}
							className="z-0 absolute w-full h-full object-fill left-0 top-0 rotate-180 rounded-[8px]"
						/>
						<AmountDisplayWithCurrency
							amount={liveBoxWinning.wonItems[0].price}
							useDivWrapper={false}
							amountStyle={{ fontSize: '8cqh', lineHeight: '8cqh', textAlign: 'center' }}
							imgStyle={{ height: '10cqh' }}
							FirstElementWrapper={({ children }) => (
								<div
									className={`${selectedCurrency === CurrencyOptions.LOOTIES ? 'col-start-2 col-end-3 ' : 'col-start-1 col-end-2'} z-10 flex items-center justify-center  `}
								>
									{children}
								</div>
							)}
							SecondElementWrapper={({ children }) => (
								<span
									className={`${selectedCurrency === CurrencyOptions.LOOTIES ? 'col-start-3 col-end-4' : 'col-start-2 col-end-3 '} z-10 flex  items-center justify-center `}
								>
									{children}
								</span>
							)}
						/>
					</div>
				</div>

				<div className="absolute z-30 inset-0 h-full w-full flex flex-col items-center justify-evenly px-[3px]">
					<span className="group-hover:translate-y-0 -translate-y-[25cqh] text-white/80  transition-transform duration-300 w-full font-bold text-[9px] flex items-center justify-center gap-x-[2px]">
						<AmountDisplayWithCurrency
							amount={liveBoxWinning.box.price}
							useDivWrapper={false}
							imgStyle={{ height: '9px' }}
						/>
					</span>
					<img
						src={`${CLOUDFRONT_IMG_URL}/${liveBoxWinning.box.portraitImageUrls.qualityLow}`}
						alt=""
						className="h-[70cqh] group-hover:translate-y-0 translate-y-[80cqh] w-auto pointer-events-none transition-transform duration-300  aspect-boxCard object-cover rounded-[12px] border-[1px] border-white"
					/>
				</div>
			</div>
		</button>
	);
}
