import { Icon } from '@iconify/react';
import { Tooltip } from 'react-tooltip';

interface Props {
	img: string;
	heading: string;
	content: string;
}

export function WelcomeBonusCard({ content, heading, img }: Props) {
	return (
		<div
			className={`relative pb-[10px] border-[#f0f8ff] bg-white/40 items-center justify-center gap-x-[15px] pt-[20px] px-[12px] flex flex-col gap-y-[5px] border-[2px] w-full max-w-[450px] overflow-hidden rounded-[20px] `}
		>
			<div className="absolute z-10 right-3 top-2">
				<span
					data-tooltip-id="bonus-tooltip"
					data-tooltip-content="Receive free WinBoxes with your first deposit. Higher deposits grant more bonus boxes. No additional steps—simply deposit, open your WinBoxes, and ship any winnings home like a regular purchase."
					className="cursor-pointer"
				>
					<Icon icon="mdi:info-circle-outline" height={22} width={22} className="text-white" />
				</span>
				<Tooltip
					id="bonus-tooltip"
					place="top"
					style={{
						maxWidth: '250px', // Set maximum width for the tooltip
						whiteSpace: 'normal', // Allow text to wrap
						wordWrap: 'break-word', // Break long words if needed
					}}
				/>
			</div>

			<div className="grid grid-cols-[1.1fr_1fr] items-center justify-center gap-x-[20px] w-full">
				<img src={img} alt="" className="w-full" />
				<div className="flex flex-col gap-y-[8px] ms:gap-y-[18px]">
					<div className="rounded-[8px] bg-white text-black font-bold font-inter text-[14px] ms:text-[17px] px-[9px] ms:px-[11px] py-[4px] ms:py-[6px] max-w-fit">
						{heading}
					</div>
					<span className="font-semibold text-[14px] ms:text-[17px] text-[rgba(255,255,255,0.7)] leading-6">
						{content}
					</span>
				</div>
			</div>
			<span className="text-[#51ff94] font-bold text-center text-[13px] ms:text-[15px]">Welcome Bonus</span>
		</div>
	);
}
