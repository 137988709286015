import gsap from 'gsap';
import { isNumber } from 'lodash';
import React, { CSSProperties, useEffect, useRef } from 'react';

interface Props {
	style?: React.CSSProperties;
	height?: number | string;
	width?: number | string;
	color?: string;
	disableAnimation?: boolean;
}

export function Skeleton({ style, height, width, disableAnimation, color = 'rgba(78, 117, 161, 0.5)' }: Props) {
	const highlightRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (disableAnimation) {
			return;
		}
		const tl = gsap.timeline();
		tl.set(highlightRef.current, { x: '-100%' });
		tl.to(highlightRef.current, { x: '100%', duration: 1.6, ease: 'power1.inOut' });
		tl.repeat(-1);
	}, [disableAnimation]);

	return (
		<div
			className="overflow-hidden flex-shrink-0 h-full w-full rounded-[8px]"
			style={{ backgroundColor: color, ...style }}
		>
			{!disableAnimation && (
				<div
					ref={highlightRef}
					className={`${height ? 'h-[var(--dynamic-height)]' : 'h-full'} ${width ? 'w-[var(--dynamic-width)]' : 'w-full'}`}
					style={
						{
							backgroundImage: `linear-gradient(90deg, var(--dynamic-color), rgba(245, 245, 245, 0.4), var(--dynamic-color))`,
							'--dynamic-height': isNumber(height) ? `${height}px` : height,
							'--dynamic-width': isNumber(width) ? `${width}px` : width,
							'--dynamic-color': `${color}`,
							...style,
							borderRadius: '0px',
						} as CSSProperties
					}
				></div>
			)}
		</div>
	);
}
