import { PrimaryBtn } from 'components/common/buttons/PrimaryBtn';
import { WithPrimaryPopup } from 'components/common/popups/WithPrimaryPopup';
import { FontDepositToUnlock, LockPadUnlockAllFeatures } from 'pages/inventory/assets';
import { useInventoryStore } from 'pages/inventory/store/InventoryStore';
import { DepositFont } from 'pages/TransactionManager/assets';
import { useCallback } from 'react';
import { usePopupStore } from 'store/usePopupStore';
import { shallow } from 'zustand/shallow';

export function UnlockAllFeaturesPopup() {
	const { isUnlockAllFeaturePopupVisible, setIsUnlockAllFeaturePopupVisible } = useInventoryStore(
		(state) => ({
			isUnlockAllFeaturePopupVisible: state.isUnlockAllFeaturePopupVisible,
			setIsUnlockAllFeaturePopupVisible: state.setIsUnlockAllFeaturePopupVisible,
		}),
		shallow
	);

	const setIsDepositPopupVisible = usePopupStore((state) => state.setIsDepositPopupVisible, shallow);

	const handleClose = useCallback(() => {
		setIsUnlockAllFeaturePopupVisible(false);
	}, [setIsUnlockAllFeaturePopupVisible]);

	const handleDepositClick = useCallback(() => {
		setIsUnlockAllFeaturePopupVisible(false);
		setIsDepositPopupVisible(true);
	}, [setIsDepositPopupVisible, setIsUnlockAllFeaturePopupVisible]);

	return (
		<WithPrimaryPopup
			onClose={handleClose}
			isExternallyVisible={isUnlockAllFeaturePopupVisible}
			useAnimation={true}
			isDesktop={true}
		>
			<div className="flex gap-x-[40px] items-center w-[600px] xl:w-[700px]">
				<img
					src={LockPadUnlockAllFeatures}
					alt=""
					className="h-[250px] xl:h-[320px] object-contain pointer-events-none"
				/>
				<div className="flex flex-col gap-y-[40px] items-center justify-center">
					<img
						src={FontDepositToUnlock}
						alt="Deposit To Unlock All Features"
						className="h-[120px] xl:h-[125px] object-contain w-auto pointer-events-none"
					/>
					<PrimaryBtn
						onClick={handleDepositClick}
						style={{ width: '80%', height: 'fit-content', position: 'relative', left: '2px' }}
						content={
							<div className="h-[45px] xl:h-[47px] flex items-center justify-center">
								<img src={DepositFont} alt="Deposit" className="h-[26px] xl:h-[27px] w-auto object-contain" />
							</div>
						}
					/>
				</div>
			</div>
		</WithPrimaryPopup>
	);
}
