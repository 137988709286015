import { StopFont } from 'assets/images';
import { PrimaryBtn } from 'components/common/buttons/PrimaryBtn';
import { useBoxOpeningStoreMobile } from 'pages/OpenBox/store/useBoxOpeningStoreMobile';

import { AutoplayBuyOptionBtn } from './AutoplayBuyOptionBtn';

// eslint-disable-next-line no-magic-numbers
const BUY_OPTIONS = [1, 5, 10];

interface Props {
	pricePerSpin: number;
}

export function AutoplayOptionsOverlay({ pricePerSpin }: Props) {
	const { increaseAutoSpinCount, setIsAutoSpin, setAutoSpinCount, autoSpinCount } = useBoxOpeningStoreMobile();

	function handleBuyAutoSpinsClick(index: number, amount: number) {
		increaseAutoSpinCount(amount);
		setIsAutoSpin(true);
	}

	return (
		<div className="w-full absolute left-0 bottom-0 flex flex-col gap-y-[15px] items-center justify-center">
			<div className="w-full flex items-center justify-center gap-x-[17px]">
				{BUY_OPTIONS.map((option, index) => (
					<AutoplayBuyOptionBtn
						onClick={() => handleBuyAutoSpinsClick(index, option)}
						key={index}
						numOfBuys={option}
						price={pricePerSpin * option}
					/>
				))}
			</div>
			<PrimaryBtn
				renderEllipse={false}
				onClick={() => setAutoSpinCount(0)}
				innerContentStyle={{ borderColor: '#EB0F0F' }}
				style={{
					background: 'linear-gradient(331.41deg, rgba(216, 49, 49, 1) 6.78%, rgba(119, 19, 19, 1) 204.87%)',
					height: '65px',
					width: '140px',
				}}
				isDisabled={autoSpinCount === 0}
				content={<img src={StopFont} className="h-[29px] w-auto" alt="Stop" />}
			/>
		</div>
	);
}
