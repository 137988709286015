import { ImageQualityUrls } from 'interfaces/ImageQuali.interface';
import { ItemRarity } from 'interfaces/ItemInterfaces';

export enum PrizeType {
	ITEM = 'item',
	LOOTGAMES = 'lootgame',
}

export interface WonItem {
	inventoryItemId?: string;
	name: string;
	price: number;
	_id: string;
	rarity: ItemRarity;
	imageUrls: ImageQualityUrls;
	isRedeemable: boolean;
	brandName?: string;
	description: string;
}

export interface PrizeInterface {
	data: WonItem;
	type: PrizeType;
	key: string;
}
