import { LoadSpinner } from 'components/common/loading/LoadSpinner';
import { CryptoPayOptionsList } from 'pages/TransactionManager/components/crypto/CryptoPayOptionsList';
import { WalletInformation } from 'pages/TransactionManager/components/crypto/WalletInformation';
import { CryptoPayCurrency } from 'pages/TransactionManager/deposit.interface';
import { useCryptoPaymentInitiator } from 'pages/TransactionManager/hooks/useCryptoPaymentInitiator';
import { useEffect, useState } from 'react';
import { useAppStore } from 'store/useAppStore';
import { shallow } from 'zustand/shallow';

export function CryptoPanel() {
	const [selectedCurrency, setSelectedCurrency] = useState<CryptoPayCurrency>(CryptoPayCurrency.BTC);

	const { data: walletData, isLoading: isPayInitiationLoading } = useCryptoPaymentInitiator({
		currency: selectedCurrency,
	});

	const setCryptoPollingStartTime = useAppStore((state) => state.setCryptoPollingStartTime, shallow);

	useEffect(() => {
		// starts crypto deposit polling
		setCryptoPollingStartTime(new Date());
	}, [setCryptoPollingStartTime]);

	return (
		<div className="max-w-[calc(100%-200px)] px-4 pb-[16px] flex flex-col gap-y-[30px] pt-[5px]">
			<CryptoPayOptionsList
				selectedCurrency={selectedCurrency}
				onSelectedCurrencyChange={(newCurrency) => setSelectedCurrency(newCurrency)}
			/>

			<div className="flex flex-col text-white">
				{walletData && !isPayInitiationLoading && (
					<WalletInformation
						onSelectedNetworkChange={(network) => setSelectedCurrency(network)}
						selectedCurrency={selectedCurrency}
						walletAddress={walletData.payAddress}
						memo={walletData.memo}
					/>
				)}
				{isPayInitiationLoading && (
					<div className="flex items-center justify-center h-[340px]">
						<LoadSpinner />
					</div>
				)}
				<span className="text-[rgba(255,255,255,0.7)] mt-[25px] text-[14px] text-center">
					Cryptocurrency deposits are generally processed after 3 confirmations. While funds typically appear in your
					account within five minutes, please allow up to 30 minutes for full crediting.
				</span>
				<span className="text-[rgba(255,255,255,0.7)] mt-[15px] text-[14px] text-center">
					Deposited funds cannot be withdrawn. All funds must be used for purchases.
				</span>
			</div>
		</div>
	);
}
