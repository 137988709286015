import axios from 'axios';
import { PopulatedBoxInterface } from 'interfaces/BoxInterface';
import { debounce } from 'lodash';
import { SearchSortOptions } from 'pages/search/seach.interface';
import { useCallback, useMemo, useState } from 'react';
import { useInfiniteQuery } from 'react-query';

import { API_DOMAIN, QUERY_CACHE_CONFIG, QUERY_KEYS } from '../../../constants';

async function searchBoxes({
	limit,
	query,
	sortOption,
	maxPrice,
	minPrice,
	page,
	includeItems,
}: Props & { limit: number; page: number }) {
	if (limit === 0) {
		return;
	}

	try {
		const response = await axios.post(
			`${API_DOMAIN.mrlootBackend}/api/boxes/find`,
			{ query, page, limit, sortOption, maxPrice, minPrice, includeItems },
			{ withCredentials: true }
		);
		if (response.data.status === 'success') {
			return response.data;
		}
	} catch (err) {
		console.error(err);
		throw err;
	}
}

interface Props {
	query: string;
	limit?: number;
	initialData?: {
		pages: BoxSearchResultInterface[];
		pageParams: number[];
	};
	minPrice?: number;
	maxPrice?: number;
	sortOption: SearchSortOptions;
	includeItems?: boolean;
}

export interface BoxSearchResultInterface {
	data: PopulatedBoxInterface[];
	pagination: {
		page: number;
		totalPages: number;
	};
}

const DEBOUNCE_TIME = 150; // in ms

export function useSearchBoxes({
	query,
	limit = 10,
	initialData,
	sortOption,
	maxPrice,
	minPrice,
	includeItems,
}: Props) {
	// State for debounced parameters
	const [debouncedParams, setDebouncedParams] = useState<Pick<Props, 'query' | 'minPrice' | 'maxPrice'>>({
		query,
		minPrice,
		maxPrice,
	});

	// Debounce the selected parameters
	const debouncedUpdateParams = useMemo(() => {
		return debounce((newParams: Pick<Props, 'query' | 'minPrice' | 'maxPrice'>) => {
			setDebouncedParams(newParams);
		}, DEBOUNCE_TIME);
	}, []);

	// Handle updates to debounced parameters
	useMemo(() => {
		debouncedUpdateParams({ query, minPrice, maxPrice });
	}, [query, minPrice, maxPrice, debouncedUpdateParams]);

	// Use infinite query with a combination of debounced and immediate parameters
	const { data, isLoading, fetchNextPage, hasNextPage, isFetchingNextPage } = useInfiniteQuery<
		BoxSearchResultInterface,
		Error
	>({
		queryKey: [
			QUERY_KEYS.boxData,
			'search',
			debouncedParams.query,
			limit,
			debouncedParams.minPrice,
			debouncedParams.maxPrice,
			sortOption,
		],
		queryFn: ({ pageParam = 0 }) =>
			searchBoxes({
				query: debouncedParams.query,
				page: pageParam,
				limit: limit,
				minPrice: debouncedParams.minPrice,
				maxPrice: debouncedParams.maxPrice,
				sortOption: sortOption,
				includeItems,
			}),
		keepPreviousData: true,
		getNextPageParam: (lastPage, allPages) => {
			const nextPage = allPages.length;
			return nextPage < lastPage.pagination.totalPages ? nextPage : undefined;
		},
		enabled: limit > 0,
		cacheTime: QUERY_CACHE_CONFIG.longTerm.cacheTime,
		staleTime: QUERY_CACHE_CONFIG.longTerm.staleTime,
		initialData: initialData
			? {
					pages: initialData.pages,
					pageParams: initialData.pageParams,
				}
			: undefined,
	});

	const loadMore = useCallback(() => {
		if (hasNextPage) {
			fetchNextPage();
		}
	}, [fetchNextPage, hasNextPage]);

	const boxes = useMemo(() => (data?.pages ? data.pages.flatMap((page) => page.data) : undefined), [data]);

	return { data: boxes, isLoading, loadMore, isFetchingNextPage, hasNextPage, rawData: data };
}
