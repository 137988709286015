import { ItemRarity } from 'interfaces/ItemInterfaces';
import { TotalOwnedRaritiesInterface } from 'interfaces/UserInterface';
import { getBgCardUrl } from 'utils';

import { rarities } from '../../../../../constants';

interface Props {
	totalOwnedRarities?: TotalOwnedRaritiesInterface;
	excludedRarities: ItemRarity[];

	onExcludedRarityChange: (rarities: ItemRarity[]) => void;
}

export default function OwnedRarities({ totalOwnedRarities, excludedRarities, onExcludedRarityChange }: Props) {
	function handleRarityClick(rarity: ItemRarity) {
		let newExcludedRarities: ItemRarity[];
		if (excludedRarities.length > 0) {
			if (excludedRarities.includes(rarity)) {
				newExcludedRarities = excludedRarities.filter((excludedRarity) => excludedRarity !== rarity);
			} else {
				newExcludedRarities = [...excludedRarities, rarity];
			}
		} else {
			newExcludedRarities = [rarity];
		}

		if (newExcludedRarities.length === Object.values(ItemRarity).length) {
			newExcludedRarities = [];
		}
		onExcludedRarityChange(newExcludedRarities);
	}

	return (
		<div className="flex flex-col justify-between py-2 px-3 h-[115px] w-full text-white border-[2px] border-darkBlue outline outline-[2px] outline-white bg-secondary-gradient rounded-[20px] shadow-[0px_4px_4px_rgba(0,0,0,0.25)]">
			<h3 className="font-semibold text-[14px] mb-1">Owned Rarities</h3>
			<div className="grid grid-cols-6 gap-y-1 gap-x-2 w-full">
				<div className="contents place-items-center">
					{Object.values(ItemRarity).map((el, index) => (
						<button
							key={index}
							className={`mx-auto ${excludedRarities.find((rarity) => el === rarity) && 'opacity-50'}`}
							onClick={() => handleRarityClick(el)}
						>
							<img alt={`Card background color is ${rarities[el].label}`} src={getBgCardUrl(el)} className="h-[35px]" />
						</button>
					))}
				</div>
				<div className="contents place-items-center">
					{Object.values(ItemRarity).map((el, index) => (
						<span
							key={index}
							className={`text-[13px] font-normal text-center ${excludedRarities.includes(el as ItemRarity) ? 'opacity-50' : ''}`}
						>
							{totalOwnedRarities ? totalOwnedRarities[el] : 0}
						</span>
					))}
				</div>
			</div>
		</div>
	);
}
