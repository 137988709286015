import { useSound } from 'hooks/utility/useSound';
import { ItemRarity } from 'interfaces/ItemInterfaces';
import { WinSoundBlueLightBlue, WinSoundGold, WinSoundPinkRed } from 'pages/OpenBox/assets/sounds';
import { useCallback } from 'react';

export function useWinSoundPlayer() {
	const { play: playBlueLightBlueWinSound } = useSound({
		src: WinSoundBlueLightBlue,
		useCleanUp: true,
		cleanupMode: 'afterFinish',
	});
	const { play: playRedPinkWinSound } = useSound({
		src: WinSoundPinkRed,
		useCleanUp: true,
		cleanupMode: 'afterFinish',
	});
	const { play: playPurpleWinSound } = useSound({
		src: WinSoundBlueLightBlue,
		useCleanUp: true,
		cleanupMode: 'afterFinish',
	});
	const { play: playGoldWinSound } = useSound({ src: WinSoundGold, useCleanUp: true, cleanupMode: 'afterFinish' });

	const playSound = useCallback(
		(rarity: ItemRarity) => {
			switch (rarity) {
				case ItemRarity.LIGHT_BLUE:
				case ItemRarity.BLUE:
					playBlueLightBlueWinSound();
					break;

				case ItemRarity.PURPLE:
					playPurpleWinSound();
					break;

				case ItemRarity.PINK:
				case ItemRarity.RED:
					playRedPinkWinSound();
					break;

				case ItemRarity.GOLD:
					// Optionally randomize between two gold sounds
					playGoldWinSound();
					//playGold2WinSound();
					break;

				default:
					console.warn(`Unhandled item rarity: ${rarity}`);
			}
		},
		[playBlueLightBlueWinSound, playPurpleWinSound, playRedPinkWinSound, playGoldWinSound]
	);

	return { playSound };
}
