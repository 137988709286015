import { PrimaryBtn } from 'components/common/buttons/PrimaryBtn';
import { ToastSuccessNotification } from 'components/toasts/ToastSuccessNotification';
import { useUserContext } from 'hooks';
import { useGiftCodeRedeem } from 'pages/TransactionManager/hooks/useGiftCodeRedeem';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { usePopupStore } from 'store/usePopupStore';
import { shallow } from 'zustand/shallow';

interface FormValues {
	redeemCode: string;
}

export const RedeemCodeCheck: React.FC = () => {
	const {
		handleSubmit,
		control,
		formState: { errors },
		setError,
	} = useForm<FormValues>({
		defaultValues: { redeemCode: '' },
	});

	const setIsDepositPopupVisible = usePopupStore((state) => state.setIsDepositPopupVisible, shallow);

	const { load: reloadUser } = useUserContext();

	const { mutate: redeemCode, isLoading: isRedeemLoading } = useGiftCodeRedeem({
		onSuccess: (message: string) => {
			setIsDepositPopupVisible(false);
			toast(<ToastSuccessNotification message={message ?? 'Gift code was successfully redeemed'} />);
			reloadUser();
		},
		onError: (message: string) => {
			setError('redeemCode', { message: message ?? 'Invalid Code' });
		},
	});

	const onSubmit = (data: FormValues) => {
		redeemCode({ code: data.redeemCode });
	};

	const formatRedeemCode = (value: string, oldValue: string): string => {
		const newValueRaw = value.toUpperCase(); // Convert to uppercase

		// Remove all dashes to work with just the alphanumeric characters
		let cleaned = newValueRaw.replace(/-/g, '');

		// Check if user is deleting (length decreased)
		if (newValueRaw.length < oldValue.length) {
			// If the old value ended with a dash, remove the dash + the preceding character
			if (oldValue.endsWith('-')) {
				cleaned = cleaned.slice(0, -1); // Remove two characters
			}
		}

		// Re-insert dashes every 4 characters
		let formatted = '';
		// eslint-disable-next-line no-magic-numbers
		for (let i = 0; i < cleaned.length; i += 4) {
			// eslint-disable-next-line no-magic-numbers
			const chunk = cleaned.substring(i, i + 4);
			if (i > 0) {
				formatted += '-';
			}
			formatted += chunk;
		}

		return formatted;
	};

	return (
		<form className="flex flex-col w-[80%] gap-y-[15px]" onSubmit={handleSubmit(onSubmit)}>
			<Controller
				name="redeemCode"
				control={control}
				rules={{
					required: 'Redeem code is required',
					pattern: {
						value: /^[A-Z0-9]{4}(-[A-Z0-9]{4}){3}$/,
						message: 'Redeem code must follow the format XXXX-XXXX-XXXX-XXXX',
					},
				}}
				render={({ field }) => (
					<div className="flex flex-col w-full gap-y-[5px]">
						<input
							{...field}
							type="text"
							className={`bg-white rounded-[16px] h-[55px] px-[12px] font-bold text-center outline-[2px] outline ${
								errors.redeemCode ? 'outline-errorRed' : 'outline-transparent'
							}`}
							value={field.value}
							onChange={(e) => field.onChange(formatRedeemCode(e.target.value, field.value))}
							maxLength={20}
							placeholder="XXXX-XXXX-XXXX-XXXX"
						/>
						{errors.redeemCode && (
							<span className="text-errorRed font-semibold text-[14px]">{errors.redeemCode.message}</span>
						)}
					</div>
				)}
			/>
			<PrimaryBtn content="REDEEM" type="submit" isDisabled={isRedeemLoading} />
		</form>
	);
};

export default RedeemCodeCheck;
