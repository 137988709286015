import { BoxCardWithItemPreview, Skeleton } from 'components';
import { LoadSpinner } from 'components/common/loading/LoadSpinner';
import { useInfiniteScroll, useSellInventoryItems, useUserContext } from 'hooks';
import { useFindSimiliarBoxes } from 'hooks/data/box/useFindSimiliarBoxes';
import { useWindowDimensions } from 'hooks/utility/layout-measurements/useWindowDimensions';
import { ToastMissedLootNotification } from 'pages/OpenBox/components/ToastMissedLootNotification';
import { ToastNewLootNotification } from 'pages/OpenBox/components/ToastNewLootNotification';
import { ToastRedeemedNotification } from 'pages/OpenBox/components/ToastRedeemedNotification';
import { useBoxOpeningStoreDesktop } from 'pages/OpenBox/store/useBoxOpeningStoreDesktop';
import { useCallback, useRef } from 'react';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import { getBoxLink } from 'utils';

import { screens } from '../../../../constants';

interface Props {
	boxname?: string;
	onBoxClick: () => void;
}

const BOXES_PER_PAGE_XL = 21;
const BOXES_PER_PAGE_LG = 18;
const BOXES_PER_PAGE_MDS = 15;
const BOXES_PER_PAGE_SM = 12;

export default function RecommendedBoxes({ boxname, onBoxClick }: Props) {
	const containerRef = useRef<HTMLDivElement>(null);

	const { screenWidth } = useWindowDimensions();

	const boxesPerPage =
		screenWidth >= screens.xl.width
			? BOXES_PER_PAGE_XL
			: screenWidth >= screens.lg.width
				? BOXES_PER_PAGE_LG
				: screenWidth >= screens.mds.width
					? BOXES_PER_PAGE_MDS
					: BOXES_PER_PAGE_SM;

	const { boxes, isLoading, loadMoreBoxes, isAllItemsLoaded } = useFindSimiliarBoxes({
		boxName: boxname,
		pageSize: boxesPerPage,
		maxPage: 2,
	});

	const { isBoxOpening, wonPrize, isDemoSpin } = useBoxOpeningStoreDesktop((state) => ({
		isBoxOpening: state.isBoxOpening,
		isDemoSpin: state.isDemoSpin,
		wonPrize: state.wonPrize,
	}));

	const lastCardRef = useRef<HTMLDivElement>(null);

	const { load: reloadUser } = useUserContext();

	const { mutate: sellItem } = useSellInventoryItems({
		onSuccess: () => {
			reloadUser();
		},
	});

	const navigate = useNavigate();

	const handleRecommendedBoxClick = useCallback(
		(boxName: string) => {
			if (isBoxOpening && wonPrize) {
				if (wonPrize.data.isRedeemable && wonPrize.data.inventoryItemId) {
					sellItem([wonPrize.data.inventoryItemId]);
				}
				toast(
					isDemoSpin ? (
						<ToastMissedLootNotification item={wonPrize.data} />
					) : wonPrize.data.isRedeemable ? (
						<ToastRedeemedNotification item={wonPrize.data} />
					) : (
						<ToastNewLootNotification item={wonPrize.data} />
					)
				);
			}
			const boxLink = getBoxLink(boxName);
			navigate(boxLink);
			onBoxClick();
		},
		[isBoxOpening, isDemoSpin, navigate, onBoxClick, sellItem, wonPrize]
	);

	const handleLoadOnInfiniteScroll = useCallback(() => {
		if (!isAllItemsLoaded) {
			loadMoreBoxes();
		}
	}, [isAllItemsLoaded, loadMoreBoxes]);

	useInfiniteScroll(lastCardRef, handleLoadOnInfiniteScroll, boxes?.length);

	return (
		<div className={`flex flex-col items-center pt-4`}>
			<div
				ref={containerRef}
				className="w-full mb-4 grid grid-cols-4 mds:grid-cols-5 lg:grid-cols-6 xl:grid-cols-7 gap-[15px] lg:gap-[20px] justify-between items-stretch"
			>
				{boxes &&
					boxes.map((box) => (
						<BoxCardWithItemPreview
							key={box._id}
							box={box}
							styles={{ width: `100%`, height: '100%' }}
							onClick={handleRecommendedBoxClick}
						/>
					))}

				{isLoading &&
					Array(boxesPerPage)
						.fill(null)
						.map((_, index) => (
							<div key={index} className="">
								<Skeleton style={{ width: '100%', aspectRatio: '0.65 / 1' }} />
							</div>
						))}
			</div>
			{!isAllItemsLoaded && <div ref={lastCardRef} className="opacity-0" />}
			<LoadSpinner size="38px" propStyle={{ marginTop: '15px' }} />
		</div>
	);
}
