/* eslint-disable max-lines */
/* eslint-disable complexity */
/* eslint-disable max-lines-per-function */
import { gsap } from 'gsap';
import { CustomEase } from 'gsap/CustomEase';
import {
	DESKTOP_FAST_SPIN_TIME,
	DESKTOP_NORMAL_SPIN_TIME,
	DESKTOP_NUM_PRE_SLOT_PRIZES,
	DESKTOP_NUM_TRANSITION_PRIZES,
	NUM_SURROUNDING_PRIZES,
} from 'pages/OpenBox/box-opening.constants';
import { PrizeInterface } from 'pages/OpenBox/boxOpening.interface';
import { useWinSoundPlayer } from 'pages/OpenBox/hooks/useWinSoundPlayer';
import { useBoxOpeningStoreDesktop } from 'pages/OpenBox/store/useBoxOpeningStoreDesktop';
import { calculateOffset, getScaleOfNodeEl } from 'pages/OpenBox/util';
import { RefObject, useCallback, useEffect, useLayoutEffect, useRef } from 'react';

interface SlotComponentInterface {
	wonPrize: PrizeInterface;
	surroundingWonPrizes: PrizeInterface[];
	progress?: number; // [0; 1]
}

interface Props {
	itemsWrapperRef: RefObject<HTMLDivElement>;
	slotPickerRef: RefObject<HTMLImageElement>;
	videoRef: RefObject<HTMLVideoElement>;
	boxTitleRef: RefObject<HTMLDivElement>;
	onSpinComplete: ({ wonPrize, surroundingWonPrizes }: SlotComponentInterface) => void;
	slotSpinContainerRef: RefObject<HTMLDivElement>;
	isFastSpin: boolean;
	hasAlreadySpun: boolean;
}

const RESIZE_DEBOUNCE_TIME = 200; // ms
const BREAK_TIME_SPIN_END = 0.5; // seconds - time slot pauses after fast spin so user can see what he has won

gsap.registerPlugin(CustomEase);
CustomEase.create('slot-spin', 'M0,0 C0.083,0.294 0.281,0.758 0.58,0.892 0.732,0.96 0.752,1 1,1 ');
CustomEase.create('customBounceOut', 'M0,0 C0.25,0.46 0.45,0.94 0.65,0.94 0.85,0.94 1.1,1.05 1,1');
CustomEase.create('smoothEase', 'M0,0 C0.25,0.1 0.25,1 1,1');

const firstSpinEasingNormal = CustomEase.create(
	'firstSpinEasingNormal',
	'M0,0 C0.132,0.363 0.332,0.632 0.475,0.788 0.652,0.981 0.818,1.001 1,1 '
);

const firstSpinEasingFast = CustomEase.create(
	'custom',
	'M0,0 C0.144,0.36 0.3,0.556 0.406,0.716 0.55,0.934 0.818,1.001 1,1 '
);

const autoSpinEasingOutNormal = CustomEase.create(
	'autoSpinEasingOutNormal',
	'M0,0 C0.072,0.142 0.203,0.563 0.498,0.81 0.625,0.916 0.807,1 1,1 '
);

const autoSpinEasingOutFast = CustomEase.create(
	'autoSpinEasingOutFast',
	'M0,0 C0.072,0.142 0.193,0.612 0.488,0.859 0.615,0.965 0.903,1 1,1 '
);

CustomEase.create('autospin', 'M0,0 C0.168,0 0.492,1 1,1 ');

const getCardWidth = (cardElement: HTMLElement, slotSpinContainerRef?: RefObject<HTMLDivElement>) => {
	const cardWidth = cardElement.clientWidth;

	if (slotSpinContainerRef) {
		const scale = getScaleOfNodeEl(slotSpinContainerRef);
		return Math.min(scale > 0 ? cardWidth / scale : cardWidth, cardWidth);
	}

	return cardWidth;
};

const getContainerWidth = (slotSpinContainerRef: RefObject<HTMLDivElement>) => {
	const scale = getScaleOfNodeEl(slotSpinContainerRef);
	const containerWidth = slotSpinContainerRef.current?.getBoundingClientRect().width ?? 0;
	return containerWidth / scale;
};

const calcRandomOffset = (cardWidth: number, thresholdMin = 0.1, thresholdMax = 0.4) => {
	return Math.floor((Math.random() * (thresholdMax - thresholdMin) + thresholdMin) * cardWidth);
};

export function useDesktopSlotSpinAnimation({
	itemsWrapperRef,
	slotPickerRef,
	slotSpinContainerRef,
	isFastSpin,
	boxTitleRef,
	hasAlreadySpun,
	videoRef,
	onSpinComplete,
}: Props) {
	const isWinningTimelineSpinning = useRef(false);
	const isBufferTimelineSpinning = useRef(false);

	const boxOpenTimeline = useRef<gsap.core.Timeline | null>(gsap.timeline());
	const winningSpinTimeline = useRef(gsap.timeline());

	// resizing & fullscreen changes
	const oldCardWidth = useRef(0);
	const oldContainerWidth = useRef(0);
	const oldStartingX = useRef(0);

	const { wonPrize, slotPrizesSurroundingWon, isFullScreen, isBoxOpening } = useBoxOpeningStoreDesktop((state) => ({
		wonPrize: state.wonPrize,
		slotPrizesSurroundingWon: state.slotPrizesSurroundingWon,
		isFullScreen: state.isFullScreen,
		isBoxOpening: state.isBoxOpening,
	}));

	// Cleanup function that kills the timeline when the component unmounts
	useEffect(() => {
		const tl = winningSpinTimeline.current;
		return () => {
			if (tl) {
				tl.clear();
				tl.pause();
				tl.kill();
			}
		};
	}, []);

	const { playSound: playWinSound } = useWinSoundPlayer();

	const updateOldStoredDimensions = useCallback(
		(cardWidth: number, currentX: number) => {
			oldCardWidth.current = cardWidth;
			oldContainerWidth.current = getContainerWidth(slotSpinContainerRef);
			oldStartingX.current = currentX;
		},
		[slotSpinContainerRef]
	);

	const handleSpinEnd = useCallback(
		(wonPrize: PrizeInterface, surroundingWonPrizes: PrizeInterface[]) => {
			playWinSound(wonPrize.data.rarity);

			isWinningTimelineSpinning.current = false;

			if (isFastSpin) {
				onSpinComplete({ wonPrize, surroundingWonPrizes });
			} else {
				gsap.delayedCall(BREAK_TIME_SPIN_END, () => onSpinComplete({ wonPrize, surroundingWonPrizes }));
			}
		},
		[isFastSpin, onSpinComplete, playWinSound]
	);

	useEffect(() => {
		if (isBoxOpening) {
			gsap.to(boxTitleRef.current, { autoAlpha: 0, duration: 0.5 });
		} else {
			gsap.set(boxTitleRef.current, { autoAlpha: 1 });
		}
	}, [boxTitleRef, isBoxOpening]);

	// lets all assets appear
	useEffect(() => {
		// Initialize the timeline
		boxOpenTimeline.current = gsap.timeline();

		// Use the timeline to set initial values
		boxOpenTimeline.current.set(slotSpinContainerRef.current, { autoAlpha: 0, scale: 0.01 });

		// Pause the timeline after setting values to prevent it from animating immediately
		boxOpenTimeline.current.pause();

		// Define the animation sequence in the timeline
		boxOpenTimeline.current.to(slotSpinContainerRef.current, {
			autoAlpha: 1,
			scale: 1,
			delay: 0.4,
			ease: 'power1.out',
			duration: 1.1,
		});
	}, [slotSpinContainerRef]);

	const animateBoxOpening = useCallback(() => {
		// Restart the timeline from the beginning and play it
		boxOpenTimeline.current?.restart(); // let all assets appear
		videoRef.current?.play();
	}, [videoRef]);

	const startWinningSpin = useCallback(
		({ wonPrize, surroundingWonPrizes, progress }: SlotComponentInterface) => {
			// Helper to update stored dimensions

			const itemsWrapperEl = itemsWrapperRef.current;

			// Initialize animation settings
			const autoSpinEasing = isFastSpin ? autoSpinEasingOutFast : autoSpinEasingOutNormal;
			const firstSpinEasing = isFastSpin ? firstSpinEasingFast : firstSpinEasingNormal;

			// Calculate target position
			const baseTargetPosInCards = DESKTOP_NUM_PRE_SLOT_PRIZES + NUM_SURROUNDING_PRIZES / 2;
			const targetPosInCards = hasAlreadySpun
				? DESKTOP_NUM_TRANSITION_PRIZES + baseTargetPosInCards
				: baseTargetPosInCards;

			const targetX = calculateOffset({
				itemsWrapperRef,
				slotPickerRef,
				targetPos: targetPosInCards,
				scaledItemContainer: slotSpinContainerRef,
			});

			if (!targetX || !itemsWrapperEl || itemsWrapperEl.children.length < 1) {
				isBufferTimelineSpinning.current = false;
				isWinningTimelineSpinning.current = false;
				onSpinComplete({ wonPrize, surroundingWonPrizes });
				return;
			}

			isBufferTimelineSpinning.current = false;
			isWinningTimelineSpinning.current = true;

			winningSpinTimeline.current.kill();
			winningSpinTimeline.current = gsap.timeline();

			const cardWidth = getCardWidth(itemsWrapperEl.children[0] as HTMLElement, slotSpinContainerRef);

			let currentX = Number(gsap.getProperty(itemsWrapperRef.current, 'x'));

			const randomOff = calcRandomOffset(cardWidth);
			const spinDuration = isFastSpin ? DESKTOP_FAST_SPIN_TIME : DESKTOP_NORMAL_SPIN_TIME;

			if (progress) {
				const newContainerWidth = slotSpinContainerRef.current?.getBoundingClientRect().width ?? 0; // Scaled wrapper width
				const wrapperDistance = oldContainerWidth.current - newContainerWidth;
				const cardScalingFactor = cardWidth / oldCardWidth.current;
				currentX = oldStartingX.current * cardScalingFactor - wrapperDistance; // scale old starting x to new dimesions

				winningSpinTimeline.current.set(itemsWrapperEl, { x: currentX });
			}

			updateOldStoredDimensions(cardWidth, currentX);

			winningSpinTimeline.current.to(itemsWrapperEl, {
				x: targetX - randomOff,
				delay: 0.5,
				duration: spinDuration,

				ease: hasAlreadySpun ? autoSpinEasing : firstSpinEasing,
			});
			winningSpinTimeline.current.to(itemsWrapperEl, {
				x: targetX + randomOff / 2,
				duration: 0.3,
				ease: 'none',
			});
			winningSpinTimeline.current.to(itemsWrapperEl, {
				x: targetX,
				duration: 0.2,

				ease: 'power1.out',
				onComplete: () => {
					handleSpinEnd(wonPrize, surroundingWonPrizes);
				},
			});

			if (progress) {
				// eslint-disable-next-line no-magic-numbers
				const totalDuration = spinDuration + 0.3 + 0.2; // Sum of all durations
				const adjustedStartTime = totalDuration * progress;
				winningSpinTimeline.current.seek(adjustedStartTime);
			}
		},
		[
			handleSpinEnd,
			hasAlreadySpun,
			updateOldStoredDimensions,
			isFastSpin,
			itemsWrapperRef,
			onSpinComplete,
			slotPickerRef,
			slotSpinContainerRef,
		]
	);

	useLayoutEffect(() => {
		let isFirstCall = true;
		let debounceTimer: NodeJS.Timeout | null = null;
		let immediateCallTimer: NodeJS.Timeout | null = null;

		const handleResize = () => {
			if (!isWinningTimelineSpinning.current || !isBufferTimelineSpinning.current) {
				return;
			}

			if (isFirstCall) {
				isFirstCall = false;
				executeResizeActions();

				if (immediateCallTimer) {
					clearTimeout(immediateCallTimer);
				}
				immediateCallTimer = setTimeout(() => {
					isFirstCall = true;
				}, RESIZE_DEBOUNCE_TIME);
			} else {
				if (debounceTimer) {
					clearTimeout(debounceTimer);
				}
				debounceTimer = setTimeout(() => {
					executeResizeActions();
				}, RESIZE_DEBOUNCE_TIME);
			}
		};

		const executeResizeActions = () => {
			if (isWinningTimelineSpinning.current) {
				const progress = winningSpinTimeline.current.progress();
				if (progress !== 1 && wonPrize && slotPrizesSurroundingWon) {
					winningSpinTimeline.current.pause();

					startWinningSpin({ wonPrize, surroundingWonPrizes: slotPrizesSurroundingWon, progress });
				}
			}
		};

		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
			if (debounceTimer) {
				clearTimeout(debounceTimer);
			}
			if (immediateCallTimer) {
				clearTimeout(immediateCallTimer);
			}
		};
	}, [startWinningSpin, slotPrizesSurroundingWon, wonPrize]);

	useLayoutEffect(() => {
		if (isWinningTimelineSpinning.current) {
			const progress = winningSpinTimeline.current.progress();
			if (progress !== 1 && wonPrize && slotPrizesSurroundingWon) {
				winningSpinTimeline.current.pause();

				startWinningSpin({ wonPrize, surroundingWonPrizes: slotPrizesSurroundingWon, progress });
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isFullScreen]);

	return { startWinningSpin, animateBoxOpening };
}
