import { PrimaryBtn } from 'components/common/buttons/PrimaryBtn';
import { WithPrimaryPopup } from 'components/common/popups/WithPrimaryPopup';
import { FontDepositToUnlock, LockPadUnlockAllFeatures } from 'pages/inventory/assets';
import { useInventoryStore } from 'pages/inventory/store/InventoryStore';
import { DepositFont } from 'pages/TransactionManager/assets';
import { useCallback } from 'react';
import { usePopupStore } from 'store/usePopupStore';
import { shallow } from 'zustand/shallow';

export function UnlockAllFeaturesPopup() {
	const { isUnlockAllFeaturePopupVisible, setIsUnlockAllFeaturePopupVisible } = useInventoryStore(
		(state) => ({
			isUnlockAllFeaturePopupVisible: state.isUnlockAllFeaturePopupVisible,
			setIsUnlockAllFeaturePopupVisible: state.setIsUnlockAllFeaturePopupVisible,
		}),
		shallow
	);

	const setIsDepositPopupVisible = usePopupStore((state) => state.setIsDepositPopupVisible, shallow);

	const handleClose = useCallback(() => {
		setIsUnlockAllFeaturePopupVisible(false);
	}, [setIsUnlockAllFeaturePopupVisible]);

	const handleDepositClick = useCallback(() => {
		setIsDepositPopupVisible(true);
		setIsUnlockAllFeaturePopupVisible(false);
	}, [setIsDepositPopupVisible, setIsUnlockAllFeaturePopupVisible]);

	return (
		<WithPrimaryPopup
			onClose={handleClose}
			isExternallyVisible={isUnlockAllFeaturePopupVisible}
			useAnimation={true}
			childrenWrapperStyle={{ padding: '20px 13px', maxWidth: '90vw' }}
		>
			<div className="flex flex-col items-center w-fit">
				<img
					src={FontDepositToUnlock}
					alt="Deposit To Unlock All Features"
					className="h-[120px] ms:h-[130px] sm:h-[140px] object-contain w-auto max-w-[90%] pointer-events-none"
				/>
				<img
					src={LockPadUnlockAllFeatures}
					alt=""
					className="h-[240px] xxs:h-[255px] ss:h-[290px] ms:h-[320px] sm:h-[430px] mb-[22px] mt-[5px] object-contain pointer-events-none"
				/>
				<PrimaryBtn
					onClick={handleDepositClick}
					style={{ width: '90%', height: 'fit-content', position: 'relative', left: '2px' }}
					content={
						<div className="h-[50px] ms:h-[55px] sm:h-[63px] flex items-center justify-center">
							<img src={DepositFont} alt="Deposit" className="h-[24px] ms:h-[29px] sm:h-[32px] w-auto object-contain" />
						</div>
					}
				/>
			</div>
		</WithPrimaryPopup>
	);
}
