import { faArrowDown19, faArrowDown91, faCheck, faFire, faStar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SecondaryBtn } from 'components/common/buttons/SecondaryBtn';
import { WithPrimaryPopup } from 'components/common/popups/WithPrimaryPopup';
import { debounce } from 'lodash';
import { useMemo } from 'react';
import ReactDOM from 'react-dom';
import { useAppStore } from 'store/useAppStore';
import { shallow } from 'zustand/shallow';

import { SearchSortOptions } from '../seach.interface';
import { useSearchStore } from '../store/SearchStore';
import { AmountRangeFilter } from './AmountRangeFilter';

interface Props {
	onClose: () => void;
	isVisible: boolean;
}

const OPTIONS = [
	{
		label: 'Popular',
		value: SearchSortOptions.POPULAR,
		icon: faFire,
	},
	{
		label: 'Newest',
		value: SearchSortOptions.NEWEST,
		icon: faStar,
	},
	{
		label: 'Price Low',
		value: SearchSortOptions.PRICE_LOW,
		icon: faArrowDown19,
	},
	{
		label: 'Price High',
		value: SearchSortOptions.PRICE_HIGH,
		icon: faArrowDown91,
	},
];

const DEBOUNCE_TIME = 200;

export function FilterPopup({ isVisible, onClose }: Props) {
	const selectedCurrency = useAppStore((state) => state.selectedCurrency, shallow);

	const { priceFilterMax, priceFilterMin, setPriceFilterMax, setPriceFilterMin } = useSearchStore(
		(state) => ({
			priceFilterMin: state.priceFilterMin,
			priceFilterMax: state.priceFilterMax,
			setPriceFilterMin: state.setPriceFilterMin,
			setPriceFilterMax: state.setPriceFilterMax,
		}),
		shallow
	);

	const debouncedSetPriceFilterMin = useMemo(() => debounce(setPriceFilterMin, DEBOUNCE_TIME), [setPriceFilterMin]);
	const debouncedSetPriceFilterMax = useMemo(() => debounce(setPriceFilterMax, DEBOUNCE_TIME), [setPriceFilterMax]);

	const { setSortOption, sortOption } = useSearchStore(
		(state) => ({
			sortOption: state.sortOption,
			setSortOption: state.setSortOption,
		}),
		shallow
	);

	function handleOptionClick(option: SearchSortOptions) {
		setSortOption(option);
		onClose();
	}

	const portalRoot = document.getElementById('portal-popup-root');

	if (!portalRoot) {
		console.error('Portal root not found. Make sure the element with id "portal-popup-root" exists in your HTML.');
		return null;
	}

	return ReactDOM.createPortal(
		<WithPrimaryPopup onClose={onClose} isExternallyVisible={isVisible} useAnimation={true} isDesktop={true}>
			<div className="flex flex-col">
				<h1 className="text-white font-bold text-[20px] mb-[25px]">Sort By</h1>
				<div className="flex flex-wrap items-center justify-center gap-x-[20px] gap-y-[20px]">
					{OPTIONS?.map((option) => (
						<SecondaryBtn
							key={option.value}
							onClick={() => handleOptionClick(option.value)}
							style={{
								width: '150px',
								height: '55px',
								padding: '0px',
								boxShadow: 'none',
							}}
							content={
								<div
									className={`h-full w-full grid grid-cols-[20px_auto_20px] rounded-[19px] items-center justify-items-center px-[12px] ${option.value === sortOption ? 'shadow-secondaryInset' : 'hover:shadow-secondaryInset'}`}
								>
									{option.icon && <FontAwesomeIcon icon={option.icon} />}
									<span className="text-[15px] font-bold text-center">{option.label}</span>
									{option.value === sortOption && <FontAwesomeIcon icon={faCheck} className="" />}
								</div>
							}
						/>
					))}
				</div>

				<h1 className="text-white font-bold mt-[50px] mb-[10px] text-[20px]">Price Range</h1>
				<AmountRangeFilter
					defaultMinValue={priceFilterMin}
					defaultMaxValue={priceFilterMax}
					currency={selectedCurrency}
					onMinPriceChange={(v) => debouncedSetPriceFilterMin(v)}
					onMaxPriceChange={(v) => debouncedSetPriceFilterMax(v)}
				/>
			</div>
		</WithPrimaryPopup>,
		portalRoot
	);
}
