import { BoxSearchResultInterface } from 'hooks';
import { PopulatedBoxInterface } from 'interfaces/BoxInterface';
import { InfiniteData } from 'react-query';
import { createWithEqualityFn } from 'zustand/traditional';

import { SearchSortOptions } from '../seach.interface';

interface SearchState {
	searchQuery: string;
	setSearchQuery: (newQuery: string) => void;

	priceFilterMin: number | undefined;
	setPriceFilterMin: (v: number | ((prev: number | undefined) => number)) => void;

	priceFilterMax: number | undefined;
	setPriceFilterMax: (v: number | ((prev: number | undefined) => number)) => void;

	sortOption: SearchSortOptions;
	setSortOption: (option: SearchSortOptions) => void;

	savedRandomBoxes: PopulatedBoxInterface[] | null;
	setSavedRandomBoxes: (boxes: PopulatedBoxInterface[] | null) => void;

	searchPage: number | null;
	setSearchPage: (page: number | ((prev: number | null) => number)) => void;

	savedSearchBoxes: InfiniteData<BoxSearchResultInterface> | undefined;
	setSavedSearchBoxes: (boxes: InfiniteData<BoxSearchResultInterface> | undefined) => void;

	resetStore: () => void;
}

export const useSearchStore = createWithEqualityFn<SearchState>(
	(set) => ({
		searchQuery: '',
		setSearchQuery: (newQuery: string) => set({ searchQuery: newQuery }),

		priceFilterMin: undefined,
		setPriceFilterMin: (v) =>
			set((state) => ({
				priceFilterMin: typeof v === 'function' ? (v as Function)(state.priceFilterMin) : v,
			})),

		priceFilterMax: undefined,
		setPriceFilterMax: (v) =>
			set((state) => ({
				priceFilterMax: typeof v === 'function' ? (v as Function)(state.priceFilterMax) : v,
			})),

		sortOption: SearchSortOptions.POPULAR,
		setSortOption: (option) => set({ sortOption: option }),

		savedRandomBoxes: null,
		setSavedRandomBoxes: (boxes: PopulatedBoxInterface[] | null) => set({ savedRandomBoxes: boxes }),

		searchPage: null,
		setSearchPage: (page) =>
			set((state) => ({
				searchPage: typeof page === 'function' ? (page as Function)(state.searchPage) : page,
			})),

		savedSearchBoxes: undefined,
		setSavedSearchBoxes: (boxes: InfiniteData<BoxSearchResultInterface> | undefined) =>
			set({ savedSearchBoxes: boxes }),

		resetStore: () =>
			set({
				searchQuery: '',
				priceFilterMin: undefined,
				priceFilterMax: undefined,
				savedRandomBoxes: null,
				searchPage: null,
				savedSearchBoxes: undefined,
			}),
	}),
	Object.is
);
