import axios from 'axios';
import { API_DOMAIN } from '../../../constants';
import { useMutation } from 'react-query';
import { usePopupStore } from 'store/usePopupStore';
import { shallow } from 'zustand/shallow';

interface RegistrationData {
	email: string;
	password: string;
	recaptchaToken: string;
	isBonusActive?: boolean;
	acceptsMarketingEmails: boolean;
}

async function registerUser(credentials: RegistrationData) {
	try {
		const response = await axios.post(
			`${API_DOMAIN.mrlootBackend}/api/user/register`,
			{
				email: credentials.email,
				password: credentials.password,
				isFreeBoxBonusActive: credentials.isBonusActive,
				recaptchaToken: credentials.recaptchaToken,
				acceptsMarketingEmails: credentials.acceptsMarketingEmails,
			},
			{ withCredentials: true }
		);

		if (response.data.status !== 'success') {
			throw new Error(response.data.message || 'Unexpected error occurred during registration');
		}
	} catch (error) {
		console.error(error);
		throw error;
	}
}

interface UseRegisterHookOptions {
	onSuccess?: () => void;
	onError?: (errorMessage: string) => void;
}

export function useRegister({ onSuccess, onError }: UseRegisterHookOptions = {}) {
	const bonusExpirationTime = usePopupStore((state) => state.signupBonusExpiredAt, shallow);

	const {
		mutate: executeRegistration,
		isLoading,
		error: registrationError,
	} = useMutation(registerUser, {
		onSuccess: () => {
			onSuccess?.();
		},
		onError: (error: any) => {
			const errorMessage = error.response?.data?.message || error.message || 'Registration failed due to server error';
			onError?.(errorMessage);
		},
	});

	async function executeRegistrationWithBonusCheck(data: RegistrationData) {
		const isBonusActive = !!bonusExpirationTime && bonusExpirationTime.getTime() > new Date().getTime();
		executeRegistration({ ...data, isBonusActive });
	}

	return { executeRegistrationWithBonusCheck, registrationError, isLoading };
}
