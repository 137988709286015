import { useCallback, useEffect, useState } from 'react';

interface CountdownFormat {
	days?: boolean;
	hours?: boolean;
	minutes?: boolean;
	seconds?: boolean;
}

const ONE_MINUTE_IN_MS = 60;
const ONE_HOUR_IN_MIN = 60;
const ONE_DAY_IN_H = 24;

export const useCountdownTimer = (
	expirationTime: Date | null,
	format: CountdownFormat = { days: false, hours: true, minutes: true, seconds: true }
): string => {
	const calculateTimeLeft = useCallback(() => {
		if (!expirationTime) {
			return '00:00';
		}

		const difference = new Date(expirationTime).getTime() - new Date().getTime();

		if (difference <= 0) {
			return '00:00';
		}

		const days = Math.floor(difference / (1000 * ONE_MINUTE_IN_MS * ONE_HOUR_IN_MIN * ONE_DAY_IN_H));
		const hours = Math.floor((difference / (1000 * ONE_MINUTE_IN_MS * ONE_HOUR_IN_MIN)) % ONE_DAY_IN_H);
		const minutes = Math.floor((difference / 1000 / ONE_MINUTE_IN_MS) % ONE_HOUR_IN_MIN);
		const seconds = Math.floor((difference / 1000) % ONE_MINUTE_IN_MS);

		const parts: string[] = [];

		if (format.days) {
			parts.push(`${days}d`);
		}
		if (format.hours) {
			parts.push(`${hours.toString().padStart(2, '0')}h`);
		}
		if (format.minutes) {
			parts.push(`${minutes.toString().padStart(2, '0')}m`);
		}
		if (format.seconds) {
			parts.push(`${seconds.toString().padStart(2, '0')}s`);
		}

		return parts.join(' ');
	}, [expirationTime, format]);

	const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

	useEffect(() => {
		const timer = setInterval(() => {
			setTimeLeft(calculateTimeLeft());
		}, 1000);

		return () => clearInterval(timer);
	}, [calculateTimeLeft]);

	return timeLeft;
};
