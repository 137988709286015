/* eslint-disable max-lines */
/* eslint-disable max-lines-per-function */
import { BoxTitleImg } from 'components/data-display/BoxTitleImg';
import { ToastErrorNotification } from 'components/toasts/ToastErrorNotification';
import { gsap } from 'gsap';
import { useScrollLock, useUserContext } from 'hooks';
import { useSound } from 'hooks/utility/useSound';
import { PopulatedBoxInterface } from 'interfaces/BoxInterface';
import { useInventoryBoxes } from 'pages/inventory/hooks/useInventoryBoxes';
import { PrizeInterface } from 'pages/OpenBox/boxOpening.interface';
import { useSlotSpinReset } from 'pages/OpenBox/hooks/useSlotSpinReset';
import { useCallback, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';
import { shallow } from 'zustand/shallow';

import { CLOUDFRONT_IMG_URL } from '../../../../../constants';
import { BoxOpenBackgroundSound } from '../../../assets';
import { AutoSpinContinueSound, SpinHiglightSparkleSound } from '../../../assets/sounds';
import {
	DESKTOP_FADE_IN_DURATION_BG_SPIN_SOUND,
	DESKTOP_FAST_SPIN_TIME,
	DESKTOP_NORMAL_SPIN_TIME,
	DESKTOP_NUM_PRE_SLOT_PRIZES,
	FADE_OUT_DURATION_BG_SPIN_SOUND,
	MAX_SPARKLE_FAST_SPIN,
	MAX_SPARKLE_NORMAL_SPIN,
	NUM_SURROUNDING_PRIZES,
	SPARKLE_SOUND_OFFSET,
} from '../../../box-opening.constants';
import { useBoxSlotItems } from '../../../hooks/useBoxSlotItems';
import { usePlaySoundRandomly } from '../../../hooks/usePlaySoundRandomly';
import { useWonBoxItem } from '../../../hooks/useWonBoxItem';
import { useBoxOpeningStoreDesktop } from '../../../store/useBoxOpeningStoreDesktop';
import { useDesktopSlotSpinAnimation } from '../../hooks/useDesktopSlotSpinAnimation';
import { useSpinCompleteHandlerDesktop } from '../../hooks/useSpinCompleteHandlerDesktop';
import BoxVideoBackground from './components/BoxVideoBackground';
import { SlotPopupHandler } from './components/SlotPopupHandler';
import { SlotPrizesWrapper } from './components/SlotPrizesWrapper';
import { WithSlotImages } from './components/WithSlotImages';

interface Props {
	box: PopulatedBoxInterface;
}

export function Slot({ box }: Props) {
	const parentRef = useRef<HTMLDivElement>(null);
	const slotItemsWrapperRef = useRef<HTMLDivElement>(null);
	const slotPickerRef = useRef<HTMLImageElement>(null);
	const slotSpinContainerRef = useRef<HTMLImageElement>(null);
	const boxTitleRef = useRef<HTMLDivElement>(null);
	const videoRef = useRef<HTMLVideoElement>(null);

	const decreaseAutoSpinCount = useBoxOpeningStoreDesktop((state) => state.decreaseAutoSpinCount, shallow);
	const setSlotPrizesPreWon = useBoxOpeningStoreDesktop((state) => state.setSlotPrizesPreWon, shallow);
	const setSlotPrizesSurroundingWon = useBoxOpeningStoreDesktop((state) => state.setSlotPrizesSurroundingWon, shallow);
	const resetStore = useBoxOpeningStoreDesktop((state) => state.resetStore, shallow);
	const isFastSpin = useBoxOpeningStoreDesktop((state) => state.isFastSpin, shallow);
	const clientSeed = useBoxOpeningStoreDesktop((state) => state.clientSeed, shallow);
	const isFullScreen = useBoxOpeningStoreDesktop((state) => state.isFullScreen, shallow);
	const hasSlotAlreadySpun = useBoxOpeningStoreDesktop((state) => state.hasSlotAlreadySpun, shallow);
	const wonPrize = useBoxOpeningStoreDesktop((state) => state.wonPrize, shallow);

	const { load: reloadUser } = useUserContext();

	const { slotPrizesSurroundingWon, isBoxOpening, autoSpinCount } = useBoxOpeningStoreDesktop(
		(state) => ({
			slotPrizesSurroundingWon: state.slotPrizesSurroundingWon,
			isBoxOpening: state.isBoxOpening,
			autoSpinCount: state.autoSpinCount,
		}),
		shallow
	);

	const { data: inventoryBoxes } = useInventoryBoxes();

	const [autoSpinLoadingBufferPrizes, setAutoSpinLoadingBufferPrizes] = useState<PrizeInterface[]>();

	const hasSlotBgImgsLoaded = useRef(false);
	const hasSpinPrizesLoaded = useRef(false);
	const hasWonPrizeImgLoaded = useRef(false);
	const isAnimatingOpening = useRef(false);

	const { start: playHighlightSoundRandomly, stop: stopHighlightSoundRandomly } = usePlaySoundRandomly({
		src: SpinHiglightSparkleSound,
		minPlays: 1,
		maxPlays: isFastSpin ? MAX_SPARKLE_FAST_SPIN : MAX_SPARKLE_NORMAL_SPIN,
		durationMS: isFastSpin
			? DESKTOP_FAST_SPIN_TIME * 1000 - SPARKLE_SOUND_OFFSET
			: DESKTOP_NORMAL_SPIN_TIME * 1000 - SPARKLE_SOUND_OFFSET,
	});
	const { play: playAutoSpinContinueSound } = useSound({ src: AutoSpinContinueSound });
	const {
		play: playBoxSpinBackgroundSound,
		stop: stopBoxSpinBackgroundSound,
		setVolume: setBackgroundSoundVolume,
	} = useSound({
		src: BoxOpenBackgroundSound,
		volume: 0.7,
		rate: 1,
		loop: true,
	});

	const { resetSlotSpin } = useSlotSpinReset({
		itemsWrapperRef: slotItemsWrapperRef,
		slotPickerRef,
	});

	const { generatePreAndPostSlotItems } = useBoxSlotItems({
		numPreSlotPrizes: DESKTOP_NUM_PRE_SLOT_PRIZES,
	});

	const { requestWinningPrize } = useWonBoxItem({
		useMobileStore: false,
		box,
		onBoxOpenSuccess: () => {
			reloadUser();
		},
	});

	const alreadyFailed = useRef(false);

	const handleTotalLoadingFail = useCallback(() => {
		if (alreadyFailed.current) {
			return;
		}
		alreadyFailed.current = true;
		toast(
			<ToastErrorNotification message="Network connection interrupted. Please check your internet and try spinning again" />
		);
		resetStore();
	}, [resetStore]);

	// for img loading of main slot prizes

	const { triggerSpinCompleteHandler } = useSpinCompleteHandlerDesktop({
		videoRef,
		boxTitleRef,
		boxPrice: box.price,
		onPrepareNextAutoSpin: useCallback(({ wonPrize, surroundingWonPrizes }) => {
			// set buffer loading screen for autospin - will be used as loading screen while new items are loading
			const preWonBufferItems = surroundingWonPrizes?.slice(0, NUM_SURROUNDING_PRIZES / 2);
			const postWonBufferItems = surroundingWonPrizes?.slice(NUM_SURROUNDING_PRIZES / 2, NUM_SURROUNDING_PRIZES);
			const newAutoSpinLoadingPrizes = [...preWonBufferItems, wonPrize, ...postWonBufferItems].map((el) => ({
				...el,
				key: uuidv4(),
			}));

			setAutoSpinLoadingBufferPrizes(newAutoSpinLoadingPrizes);

			// now waits until images of buffer are loaded - then [in handleAutoSpinTransitionBufferLoaded] resets slot
		}, []),
	});

	const { startWinningSpin, animateBoxOpening } = useDesktopSlotSpinAnimation({
		isFastSpin,
		videoRef,
		itemsWrapperRef: slotItemsWrapperRef,
		slotPickerRef,
		slotSpinContainerRef,
		boxTitleRef,
		hasAlreadySpun: hasSlotAlreadySpun,
		onSpinComplete: useCallback(() => {
			isAnimatingOpening.current = false;
			hasSpinPrizesLoaded.current = false;
			hasWonPrizeImgLoaded.current = false;

			if (autoSpinCount === 0) {
				// eslint-disable-next-line no-magic-numbers
				setBackgroundSoundVolume(0.2);
			}

			triggerSpinCompleteHandler();
		}, [autoSpinCount, setBackgroundSoundVolume, triggerSpinCompleteHandler]),
	});

	// win prize not loaded at this point; only buffer and spin prizes
	const startSpinIfAssetsLoaded = useCallback(() => {
		if (!hasSpinPrizesLoaded.current || !hasSlotBgImgsLoaded.current) {
			return;
		}

		if (!hasSlotAlreadySpun && !isAnimatingOpening.current) {
			animateBoxOpening();
			isAnimatingOpening.current = true;
		}

		if (hasSlotAlreadySpun) {
			playAutoSpinContinueSound();
		}

		playHighlightSoundRandomly();
		playBoxSpinBackgroundSound(DESKTOP_FADE_IN_DURATION_BG_SPIN_SOUND);

		if (wonPrize && slotPrizesSurroundingWon) {
			startWinningSpin({ wonPrize, surroundingWonPrizes: slotPrizesSurroundingWon });
		}
	}, [
		animateBoxOpening,
		hasSlotAlreadySpun,
		playAutoSpinContinueSound,
		playBoxSpinBackgroundSound,
		playHighlightSoundRandomly,
		slotPrizesSurroundingWon,
		startWinningSpin,
		wonPrize,
	]);

	const handleSpinPrizesLoaded = useCallback(() => {
		hasSpinPrizesLoaded.current = true;
		startSpinIfAssetsLoaded();
	}, [startSpinIfAssetsLoaded]);

	const handleSlotBgImgsLoaded = useCallback(() => {
		hasSlotBgImgsLoaded.current = true;
		startSpinIfAssetsLoaded();
	}, [startSpinIfAssetsLoaded]);

	// translates slot to start items to prepare for next autospin (winning screen prizes and first prizes in slot are the same)
	const handleAutoSpinTransitionBufferLoaded = useCallback(() => {
		resetSlotSpin();
		decreaseAutoSpinCount();

		requestWinningPrize({
			boxName: box.name,
			clientSeed: clientSeed,
			useFreeBox: !!inventoryBoxes && inventoryBoxes.length > 0,
		});
		const { preSlotItems, surroundingSlotItems } = generatePreAndPostSlotItems(box);
		setSlotPrizesPreWon(preSlotItems);
		setSlotPrizesSurroundingWon(surroundingSlotItems);
		// now wait until prizes are loaded in slot - we get notified via callbacks of SlotPrizesWrapper
	}, [
		box,
		clientSeed,
		decreaseAutoSpinCount,
		generatePreAndPostSlotItems,
		inventoryBoxes,
		requestWinningPrize,
		resetSlotSpin,
		setSlotPrizesPreWon,
		setSlotPrizesSurroundingWon,
	]);

	useScrollLock({ isDisabled: !isFullScreen });

	// cleanup after spin end
	useEffect(() => {
		if (!isBoxOpening) {
			stopHighlightSoundRandomly();
			stopBoxSpinBackgroundSound(FADE_OUT_DURATION_BG_SPIN_SOUND);
			hasSpinPrizesLoaded.current = false;
			hasWonPrizeImgLoaded.current = false;
			isAnimatingOpening.current = false;
			setAutoSpinLoadingBufferPrizes(undefined);
			if (slotSpinContainerRef.current) {
				gsap.set(slotSpinContainerRef.current, { autoAlpha: 0, scale: 0.01 });
			}
			if (slotItemsWrapperRef.current) {
				gsap.set(slotItemsWrapperRef.current, { x: 0 });
			}
		}
	}, [isBoxOpening, stopBoxSpinBackgroundSound, stopHighlightSoundRandomly]);

	return (
		<div
			id="slot-parent"
			ref={parentRef}
			className={`${isFullScreen ? 'fixed z-50 left-0 top-0 w-screen h-screen rounded-none' : 'relative aspect-[1.8/1] mds:aspect-[2/1] lg:aspect-[2.7/1] lgx:aspect-[2.6/1] w-full rounded-[24px]  border-[3px] border-[rgba(255,255,255,0.25)]'} `}
		>
			<div ref={boxTitleRef} className="absolute left-5 top-5 z-10 max-w-[70%]">
				<BoxTitleImg fileUrl={box.titleImageUrls?.qualityMedium} boxname={box.name} />
			</div>

			<BoxVideoBackground
				ref={videoRef}
				boxId={box._id}
				src={`${CLOUDFRONT_IMG_URL}/${box.boxOpeningVideoName}`}
				isFullScreen={isFullScreen}
			/>
			<div
				ref={slotSpinContainerRef}
				className="absolute inset-0 w-full h-full flex items-center overflow-hidden"
				style={{
					willChange: 'opacity, transform',
				}}
			>
				<WithSlotImages
					ref={slotPickerRef}
					onCompleteLoading={handleSlotBgImgsLoaded}
					onTotalFailure={handleTotalLoadingFail}
				>
					<SlotPrizesWrapper
						ref={(ref) => {
							(slotItemsWrapperRef as { current: HTMLDivElement | null }).current = ref?.getContainerElement() || null;
						}}
						autoSpinLoadingBufferPrizes={autoSpinLoadingBufferPrizes}
						onImageLoadingFailed={handleTotalLoadingFail}
						onAutoSpinTransitionPrizesLoaded={handleAutoSpinTransitionBufferLoaded}
						onSpinPrizesLoaded={handleSpinPrizesLoaded}
					/>
				</WithSlotImages>
			</div>

			<SlotPopupHandler />
		</div>
	);
}
