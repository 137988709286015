import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ToastErrorNotification } from 'components/toasts/ToastErrorNotification';
import { LoginHeader } from 'pages/authentication/mobile/components/LoginHeader';
import { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';

import { COLORS } from '../../../constants';
import { shallow } from 'zustand/shallow';
import { useHistoryStore } from 'store/useHistoryStore';

export default function MobileAuthLayout() {
	const location = useLocation();
	const navigate = useNavigate();
	const getLastHistoryExcluding = useHistoryStore((state) => state.getLastHistoryExcluding, shallow);

	const params = new URLSearchParams(location.search);
	const errorCode = params.get('error_message');

	useEffect(() => {
		if (errorCode && errorCode.length > 0) {
			toast(<ToastErrorNotification message={errorCode} />, {
				style: { backgroundColor: COLORS.blue90 },
				autoClose: 5000,
			});
		}
	}, [errorCode]);

	function handleAuthClose() {
		const lastLocationOutsideAuth = getLastHistoryExcluding('auth') ?? '/';
		navigate(lastLocationOutsideAuth);
	}

	return (
		<>
			<ToastContainer
				autoClose={5000}
				stacked={true}
				hideProgressBar={true}
				draggableDirection="y"
				theme="colored"
				toastStyle={{ backgroundColor: COLORS.blue90 }}
				closeButton={(props) => (
					<button onClick={props.closeToast} className="absolute right-2 top-2 text-whie text-[14px] text-white">
						<FontAwesomeIcon icon={faXmark} />
					</button>
				)}
			/>
			<div className="bg-primary-gradient">
				<div className="px-mobile-side-padding pt-[25px] space-y-[45px] min-h-dvh">
					<LoginHeader onClose={handleAuthClose} />
					<div className="w-full gap-y-[45px] pb-[25px] flex flex-col justify-between min-h-[calc(100dvh-110px)]">
						<Outlet />
						<div className="text-white/80 text-[14px] text-center font-semibold">
							This site is protected by reCAPTCHA and the Google{' '}
							<a target="_blank" href="https://policies.google.com/privacy">
								Privacy Policy
							</a>{' '}
							and{' '}
							<a target="_blank" href="https://policies.google.com/terms">
								Terms of Service
							</a>{' '}
							apply.
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
