import { TrophyIcon } from 'assets/images';
import { useWindowDimensions } from 'hooks';
import { LiveDropCard } from 'pages/overview/components/LiveDropCard';
import { LiveDropSkeleton } from 'pages/overview/components/LiveDropSkeleton';
import { useLiveBoxDrops } from 'pages/overview/hooks/liveDrops/useLiveBoxDrops';
import { useLiveDropAnimation } from 'pages/overview/hooks/liveDrops/useLiveDropAnimation';
import { useRef } from 'react';

import { screens } from '../../../../constants';

const DROP_SYNC_DELAY = 20000; // 20 seconds
const NUM_SKELETON_XS = 10;
const NUM_SKELETON_MS = 20;

export function LiveDrops() {
	const { drops, isLoading } = useLiveBoxDrops({ refetchInterval: DROP_SYNC_DELAY });

	const containerRef = useRef<HTMLDivElement>(null);

	useLiveDropAnimation({ containerRef, drops, dropSyncDelay: DROP_SYNC_DELAY });

	const { screenWidth } = useWindowDimensions();
	const numSkeleton = screenWidth >= screens.ms.width ? NUM_SKELETON_MS : NUM_SKELETON_XS;

	return (
		<div className="w-full mb-[-3px]">
			<div
				className="w-full flex gradient-mask-r-[rgba(0,0,0,1.0)_80%] h-[95px] ms:h-[115px] sm:h-[140px] lgx:h-[155px]"
				style={{
					WebkitMaskImage: 'linear-gradient(90deg, rgba(0, 0, 0) 85%, rgba(0, 0, 0, 0.3))',
					maskImage: 'linear-gradient(90deg, rgba(0, 0, 0) 85%, rgba(0, 0, 0, 0.3))',
				}}
			>
				<div className="relative flex flex-col bg-primary-gradient justify-center items-center gap-y-[5px] h-full border-r-[2px] border-[#85a7e7]">
					<img src={TrophyIcon} className="h-[28px] ms:h-[33px] sm:h-[39px] lgx:h-[44px] w-auto" alt="" />
					<h2 className="font-bold text-white/80 font-inter text-[13px] ms:text-[15px] sm:text-[18px] lgx:text-[19px] text-center w-full">
						LIVE WINS
					</h2>
				</div>
				<div className="relative overflow-x-hidden h-full w-full max-w-full ">
					<div ref={containerRef} className="flex items-center gap-x-[1px] justify-start h-full will-change-transform">
						{isLoading
							? Array.from({ length: numSkeleton }).map((_, index) => <LiveDropSkeleton key={index} />)
							: drops && drops.map((dropItem) => <LiveDropCard liveBoxWinning={dropItem} key={dropItem._id} />)}
					</div>
				</div>
			</div>
		</div>
	);
}
