import { SeparatorWithText } from 'components/Separator/SeparatorWithText';
import OAuthBtnsHorizontal from '../../components/OAuthBtnsHorizontal';
import { MobileLoginForm } from './MobileLoginForm';

export default function MobileLogin() {
	return (
		<div className="flex flex-col gap-y-[30px] pb-[40px]">
			<MobileLoginForm />

			<SeparatorWithText
				text="Or continue"
				textLine2="with"
				propStyle={{ color: 'rgba(255,255,255,0.6)', marginTop: '15px', fontFamily: 'poppins' }}
				lineColor="rgba(255,255,255,0.6)"
			/>

			<OAuthBtnsHorizontal isRequestLoading={false} />
		</div>
	);
}
