import Secure3dBatch from './3d-secure.webp';
import Bonus10PFont from './10p bonues.png';
import Bonus20PFont from './20p bonues.png';
import FontAvailableBonus from './available bonus.webp';
import DepositFont from './deposit.webp';
import FirstDepositBonusFont from './first-deposit-bonus-font.webp';
import Font25Dollar from './font-25-dollar.webp';
import Font25Euro from './font-25-euro.webp';
import Font25Pounds from './font-25-pounds.webp';
import Font50Dollar from './font-50-dollar.webp';
import Font50Euro from './font-50-euro.webp';
import Font50Pounds from './font-50-pounds.webp';
import Font100Dollar from './font-100-dollar.webp';
import Font100Euro from './font-100-euro.webp';
import Font100Pounds from './font-100-pounds.webp';
import Font250Dollar from './font-250-dollar.webp';
import Font250Euro from './font-250-euro.webp';
import Font250Pounds from './font-250-pounds.webp';
import Font500Dollar from './font-500-dollar.webp';
import Font500Euro from './font-500-euro.webp';
import Font500Pounds from './font-500-pounds.webp';
import Font1000Dollar from './font-1000-dollar.webp';
import Font1000Euro from './font-1000-euro.webp';
import Font1000Pounds from './font-1000-pounds.webp';
import FontBonusApplies from './font-bonus-applies.webp';
import FontViewDepositBonus from './font-view-deposit-bonus.webp';
import FontWelcomeBonus from './font-welcome-bonus.webp';

export {
	Bonus10PFont,
	Bonus20PFont,
	DepositFont,
	FirstDepositBonusFont,
	Font25Dollar,
	Font25Euro,
	Font25Pounds,
	Font50Dollar,
	Font50Euro,
	Font50Pounds,
	Font100Dollar,
	Font100Euro,
	Font100Pounds,
	Font250Dollar,
	Font250Euro,
	Font250Pounds,
	Font500Dollar,
	Font500Euro,
	Font500Pounds,
	Font1000Dollar,
	Font1000Euro,
	Font1000Pounds,
	FontAvailableBonus,
	FontBonusApplies,
	FontViewDepositBonus,
	FontWelcomeBonus,
	Secure3dBatch,
};

export const CURRENCY_IMAGES: Record<string, Record<number, string>> = {
	USD: {
		25: Font25Dollar,
		50: Font50Dollar,
		100: Font100Dollar,
		250: Font250Dollar,
		500: Font500Dollar,
		1000: Font1000Dollar,
	},
	EUR: {
		25: Font25Euro,
		50: Font50Euro,
		100: Font100Euro,
		250: Font250Euro,
		500: Font500Euro,
		1000: Font1000Euro,
	},
	GBP: {
		25: Font25Pounds,
		50: Font50Pounds,
		100: Font100Pounds,
		250: Font250Pounds,
		500: Font500Pounds,
		1000: Font1000Pounds,
	},
};
