import { gsap } from 'gsap';
import { useLayoutEffect } from 'react';

interface Props {
	ref: React.RefObject<HTMLDivElement>;
	animationTime?: number;
	trigger?: unknown[];
}

export function useFadeInAnimation({ ref, animationTime = 0.15, trigger }: Props) {
	useLayoutEffect(() => {
		if (ref.current) {
			const timeline = gsap.timeline();
			timeline.set(ref.current, { opacity: 0 });
			timeline.to(ref.current, {
				opacity: 1,
				duration: animationTime,
				ease: 'power2.in',
			});
		}
	}, [animationTime, ref, trigger]);
}
