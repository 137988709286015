import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ArrowDown19 } from 'assets/icons/ArrowDown19';
import { ArrowDown91 } from 'assets/icons/ArrowDown91';
import { CalendarSortAscendingIcon } from 'assets/icons/CalendarSortAscendingIcon';
import { DropdownOption } from 'components/common/mobile/Dropdowns/MobileDropdown';
import { useEffect, useState } from 'react';
import { BottomSheet } from 'react-spring-bottom-sheet';
import { shallow } from 'zustand/shallow';

import { InventoryOrderOptions } from '../../inventoryUI.interface';
import { useInventoryStore } from '../../store/InventoryStore';

const OPTIONS: DropdownOption[] = [
	{
		label: 'Newest',
		value: InventoryOrderOptions.NEWEST,
		icon: CalendarSortAscendingIcon,
	},
	{
		label: 'Price High',
		value: InventoryOrderOptions.PRICE_HIGH,
		icon: ArrowDown91,
	},
	{
		label: 'Oldest',
		value: InventoryOrderOptions.OLDEST,
		icon: CalendarSortAscendingIcon,
	},
	{
		label: 'Price Low',
		value: InventoryOrderOptions.PRICE_LOW,
		icon: ArrowDown19,
	},
];

interface Props {
	isVisible: boolean;
	onClose: () => void;
}

const SNAP_POINT = 320;

export function SortOptionsSlideupSheet({ isVisible, onClose }: Props) {
	const [showBackground, setShowBackground] = useState(false);

	const { currentSortByOption, setCurrentSortByOption } = useInventoryStore(
		(state) => ({
			currentSortByOption: state.currentSortByOption,
			setCurrentSortByOption: state.setCurrentSortByOption,
		}),
		shallow
	);

	useEffect(() => {
		if (isVisible) {
			setShowBackground(true);
		} else {
			window.setTimeout(() => setShowBackground(false), 100);
		}
	}, [isVisible]);

	function handleOptionClick(option: DropdownOption) {
		setCurrentSortByOption(option);
		onClose();
	}

	return (
		<div className={`fixed z-40 inset-0 w-screen h-screen ${showBackground ? 'block' : 'hidden'}`}>
			<BottomSheet
				style={{ zIndex: 40, position: 'absolute' }}
				open={isVisible}
				onDismiss={onClose}
				snapPoints={() => SNAP_POINT}
				header={<div className="h-[7px] "></div>}
			>
				<div className="w-screen h-full rounded-[12px_12px_0_0] flex flex-col gap-y-[25px] pt-[20px]">
					<h1 className="text-[22px] text-white font-bold px-mobile-side-padding">Sort By</h1>

					<div className="flex flex-col justify-between flex-grow pb-[15px] max-full overflow-y-auto">
						{OPTIONS?.map((option, index) => (
							<button
								key={index}
								onClick={() => handleOptionClick(option)}
								className="z-10 flex items-center justify-between w-full text-white py-[10px] px-mobile-side-padding text-[17px]"
							>
								<div className="flex items-center space-x-[30px] ">
									{option.icon && <option.icon />}
									<span className="font-bold">{option.label}</span>
								</div>
								{option.value === currentSortByOption.value && <FontAwesomeIcon icon={faCheck} className="" />}
							</button>
						))}
					</div>
				</div>
			</BottomSheet>
		</div>
	);
}
