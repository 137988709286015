import { shallow } from 'zustand/shallow';
import { createWithEqualityFn } from 'zustand/traditional';

interface HistoryState {
	history: string[];
	addToHistory: (path: string) => void;
	clearHistory: () => void;

	// Virtual Fields
	lastHistory: () => string | null; // Returns last entry
	getLastHistoryExcluding: (exclude: string) => string | null; // Last entry excluding specific string
}

export const useHistoryStore = createWithEqualityFn<HistoryState>(
	(set, get) => ({
		history: [],

		// Add new path to history
		addToHistory: (path) =>
			set((state) => {
				const MAX_HISTORY = 100;
				const updatedHistory = [...state.history, path];

				// Trim history size
				if (updatedHistory.length > MAX_HISTORY) {
					updatedHistory.shift();
				}

				return { history: updatedHistory };
			}),

		// Clear all history
		clearHistory: () => set({ history: [] }),

		// Virtual Field: Last history entry
		lastHistory: () => {
			const { history } = get();
			return history.length > 0 ? history[history.length - 1] : null;
		},

		// Virtual Field: Last history excluding specific string
		getLastHistoryExcluding: (exclude) => {
			const { history } = get();
			for (let i = history.length - 1; i >= 0; i--) {
				if (!history[i].includes(exclude)) {
					return history[i];
				}
			}
			return null; // No match found
		},
	}),
	shallow // Optimizes performance by using shallow equality
);
