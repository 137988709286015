import {
	ApplePay,
	Bitcoin,
	CreditCardPaymentGroup,
	Etherium,
	GooglePay,
	KlarnaBadge,
	Polygon,
	USDCoin,
} from 'assets/images';
import { PrimaryBtn } from 'components/common/buttons/PrimaryBtn';
import { PaymentMethod } from 'pages/TransactionManager/transaction.interface';
import { isKlarnaAvailable } from 'pages/TransactionManager/transaction.utils';
import { useFormContext } from 'react-hook-form';

import { FormData } from '../DepositPopup';

const buttonConfigs = [
	{
		images: [
			{ src: ApplePay, alt: 'Apple Pay', height: 20 },
			{ src: GooglePay, alt: 'Google Pay', height: 20 },
		],
		paymentMethod: PaymentMethod.APPLE_GOOGLE_PAY,
	},
	{
		images: [{ src: CreditCardPaymentGroup, alt: 'Visa', height: 23 }],
		paymentMethod: PaymentMethod.CREDIT_CARD,
	},
	{
		images: [{ src: KlarnaBadge, alt: 'Klarna', height: 26 }],
		paymentMethod: PaymentMethod.KLARNA,
	},
	{
		images: [
			{ src: Bitcoin, alt: 'Bitcoin', height: 20 },
			{ src: Etherium, alt: 'Etherium', height: 20 },
			{ src: USDCoin, alt: 'USD Coin', height: 20 },
			{ src: Polygon, alt: 'Polygon', height: 20 },
		],
		paymentMethod: PaymentMethod.CRYPTO,
	},

	//{ images: [{ src: BlueMFont, alt: 'M', height: 20 }], text: 'Gift Card', paymentMethod: PaymentMethod.GIFT_CARD },
];

export function PaymentMethodsList() {
	const { setValue, watch } = useFormContext<FormData>();

	const selectedPaymentMethod = watch('paymentMethod');
	const selectedCurrency = watch('currency');

	const handleClick = (paymentMethod: PaymentMethod) => {
		setValue('paymentMethod', paymentMethod);
	};

	// Filter Klarna if the user's country is not supported
	const filteredButtonConfigs = buttonConfigs.filter((method) => {
		if (method.paymentMethod === PaymentMethod.KLARNA) {
			return isKlarnaAvailable(selectedCurrency);
		}
		return true;
	});

	return (
		<div className="sticky top-0 flex flex-col gap-y-[12px] min-w-[191px] max-w-[191px] pl-[3px] pt-[3px]">
			{filteredButtonConfigs.map((btnConfig, index) => (
				<PrimaryBtn
					key={index}
					renderEllipse={false}
					onClick={() => handleClick(btnConfig.paymentMethod)}
					innerContentStyle={{
						boxShadow:
							selectedPaymentMethod === btnConfig.paymentMethod ? 'inset 0px -1px 7px rgba(0, 0, 0, 0.7)' : undefined,
					}}
					content={
						<div className="flex items-center gap-[10px]">
							{btnConfig.images.map((img, index) => (
								<img
									key={index}
									style={{ height: `${img.height}px` }}
									src={img.src}
									className="h-[20px] w-auto"
									alt={img.alt}
								/>
							))}
							{/*btnConfig.text && <span className="text-white font-semibold text-[16px]">{btnConfig.text}</span>*/}
						</div>
					}
				/>
			))}
		</div>
	);
}
