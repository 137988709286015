import axios from 'axios';
import { useMutation } from 'react-query';

import { API_DOMAIN } from '../../../constants';

async function requestCodeRedeem(code: string): Promise<ReturnType> {
	try {
		const response = await axios.post(
			`${API_DOMAIN.mrlootBackend}/api/user-gift/redeem-code`,
			{ code },
			{ withCredentials: true }
		);

		if (response.data.status === 'success') {
			return response.data.data; // Ensure sessionId exists
		}

		throw new Error(response.data.message || 'Unable to create a checkout session. Please try again later.');
	} catch (err) {
		console.log(err);
		if (axios.isAxiosError(err)) {
			const message =
				err.response?.data?.message ||
				err.message ||
				'Something went wrong. Please check your connection and try again.';
			throw new Error(message);
		}
		throw new Error('An unexpected error occurred. Please try again.');
	}
}

interface CallbackProps {
	onSuccess?: (sessionId: string) => void;
	onError?: (error: string) => void;
}

interface MutateProps {
	code: string;
}

interface ReturnType {
	amount: number;
}

// Custom Hook
export function useGiftCodeRedeem({ onError, onSuccess }: CallbackProps = {}) {
	return useMutation(({ code }: MutateProps) => requestCodeRedeem(code), {
		onSuccess: (data: ReturnType) => {
			data.amount && onSuccess?.(`$${data.amount} was successfully added to your balance`);
		},
		onError: (error: Error) => {
			onError?.(error.message);
		},
	});
}
