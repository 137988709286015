import { CurrencyOptions } from 'store/useAppStore';

import { dollarToLootieExchangeRate } from '../constants';

/**
 * Converts a dollar amount to looties and formats the result for display.
 *
 * This function calculates the equivalent amount of looties for a given dollar amount,
 * assuming one dollar is worth four looties. The resulting lootie amount is then formatted
 * as a string in French locale format (e.g., "1,234" for one thousand two hundred and thirty-four).
 * This format may include grouping delimiters according to the locale.
 *
 * @param {number} dollarAmount - The amount in dollars to be converted.
 * @returns {string} The formatted string representing the equivalent amount of looties.
 */

export const convertToLootiesAndFormat = (dollarAmount: number) => {
	const lootieAmount = Math.floor(dollarAmount * dollarToLootieExchangeRate);
	return lootieAmount.toLocaleString('Fr-fr');
};

const MILLION = 1_000_000;
const HUNDRED_THOUSAND = 100_000;
const TEN_THOUSAND = 10_000;
const THOUSAND = 1_000;

type AbbreviationOption = '10K' | '100K' | '1M' | 'default' | 'none';

export const convertToLootiesAndFormatAsAbbreviated = (
	dollarAmount: number,
	abbreviationOption: AbbreviationOption = 'default'
): string => {
	const lootieAmount = Math.floor(dollarAmount * dollarToLootieExchangeRate);

	if (abbreviationOption === 'none') {
		return lootieAmount.toLocaleString('fr-FR');
	}

	// Determine the minimum threshold based on the abbreviation option
	let threshold;
	switch (abbreviationOption) {
		case '1M':
			threshold = MILLION;
			break;
		case '100K':
			threshold = HUNDRED_THOUSAND;
			break;
		case '10K':
			threshold = TEN_THOUSAND;
			break;
		default:
			threshold = 0; // No threshold, abbreviate based on the highest applicable category
	}

	// Apply abbreviation based on the determined threshold
	if (lootieAmount >= threshold) {
		if (lootieAmount >= MILLION) {
			return formatAbbreviatedNumber(lootieAmount, MILLION, 'M');
		} else if (lootieAmount >= HUNDRED_THOUSAND) {
			return formatAbbreviatedNumber(lootieAmount, THOUSAND, 'K');
		} else if (lootieAmount >= TEN_THOUSAND) {
			return formatAbbreviatedNumber(lootieAmount, THOUSAND, 'K');
		}
	}

	// If the lootie amount is less than the threshold or no abbreviation is applicable
	return lootieAmount.toLocaleString('fr-FR');
};

export const displayUsdAmountInCurrency = (amount: number, currency: CurrencyOptions) => {
	if (currency === CurrencyOptions.US_DOLLAR) {
		return amount;
	}
	return convertToLooties(amount);
};

export const formatAmountAsAbbreviated = (
	dollarAmount: number,
	abbreviationOption: AbbreviationOption = 'default',
	maxFractionDigitsForSmallNumbers = 4,
	minFractionDigits = 2
): string => {
	// Determine the minimum threshold based on the abbreviation option
	let threshold;

	if (abbreviationOption === 'none') {
		return dollarAmount
			.toLocaleString('en-US', {
				minimumFractionDigits: minFractionDigits,
				maximumFractionDigits: maxFractionDigitsForSmallNumbers,
			})
			.replace(/,/g, ' ') // Replace comma with space
			.replace(
				new RegExp(`(\\d+\\.\\d{${minFractionDigits}})0+$`), // Match the required digits and trailing zeros
				'$1' // Replace with the captured part (removing trailing zeros)
			);
	}

	switch (abbreviationOption) {
		case '1M':
			threshold = MILLION;
			break;
		case '100K':
			threshold = HUNDRED_THOUSAND;
			break;
		case '10K':
			threshold = TEN_THOUSAND;
			break;
		default:
			threshold = 0; // No threshold, abbreviate based on the highest applicable category
	}

	// Apply abbreviation based on the determined threshold
	if (dollarAmount >= threshold) {
		if (dollarAmount >= MILLION) {
			return formatAbbreviatedNumber(dollarAmount, MILLION, 'M', 0);
		} else if (dollarAmount >= HUNDRED_THOUSAND) {
			return formatAbbreviatedNumber(dollarAmount, THOUSAND, 'K', 0);
		} else if (dollarAmount >= TEN_THOUSAND) {
			return formatAbbreviatedNumber(dollarAmount, THOUSAND, 'K', 0);
		}
	}

	// Formatting for small numbers
	const isSmallNumber = dollarAmount < 1;

	return dollarAmount
		.toLocaleString('en-US', {
			minimumFractionDigits: minFractionDigits,
			maximumFractionDigits: isSmallNumber ? maxFractionDigitsForSmallNumbers : minFractionDigits,
		})
		.replace(/,/g, ' ') // Replace comma with space
		.replace(
			new RegExp(`(\\d+\\.\\d{${minFractionDigits}})0+$`), // Match the required digits and trailing zeros
			'$1' // Replace with the captured part (removing trailing zeros)
		);
};

function formatAbbreviatedNumber(amount: number, divisor: number, suffix: string, minFractionDigits?: number): string {
	const dividedAmount = amount / divisor;
	const roundedAmount = Math.floor(dividedAmount * 10) / 10; // Multiply and divide to keep one decimal place
	return Math.floor(roundedAmount) !== roundedAmount
		? `${roundedAmount.toFixed(Math.max(minFractionDigits ?? 0, 1))}${suffix}` // Ensure at least `minFractionDigits`
		: `${roundedAmount.toFixed(minFractionDigits)}${suffix}`;
}

/**
 * Converts a dollar amount to an integer number of looties.
 *
 * This function calculates the equivalent lootie amount for a given dollar amount,
 * rounding up to the nearest whole number, with the conversion rate being one dollar to four looties.
 *
 * @param {number} dollarAmount - The dollar amount to be converted to looties.
 * @returns {number} The lootie amount as a whole number.
 */
export const convertToLooties = (dollarAmount: number) => {
	const lootieAmount = Math.floor(dollarAmount * dollarToLootieExchangeRate);
	return lootieAmount;
};

export const convertFromLooties = (lootieAmount: number): number => {
	// Use the inverse of the exchange rate
	const dollarAmount = lootieAmount / dollarToLootieExchangeRate;
	return parseFloat(dollarAmount.toFixed(2)); // Round to 2 decimal places
};

/**
 * Formats a probability value as a percentage string with appropriate precision.
 *
 * This function takes a probability value (e.g., 0.12345) and converts it to a percentage
 * string with up to three decimal places. For probabilities less than 0.1%, it returns "<0.001%".
 * This ensures that very small probabilities are communicated in a clear and understandable way.
 *
 * @param {number} prob - The probability value to be formatted.
 * @returns {string} The formatted percentage string.
 */
export function formatProbability(prob: number) {
	const MIN_PERCENTAGE_FOR_TWO_DECIMALS = 0.01;
	const MIN_PERCENTAGE_FOR_THREE_DECIMALS = 0.001;
	const TWO_DECIMALS = 2;
	const THREE_DECIMALS = 3;
	const MIN_DISPLAYABLE_PERCENTAGE = '<0.001%';
	const MAX_PERCENTAGE = 99.99;

	const percentage = prob * 100;

	if (percentage >= MAX_PERCENTAGE) {
		return MAX_PERCENTAGE + '%';
	} else if (percentage >= MIN_PERCENTAGE_FOR_TWO_DECIMALS) {
		return percentage.toFixed(TWO_DECIMALS) + '%';
	} else if (percentage >= MIN_PERCENTAGE_FOR_THREE_DECIMALS) {
		return percentage.toFixed(THREE_DECIMALS) + '%';
	}

	return MIN_DISPLAYABLE_PERCENTAGE;
}

interface ConversionRatesProps {
	bitcoin: number;
	etherium: number;
	solana: number;
	dogecoin: number;
}

export const convertCurrency = (
	amountInDollars: number,
	targetCurrency: CurrencyOptions,
	conversionRates?: ConversionRatesProps
) => {
	const convertCrypto = (amount: number, rate?: number) => {
		if (!rate) {
			return 0;
		}
		return amount / rate;
	};

	switch (targetCurrency) {
		case CurrencyOptions.LOOTIES:
			return convertToLooties(amountInDollars);
		case CurrencyOptions.US_DOLLAR:
			return amountInDollars; // Default to USD
	}

	if (!conversionRates) {
		return 0;
	}

	switch (targetCurrency) {
		case CurrencyOptions.BITCOIN:
			return convertCrypto(amountInDollars, conversionRates.bitcoin);
		case CurrencyOptions.ETHERIUM:
			return convertCrypto(amountInDollars, conversionRates.etherium);
		case CurrencyOptions.SOLANA:
			return convertCrypto(amountInDollars, conversionRates.solana);
		case CurrencyOptions.DOGECOIN:
			return convertCrypto(amountInDollars, conversionRates.dogecoin);
		default:
			return amountInDollars; // Default to USD
	}
};

export const toValidNumber = (value: string | number): number | null => {
	const parsed = Number(value);
	return isNaN(parsed) ? null : parsed;
};

/**
 * Converts an amount in a given currency **back to US dollars**.
 *
 * ## Important Notes:
 * - Conversion rates provided are **USD to target currency**.
 * - To convert back to USD, the function takes the **inverse** of the rate (1 / rate).
 *
 * @param amountInCurrency - The amount in the source currency to be converted.
 * @param sourceCurrency - The currency of the provided amount.
 * @param conversionRates - An object containing USD to target currency conversion rates.
 * @returns The equivalent amount in US dollars, rounded to 2 decimal places.
 */
export const convertFromCurrencyToDollars = (
	amountInCurrency: number,
	sourceCurrency: CurrencyOptions,
	conversionRates?: ConversionRatesProps
): number => {
	if (!conversionRates) {
		return 0; // Return 0 if conversion rates are missing
	}

	const convertToUSD = (amount: number, rate?: number): number => {
		if (!rate || rate === 0) {
			return 0; // Avoid division by zero or invalid rates
		}
		return amount / rate; // Take the inverse of the conversion rate
	};

	switch (sourceCurrency) {
		case CurrencyOptions.US_DOLLAR:
			return amountInCurrency; // Already in USD
		case CurrencyOptions.LOOTIES:
			return convertFromLooties(amountInCurrency); // Use a function to convert Looties to USD
		case CurrencyOptions.BITCOIN:
			return convertToUSD(amountInCurrency, conversionRates.bitcoin);
		case CurrencyOptions.ETHERIUM:
			return convertToUSD(amountInCurrency, conversionRates.etherium);
		case CurrencyOptions.SOLANA:
			return convertToUSD(amountInCurrency, conversionRates.solana);
		case CurrencyOptions.DOGECOIN:
			return convertToUSD(amountInCurrency, conversionRates.dogecoin);
		default:
			return 0; // Default fallback for unsupported currencies
	}
};
