import { FontClaim } from 'assets/images';
import { PrimaryBtn } from 'components/common/buttons/PrimaryBtn';
import { WithPrimaryPopup } from 'components/common/popups/WithPrimaryPopup';
import { useUnclaimedUserGifts } from 'hooks/data/userGifts/useUnclaimedUserGifts';
import { useBoxOpeningStoreDesktop } from 'pages/OpenBox/store/useBoxOpeningStoreDesktop';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { shallow } from 'zustand/shallow';

import { CLOUDFRONT_IMG_URL } from '../../../constants';
import { useDelayedUnblock } from '../hooks/useDelayedUnblock';

export function DesktopUserGiftPopup() {
	const [isVisible, setIsVisible] = useState(false);

	const { data: unclaimedUserGifts } = useUnclaimedUserGifts();
	const isBoxOpening = useBoxOpeningStoreDesktop((state) => state.isBoxOpening, shallow);

	useEffect(() => {
		// Check if there are any unclaimed gifts available
		if (unclaimedUserGifts && unclaimedUserGifts.length > 0) {
			const firstUnclaimedGift = unclaimedUserGifts[0];

			// Gifts are pre-sorted such that the first item with showPopup = true appears first.
			// If the first item does not have showPopup = true, then no subsequent items will either.
			if (firstUnclaimedGift.showPopup) {
				setIsVisible(true);
			}
		}
	}, [unclaimedUserGifts]);

	const navigate = useNavigate();
	const location = useLocation();

	const handlePopupClose = useCallback(() => {
		setIsVisible(false);
	}, [setIsVisible]);

	const handleClaim = useCallback(() => {
		setIsVisible(false);
		navigate('/inventory?view=winboxes');
	}, [navigate]);

	const isPopupUnblocked = useDelayedUnblock({ isBlocked: location.pathname.includes('auth') || isBoxOpening });

	const isComponentVisible = useMemo(() => isVisible && isPopupUnblocked, [isPopupUnblocked, isVisible]);

	return (
		<WithPrimaryPopup
			onClose={handlePopupClose}
			isExternallyVisible={isComponentVisible}
			useAnimation={true}
			isDesktop={true}
			childrenWrapperStyle={{ paddingBottom: '15px' }}
		>
			<div className="flex flex-col items-center justify-center w-[580px] lg:w-[630px] xl:w-[820px] gap-y-[25px] xl:gap-y-[35px] xl:pb-[10px]">
				<div className="flex flex-col gap-y-[5px]">
					<h1 className="font-inter font-bold text-[24px] lg:text-[26px] xl:text-[29px] text-center w-full ">
						New Year, New Loot Awaits! 🎁
					</h1>
					<span className="text-white/70 font-semibold font-inter text-[15px] lg:text-[16px] xl:text-[18px] text-center leading-[15px] lg:leading-[16px] xl:leading-[18px]">
						As a valued customer, you’re receiving a FREE "New Year New Loot" WinBox
					</span>
				</div>
				<div className="relative aspect-[1.5/1] w-[60%] lg:w-[55%] rounded-[18px] outline outline-[1px] outline-white overflow-hidden mx-auto">
					<img
						src={`${CLOUDFRONT_IMG_URL}/one-time-use/new-year-free-box.webp`}
						alt=""
						className="h-full w-full object-cover"
					/>
				</div>
				<div className="flex flex-col items-center gap-y-[12px] w-[250px] lg:w-[280px] xl:w-[320px]">
					<PrimaryBtn
						style={{ height: 'fit-content', borderRadius: '24px' }}
						innerContentStyle={{ borderRadius: '24px' }}
						content={
							<div className="h-[47px] lg:h-[48px] xl:h-[54px] flex items-center">
								<img
									src={FontClaim}
									alt="claim your bonus now!"
									className="h-[22px] lg:h-[23px] xl:h-[27px] object-contain"
								/>
							</div>
						}
						onClick={handleClaim}
					/>
				</div>
			</div>
		</WithPrimaryPopup>
	);
}
