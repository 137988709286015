import { Icon } from '@iconify/react';
import { useCountdownTimer } from 'hooks/utility/useCountdownTimer';
import { BonusBundle } from 'pages/TransactionManager/deposit.interface';
import { Tooltip } from 'react-tooltip';
import { getXTimesImgByNumber } from 'utils';

import { CLOUDFRONT_IMG_URL } from '../../../../constants';

function ExpirationCountDown({ expiresAt }: { expiresAt: Date }) {
	const timeLeft = useCountdownTimer(expiresAt, {
		days: true,
		hours: true,
		minutes: true,
		seconds: true,
	});

	return <span className="text-[#51ff94] font-semibold font-inter text-[13px] ms:text-[15px]">{timeLeft}</span>;
}

interface BonusCardProps {
	bonus: BonusBundle;
}

export function BonusCard({ bonus }: BonusCardProps) {
	if (!bonus.boxes || bonus.boxes.length === 0) {
		return null;
	}

	const firstBox = bonus.boxes[0];
	const heading = `${bonus.boxes.length} FREE WinBox${bonus.boxes.length > 1 ? 'es' : ''}`;

	return (
		<div
			className={`relative pb-[10px] border-[#f0f8ff] bg-white/40 items-center justify-between gap-x-[15px] pt-[5px] px-[12px] flex flex-col border-[2px] w-full aspect-[1/0.62] max-h-[265px] max-w-[450px] overflow-hidden rounded-[20px] `}
		>
			<div className="z-10 w-full flex items-center justify-between">
				{bonus.expiresAt && <ExpirationCountDown expiresAt={new Date(bonus.expiresAt)} />}
				<div className="ml-auto">
					<span
						data-tooltip-id="bonus-tooltip"
						data-tooltip-content="Receive free WinBoxes with your first deposit. Higher deposits grant more bonus boxes. No additional steps—simply deposit, open your WinBoxes, and ship any winnings home like a regular purchase."
						className="cursor-pointer"
					>
						<Icon icon="mdi:info-circle-outline" height={22} width={22} className="text-white" />
					</span>
					<Tooltip
						id="bonus-tooltip"
						place="top"
						style={{
							maxWidth: '250px', // Set maximum width for the tooltip
							whiteSpace: 'normal', // Allow text to wrap
							wordWrap: 'break-word', // Break long words if needed
						}}
					/>
				</div>
			</div>

			<div className="grid grid-cols-[1.1fr_1fr] items-center justify-items-center gap-x-[20px] w-full pb-[5px]">
				<div className="relative w-full flex items-center justify-center">
					<img
						src={`${CLOUDFRONT_IMG_URL}/${firstBox?.boxImgUrl}`}
						alt={firstBox?.boxName}
						className="w-[55%] aspect-boxCard object-cover rounded-[8px]"
					/>
					<img
						src={getXTimesImgByNumber(firstBox.qty)}
						className="absolute left-1/2 bottom-0 -translate-x-1/2 translate-y-[40%] h-[40px] w-auto object-contain"
						alt={`${firstBox?.qty}x`}
					/>
				</div>
				<div className="flex flex-col gap-y-[8px] ms:gap-y-[18px]">
					<div className="rounded-[8px] bg-white text-black font-bold font-inter text-[14px] ms:text-[17px] px-[9px] ms:px-[11px] py-[4px] ms:py-[6px] max-w-fit">
						{heading}
					</div>
					<span className="font-semibold text-[14px] ms:text-[17px] text-[rgba(255,255,255,0.7)] leading-6">
						{bonus.description}
					</span>
				</div>
			</div>
			<span className="text-[#51ff94] font-bold text-center text-[13px] ms:text-[15px]">{bonus.type}</span>
		</div>
	);
}
