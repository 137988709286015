import { useQuery } from 'react-query';

import { QUERY_CACHE_CONFIG } from '../../constants';

// Constants for mapping locales and country codes
const LOCALE_TO_CURRENCY: Record<string, string> = {
	de: 'EUR', // Germany, Austria
	fr: 'EUR', // France, Belgium, Luxembourg
	it: 'EUR', // Italy
	es: 'EUR', // Spain
	pt: 'EUR', // Portugal
	nl: 'EUR', // Netherlands, Belgium
	et: 'EUR', // Estonia
	fi: 'EUR', // Finland
	sv: 'EUR', // Swedish minority in Finland
	el: 'EUR', // Greece, Cyprus
	lv: 'EUR', // Latvia
	lt: 'EUR', // Lithuania
	sk: 'EUR', // Slovakia
	sl: 'EUR', // Slovenia
	mt: 'EUR', // Malta
	lb: 'EUR', // Luxembourg
	en: 'EUR', // Ireland, Malta
	'en-GB': 'GBP', // British English
};

const COUNTRY_TO_CURRENCY: Record<string, string> = {
	AT: 'EUR', // Austria
	BE: 'EUR', // Belgium
	CY: 'EUR', // Cyprus
	EE: 'EUR', // Estonia
	FI: 'EUR', // Finland
	FR: 'EUR', // France
	DE: 'EUR', // Germany
	GR: 'EUR', // Greece
	IE: 'EUR', // Ireland
	IT: 'EUR', // Italy
	LV: 'EUR', // Latvia
	LT: 'EUR', // Lithuania
	LU: 'EUR', // Luxembourg
	MT: 'EUR', // Malta
	NL: 'EUR', // Netherlands
	PT: 'EUR', // Portugal
	SK: 'EUR', // Slovakia
	SI: 'EUR', // Slovenia
	ES: 'EUR', // Spain
	GB: 'GBP', // United Kingdom (non-Euro)
};

// Currency detection utility
const detectCurrency = async (): Promise<string> => {
	// Check browser locale first
	const locale = navigator.language || 'en-US';
	const baseLocale = locale.split('-')[0];
	if (LOCALE_TO_CURRENCY[locale] || LOCALE_TO_CURRENCY[baseLocale]) {
		return LOCALE_TO_CURRENCY[locale] || LOCALE_TO_CURRENCY[baseLocale];
	}

	// Fallback to IP geolocation
	try {
		const res = await fetch('https://ipapi.co/json/');
		const data = await res.json();

		return COUNTRY_TO_CURRENCY[data.country_code] || 'USD';
	} catch (error) {
		console.error('Currency detection failed:', error);
		return 'USD'; // Default fallback
	}
};

// Custom hook using useQuery
export const useCurrencyDetection = () => {
	return useQuery('currency', detectCurrency, {
		retry: 1, // Retry once if it fails
		cacheTime: QUERY_CACHE_CONFIG.veryLongTerm.cacheTime,
		staleTime: QUERY_CACHE_CONFIG.veryLongTerm.staleTime,
	});
};
