import { AmountDisplayWithCurrency } from 'components/common/AmountDisplayWithCurrency';
import { WonItem } from 'pages/OpenBox/boxOpening.interface';

interface Props {
	item: WonItem;
	pseudoImageRef: React.RefObject<HTMLDivElement>;
}

export function ItemInfoDisplay({ item }: Props) {
	return (
		<>
			<div className="">
				<h1 className="text-[28px] lg:text-[34px] leading-[34px] lg:leading-[40px] xl:text-[38px] xl:leading-[45px] line-clamp-2 font-bold text-center w-full sm:text-start">
					{item.name}
				</h1>
				{item.brandName && <h3 className="text-[20px]">{item.brandName}</h3>}
			</div>

			<div className="flex items-center space-x-3 text-[30px] lg:text-[35px] xl:text-[40px] font-bold">
				<AmountDisplayWithCurrency amount={item.price} imgStyle={{ height: '32px' }} useDivWrapper={false} />
			</div>
			<p className="text-[11px] lg:text-[13px] lgx:text-[14px] lgx:leading-[22px] xl:leading-[28px] w-full line-clamp-3 lgx:line-clamp-4">
				{item.description}
			</p>
		</>
	);
}
