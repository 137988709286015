import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faArrowDown19, faArrowDown91, faArrowDownAZ, faArrowDownZA } from '@fortawesome/free-solid-svg-icons';
import { Dropdown } from 'components';
import { useWindowDimensions } from 'hooks/utility/layout-measurements/useWindowDimensions';
import { useInventoryStore } from 'pages/inventory/store/InventoryStore';
import { useRef } from 'react';

import { screens } from '../../../../../constants';
import { BoxViewSwitch } from './BoxViewSwitch';
import { SearchBtn } from './SearchBtn';

const filterOptions: { name: string; icon: IconDefinition }[] = [
	{
		name: 'Price low',
		icon: faArrowDown19,
	},
	{
		name: 'Price high',
		icon: faArrowDown91,
	},
	{
		name: 'Newest',
		icon: faArrowDownAZ,
	},
	{
		name: 'Oldest',
		icon: faArrowDownZA,
	},
];

interface Props {
	selectedOrderOption: string;
	searchInput: string;

	onSelectedOrderOptionChange: (value: string) => void;
	onSearchInputChange: (value: string) => void;
}

export function Topbar({ selectedOrderOption, searchInput, onSelectedOrderOptionChange, onSearchInputChange }: Props) {
	const modeSwitchContainerRef = useRef<HTMLInputElement>(null);
	const orderDropdownRef = useRef<HTMLInputElement>(null);

	const { isBoxView, toogleBoxView } = useInventoryStore((state) => ({
		isBoxView: state.isBoxView,
		toogleBoxView: state.toogleBoxView,
	}));

	const { screenWidth } = useWindowDimensions();

	return (
		<div className="relative bg-secondary-gradient border-[2px] border-darkBlue z-30 w-full h-[38px] flex items-center justify-between outline outline-[2px] outline-white rounded-[20px] box-border">
			<SearchBtn searchInput={searchInput} onSearchInputChange={onSearchInputChange} />

			{screenWidth > screens.xs.width && (
				<BoxViewSwitch containerRef={modeSwitchContainerRef} isBoxView={isBoxView} onChange={toogleBoxView} />
			)}

			<div
				ref={orderDropdownRef}
				className="z-30 h-[28px] box-border absolute top-0 right-0 translate-y-[3px] translate-x-[-4px]"
			>
				<Dropdown
					options={filterOptions}
					selectedOption={selectedOrderOption}
					onOptionSelect={onSelectedOrderOptionChange}
				/>
			</div>
		</div>
	);
}
