import axios from 'axios';
import { useQuery } from 'react-query';

import { API_DOMAIN } from '../../../../constants';
import { LiveBoxWinning } from '../../interfaces/LiveDropsInterface';

const QUERY_LOOKBACK_WINDOW = 120000; // 120 seconds

// Fetch drops with optional "after" timestamp
async function fetchLiveDrops(refetchInterval: number, limit = 14): Promise<LiveBoxWinning[]> {
	// Add a time offset to simulate past drops and align with animations

	const queryStartTime = new Date(Date.now() - QUERY_LOOKBACK_WINDOW).toISOString();
	const visibleCutoffTime = new Date(Date.now() - refetchInterval).toISOString();

	const response = await axios.get(`${API_DOMAIN.mrlootBackend}/api/boxes/live-drops`, {
		params: { after: queryStartTime, limit, limitUntil: visibleCutoffTime },
	});
	return response.data.data;
}

interface Props {
	refetchInterval: number;
	limit?: number;
}

export function useLiveBoxDrops({ refetchInterval, limit }: Props) {
	const { data, error, isLoading } = useQuery<LiveBoxWinning[]>(
		['liveDrops', limit, refetchInterval],
		() => fetchLiveDrops(refetchInterval, limit), // Fetch with synchronized delay
		{
			refetchInterval: refetchInterval,
			refetchOnWindowFocus: true,
		}
	);

	return {
		drops: data || [],
		error,
		isLoading,
	};
}
