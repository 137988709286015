/* eslint-disable max-lines-per-function */
/* eslint-disable no-magic-numbers */
import 'rc-slider/assets/index.css';

import { faMinus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Bitcoin, Coin, CryptoDogecoin, CryptoSolana, DollarSign, Etherium } from 'assets/images';
import { useConversionRates } from 'hooks/currency/useConversionRates';
import Slider from 'rc-slider';
import { useEffect, useState } from 'react';
import { CurrencyOptions, useAppStore } from 'store/useAppStore';
import { convertCurrency, convertFromCurrencyToDollars } from 'utils';
import { shallow } from 'zustand/shallow';

import { COLORS } from '../../../constants';

const MIN_AMOUNT_PRICE_FILTER = 1;
const MAX_AMOUNT_PRICE_FILTER = 100000;

const renderCurrencyImage = (currency: CurrencyOptions) => {
	switch (currency) {
		case CurrencyOptions.LOOTIES:
			return <img src={Coin} alt="lootie coin" className="h-[22px] object-contain" />;
		case CurrencyOptions.US_DOLLAR:
			return <img src={DollarSign} alt="dollar sign" className="h-[22px] object-contain" />;
		case CurrencyOptions.BITCOIN:
			return <img src={Bitcoin} alt="bitcoin" className="h-[22px] object-contain" />;
		case CurrencyOptions.ETHERIUM:
			return <img src={Etherium} alt="etherium" className="h-[22px] object-contain" />;
		case CurrencyOptions.SOLANA:
			return <img src={CryptoSolana} alt="solana" className="h-[22px] object-contain" />;
		case CurrencyOptions.DOGECOIN:
			return <img src={CryptoDogecoin} alt="dogecoin" className="h-[22px] object-contain" />;
		default:
			return <img src={DollarSign} alt="dollar sign" className="h-[22px] object-contain" />;
	}
};

const getStepSize = (selectedCurrency: CurrencyOptions): number => {
	switch (selectedCurrency) {
		case CurrencyOptions.BITCOIN:
			return 0.00001; // Extra precision for Bitcoin
		case CurrencyOptions.ETHERIUM:
			return 0.0001; // Smaller steps for Ethereum
		case CurrencyOptions.SOLANA:
			return 0.01;
		case CurrencyOptions.LOOTIES:
		case CurrencyOptions.DOGECOIN:
		case CurrencyOptions.US_DOLLAR:
			return 1; // No decimals for Dollar and Lootie
		default:
			return 1;
	}
};

// Dynamically calculate phase boundaries based on currency range
const getPhases = (min: number, max: number) => {
	const rangeSize = max - min;
	return {
		phase1: min + rangeSize * 0.0005, // Slower: 0.01% of range
		phase2: min + rangeSize * 0.005, // Medium-slow: 5% of range
		phase3: min + rangeSize * 0.25, // Medium-fast: 25% of range
	};
};

// Map actual value back to slider position
const valueToSlider = (value: number, min: number, max: number): number => {
	const { phase1, phase2, phase3 } = getPhases(min, max);
	if (value <= phase1) {
		return ((value - min) / (phase1 - min)) * 25;
	} else if (value <= phase2) {
		return 25 + ((value - phase1) / (phase2 - phase1)) * 25;
	} else if (value <= phase3) {
		return 50 + ((value - phase2) / (phase3 - phase2)) * 25;
	}
	return 75 + ((value - phase3) / (max - phase3)) * 25;
};

const sliderToValue = (position: number, min: number, max: number, selectedCurrency: CurrencyOptions): number => {
	const { phase1, phase2, phase3 } = getPhases(min, max);
	let result;

	if (position <= 25) {
		result = min + (position / 25) * (phase1 - min);
	} else if (position <= 50) {
		result = phase1 + ((position - 25) / 25) * (phase2 - phase1);
	} else if (position <= 75) {
		result = phase2 + ((position - 50) / 25) * (phase3 - phase2);
	} else {
		result = phase3 + ((position - 75) / 25) * (max - phase3);
	}

	const step = getStepSize(selectedCurrency);

	// Round based on step size
	if (step >= 1) {
		return Math.round(result); // No decimals
	}
	return parseFloat(result.toFixed(5)); // Ensure small step precision
};

export interface AmountRangeFilterProps {
	defaultMinValue?: number;
	defaultMaxValue?: number;
	currency: CurrencyOptions;
	onMinPriceChange: (value: number) => void;
	onMaxPriceChange: (value: number) => void;
}

export function AmountRangeFilter({
	currency,
	onMaxPriceChange,
	onMinPriceChange,
	defaultMaxValue,
	defaultMinValue,
}: AmountRangeFilterProps) {
	const [range, setRange] = useState<[number, number]>([
		defaultMinValue ?? MIN_AMOUNT_PRICE_FILTER,
		defaultMaxValue ?? MAX_AMOUNT_PRICE_FILTER,
	]);
	const [rangeStartInput, setRangeStartInput] = useState('');
	const [rangeEndInput, setRangeEndInput] = useState('');

	const [min, setMin] = useState(MIN_AMOUNT_PRICE_FILTER);
	const [max, setMax] = useState(MAX_AMOUNT_PRICE_FILTER);

	const selectedCurrency = useAppStore((state) => state.selectedCurrency, shallow);
	const { data: cryptoConversionRates } = useConversionRates();

	// Update min and max based on selected currency and conversion rates
	useEffect(() => {
		if (cryptoConversionRates) {
			let newMin = MIN_AMOUNT_PRICE_FILTER;
			let rangeStart = defaultMinValue ?? MIN_AMOUNT_PRICE_FILTER;

			if (
				selectedCurrency === CurrencyOptions.BITCOIN ||
				selectedCurrency === CurrencyOptions.SOLANA ||
				selectedCurrency === CurrencyOptions.ETHERIUM
			) {
				newMin = Math.max(0.00001, convertCurrency(MIN_AMOUNT_PRICE_FILTER, selectedCurrency, cryptoConversionRates));
				rangeStart = Math.max(
					0.00001,
					convertCurrency(defaultMinValue ?? MIN_AMOUNT_PRICE_FILTER, selectedCurrency, cryptoConversionRates)
				);
			}

			setMin(newMin);
			const newMax = Math.max(1, convertCurrency(MAX_AMOUNT_PRICE_FILTER, selectedCurrency, cryptoConversionRates));
			const rangeEnd = Math.max(
				1,
				convertCurrency(defaultMaxValue ?? MAX_AMOUNT_PRICE_FILTER, selectedCurrency, cryptoConversionRates)
			);
			setMax(newMax);

			setRange([rangeStart, rangeEnd]);
			setRangeStartInput(rangeStart.toString());
			setRangeEndInput(rangeEnd.toString());
		}
		// avoid circling dependency
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [cryptoConversionRates, selectedCurrency]);

	// Map slider position to actual value with dynamic phases

	// Handle range change event
	const handleRangeChange = (value: number | number[]) => {
		if (Array.isArray(value)) {
			const newMinValue = sliderToValue(value[0], min, max, selectedCurrency);
			const newMaxValue = sliderToValue(value[1], min, max, selectedCurrency);

			setRange([newMinValue, newMaxValue]);
			setRangeStartInput(newMinValue.toString());
			setRangeEndInput(newMaxValue.toString());

			onMinPriceChange(convertFromCurrencyToDollars(newMinValue, selectedCurrency, cryptoConversionRates));
			onMaxPriceChange(convertFromCurrencyToDollars(newMaxValue, selectedCurrency, cryptoConversionRates));
		}
	};

	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
		const numValue = parseFloat(e.target.value);
		const newValue = e.target.value;

		if (isNaN(numValue) && newValue.length > 0) {
			return;
		} // Ignore invalid inputs like letters

		if (index === 0) {
			setRangeStartInput(newValue);
		} else {
			setRangeEndInput(newValue);
		}
	};

	const handleInputBlur = (index: number) => {
		setRange((prevRange) => {
			// Get the new value based on index
			const newValue = index === 0 ? parseFloat(rangeStartInput) : parseFloat(rangeEndInput);

			// Check if the new value is a valid number
			const isValid = !isNaN(newValue);

			// Clone the range and apply logic
			const updatedRange = [...prevRange] as [number, number];

			if (isValid) {
				// If valid, apply the clamped value
				const clampedValue = Math.max(min, Math.min(max, newValue));
				updatedRange[index] = clampedValue;

				if (index === 0) {
					onMinPriceChange(convertFromCurrencyToDollars(clampedValue, selectedCurrency, cryptoConversionRates));
				} else {
					onMaxPriceChange(convertFromCurrencyToDollars(clampedValue, selectedCurrency, cryptoConversionRates));
				}
			} else {
				// If invalid, reset input to the current range value
				if (index === 0) {
					setRangeStartInput(prevRange[0].toString());
				} else {
					setRangeEndInput(prevRange[1].toString());
				}
			}

			return updatedRange;
		});
	};

	return (
		<div className="flex flex-col text-white gap-y-[25px]">
			{/* Display Actual Values */}
			<div className="flex justify-center gap-x-[20px] items-center text-sm">
				<div className="relative h-[60px] w-fit">
					<div className="absolute left-3 top-1/2 -translate-y-1/2">{renderCurrencyImage(currency)}</div>
					<input
						onChange={(e) => handleInputChange(e, 0)} // Handle start value
						onBlur={() => handleInputBlur(0)}
						value={rangeStartInput}
						className="h-[60px] w-[140px] text-[17px] rounded-[20px] border-[2px] border-white pl-[25px] pr-[12px] font-semibold text-center bg-transparent text-white outline-none"
					/>
				</div>
				<FontAwesomeIcon icon={faMinus} />
				<div className="relative h-[60px] w-fit">
					<div className="absolute left-3 top-1/2 -translate-y-1/2">{renderCurrencyImage(currency)}</div>
					<input
						onChange={(e) => handleInputChange(e, 1)} // Handle start value
						onBlur={() => handleInputBlur(1)}
						value={rangeEndInput}
						className="h-[60px] w-[140px] text-[17px] rounded-[20px] border-[2px] border-white pl-[25px] pr-[12px] font-semibold text-center bg-transparent text-white outline-none"
					/>
				</div>
			</div>

			{/* Dual Range Slider */}
			<div className="px-[15px]">
				<Slider
					range
					min={0}
					max={100}
					step={getStepSize(selectedCurrency)}
					value={[valueToSlider(range[0], min, max), valueToSlider(range[1], min, max)]}
					onChange={handleRangeChange}
					styles={{
						track: { background: COLORS.primaryGradient, height: '10px', border: '1px solid white' },
						rail: { backgroundColor: 'rgba(255,255,255,0.5)', height: '10px' },
						handle: {
							borderColor: 'white',
							background: COLORS.primaryGradient,
							height: '27px',
							width: '27px',
							borderRadius: '8px',
							transform: 'translateY(-4px) translateX(-50%)',
						},
					}}
				/>
			</div>
		</div>
	);
}
