interface Props {
	onClick: () => void;
}

export function CookieDenyBtn({ onClick }: Props) {
	return (
		<button
			onClick={onClick}
			className="bg-white text-black flex items-center justify-center w-[102px] lgx:w-[120px] h-[44px] lgx:h-[50px] rounded-full text-[14px] lgx:text-[16px] font-semibold font-inter"
		>
			Decline
		</button>
	);
}
