import { ToastSoldNotification } from 'components/toasts/ToastSoldNotification';
import { gsap } from 'gsap';
import { useSellInventoryItems, useUserContext } from 'hooks';
import { useSound } from 'hooks/utility/useSound';
import { RedeemSound } from 'pages/OpenBox/assets/sounds';
import { ToastRedeemedNotification } from 'pages/OpenBox/components/ToastRedeemedNotification';
import { useBoxOpeningStoreDesktop } from 'pages/OpenBox/store/useBoxOpeningStoreDesktop';
import { RefObject, useCallback, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useBalanceStore } from 'store/useBalanceStore';
import { shallow } from 'zustand/shallow';

interface Props {
	prizeImgRef: RefObject<HTMLImageElement>;
	parentRef: RefObject<HTMLDivElement>;
}

export function useSellBtn({ parentRef, prizeImgRef }: Props) {
	const { user, load: reloadUser } = useUserContext();
	const { setBalance, setBalanceHighlight, resetBalance } = useBalanceStore(
		(state) => ({
			setBalanceHighlight: state.setHighlight,
			setBalance: state.setBalance,
			resetBalance: state.resetBalance,
		}),
		shallow
	);

	const { setShowWonScreen, setIsBoxOpening, wonPrize, setAutoSpinCount } = useBoxOpeningStoreDesktop(
		(state) => ({
			setShowWonScreen: state.setShowWonScreen,
			setIsBoxOpening: state.setIsBoxOpening,
			wonPrize: state.wonPrize,
			setAutoSpinCount: state.setAutoSpinCount,
		}),
		shallow
	);

	const { play: playRedeemSound } = useSound({ src: RedeemSound, useCleanUp: false });

	const { mutate: sellMutation, isLoading: isSellLoading } = useSellInventoryItems({
		onSuccess: () => {
			reloadUser();
		},
		onError: (error) => {
			console.error('Error selling item:', error);
		},
	});

	const handleSell = useCallback(() => {
		setIsBoxOpening(false);

		resetBalance();

		setAutoSpinCount(1);
		if (wonPrize?.data.inventoryItemId) {
			sellMutation([wonPrize.data.inventoryItemId]);

			const tl = gsap.timeline();
			tl.to(prizeImgRef.current, {
				duration: 0.25,
				scale: 0,
				opacity: 0,
				ease: 'power1.out',
			});
			tl.to(
				parentRef.current,
				{
					duration: 0.25,
					opacity: 0,
					ease: 'power1.out',
					onComplete: () => {
						if (wonPrize) {
							if (wonPrize.data.isRedeemable) {
								playRedeemSound();
								toast(<ToastRedeemedNotification item={wonPrize.data} />);
							} else {
								playRedeemSound();
								toast(<ToastSoldNotification item={wonPrize.data} />);
							}
						}
						setShowWonScreen(false);
					},
				},
				'<'
			);
		}
	}, [
		parentRef,
		playRedeemSound,
		prizeImgRef,
		resetBalance,
		sellMutation,
		setAutoSpinCount,
		setIsBoxOpening,
		setShowWonScreen,
		wonPrize,
	]);

	const handleSellHover = useCallback(() => {
		if (user && wonPrize) {
			setBalance(wonPrize.data.price + user.balance);
			setBalanceHighlight(true);
		}
	}, [setBalance, setBalanceHighlight, user, wonPrize]);

	const handleSellLeave = useCallback(() => {
		resetBalance();
	}, [resetBalance]);

	useEffect(() => {
		return () => setBalanceHighlight(false);
	}, [setBalanceHighlight]);

	return { handleSellLeave, handleSellHover, handleSell, isSellLoading };
}
