import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PrimaryBtn } from 'components/common/buttons/PrimaryBtn';
import { useCurrencyDetection } from 'hooks/currency/useCurrencyPreferences';
import {
	Font25Dollar,
	Font25Euro,
	Font25Pounds,
	Font50Dollar,
	Font50Euro,
	Font50Pounds,
	Font100Dollar,
	Font100Euro,
	Font100Pounds,
	Font250Dollar,
	Font250Euro,
	Font250Pounds,
	Font500Dollar,
	Font500Euro,
	Font500Pounds,
	Font1000Dollar,
	Font1000Euro,
	Font1000Pounds,
} from 'pages/TransactionManager/assets';
import { AmountButton } from 'pages/TransactionManager/mobile/views/PaymentAmount/AmountButton';
import AmountInput from 'pages/TransactionManager/mobile/views/PaymentAmount/AmountInput';
import { BoxBonusDisplay } from 'pages/TransactionManager/mobile/views/PaymentAmount/BonusDisplay';
import { PaymentMethod } from 'pages/TransactionManager/transaction.interface';
import { useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { toValidNumber } from 'utils';

import { FormData } from '../../DepositPopup';

const CURRENCY_IMAGES: Record<string, Record<number, string>> = {
	USD: {
		25: Font25Dollar,
		50: Font50Dollar,
		100: Font100Dollar,
		250: Font250Dollar,
		500: Font500Dollar,
		1000: Font1000Dollar,
	},
	EUR: {
		25: Font25Euro,
		50: Font50Euro,
		100: Font100Euro,
		250: Font250Euro,
		500: Font500Euro,
		1000: Font1000Euro,
	},
	GBP: {
		25: Font25Pounds,
		50: Font50Pounds,
		100: Font100Pounds,
		250: Font250Pounds,
		500: Font500Pounds,
		1000: Font1000Pounds,
	},
};

interface Props {
	isStripeCheckoutLoading: boolean;
}

export function DepositAmountPanel({ isStripeCheckoutLoading }: Props) {
	const { control, watch, setValue, clearErrors } = useFormContext<FormData>();

	const amount = watch('amount');
	const currency = watch('currency');
	const method = watch('paymentMethod');
	const numericAmount = toValidNumber(amount);

	const { data: detectedCurrency, isLoading: isCurrencyLoading } = useCurrencyDetection();

	useEffect(() => {
		if (detectedCurrency) {
			setValue('currency', detectedCurrency);
		}
	}, [detectedCurrency, setValue]);

	function handleButtonAmountClick(amount: number) {
		setValue('amount', amount);
		clearErrors();
	}

	const amountOptions = useMemo(
		() =>
			Object.entries(CURRENCY_IMAGES[currency] || []).map(([key, imgSrc]) => ({
				amount: Number(key),
				imgSrc,
				isSelected: Number(key) === numericAmount,
			})),
		[currency, numericAmount]
	);

	return (
		<div className={`w-full flex flex-col items-center gap-y-[15px] px-4 `}>
			<div className="flex flex-col w-full">
				<AmountInput
					control={control}
					name="amount"
					selectedCurrency={isCurrencyLoading ? '...' : currency}
					onCurrencyChange={(cur) => setValue('currency', cur)}
					excludedCurrencies={method === PaymentMethod.KLARNA ? ['USD'] : undefined}
				/>
				<span className="mt-[7px] font-semibold text-[14px] text-[rgba(255,255,255,0.7)]">Min. 15 - Max. 100 000</span>
				<div className={`flex-wrap flex w-full pt-[11px] pb-[4px] pr-[4px] gap-[12px] items-center justify-center`}>
					{amountOptions.map(({ amount, imgSrc, isSelected }) => (
						<AmountButton
							key={amount}
							amount={amount}
							imgSrc={imgSrc}
							isSelected={isSelected}
							onClick={() => handleButtonAmountClick(amount)}
						/>
					))}
				</div>
			</div>

			<div className="w-full flex flex-col gap-y-[10px] mt-[10px]">
				<BoxBonusDisplay amount={numericAmount} />

				<PrimaryBtn
					type="submit"
					style={{
						height: '55px',
						borderRadius: '24px',
						marginTop: '20px',
						outlineWidth: '4px',
						marginLeft: '4px',
						width: 'calc(100% - 8px)',
					}}
					content={
						<div className="flex items-center justify-center gap-x-[5px]">
							{isStripeCheckoutLoading ? (
								<FontAwesomeIcon icon={faSpinner} className="animate-spin text-white" />
							) : (
								<>
									<span className="font-inter">Deposit</span>
									<span className="font-inter">{numericAmount}</span>
									<span className="font-inter">{currency}</span>
								</>
							)}
						</div>
					}
				/>
				<span className="mt-[20px] text-[rgba(255,255,255,0.7)] w-full text-center font-semibold">
					Need help? Feel free to <button className="custom-intercom-launcher font-bold text-white">contact us!</button>
				</span>
			</div>
		</div>
	);
}
