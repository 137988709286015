import { PrimaryBtn } from 'components/common/buttons/PrimaryBtn';
import { MobileEmailInput } from 'components/common/inputs/MobileEmailInput';
import { ToastErrorNotification } from 'components/toasts/ToastErrorNotification';
import { COLORS } from '../../../../../constants';
import { useResetPasswordMail } from 'pages/authentication/hooks/useResetPasswordMail';
import { useAuthenticationStore } from 'pages/authentication/store/useAuthenticationStore';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

export function MobileResetPasswordEmailStep() {
	const { setEmailForPasswordReset } = useAuthenticationStore();

	const navigate = useNavigate();

	const { mutate: requestResetPasswordMail, isLoading } = useResetPasswordMail({
		onSuccess: () => {
			navigate('/auth/reset-password/verification-code');
		},
		onError: (message) => {
			toast(<ToastErrorNotification message={message} />, {
				style: { backgroundColor: COLORS.blue90 },
			});
		},
	});

	const { executeRecaptcha } = useGoogleReCaptcha();

	const {
		register,
		handleSubmit,
		formState: { errors },
		resetField,
		watch,
	} = useForm<{ email: string }>();

	const handleSendRecoveryCode: SubmitHandler<{ email: string }> = async (data) => {
		if (!executeRecaptcha) {
			toast(<ToastErrorNotification message="reCAPTCHA is temporarily unavailable. Please try again later" />);
			return;
		}

		try {
			const recaptchaToken = await executeRecaptcha('recover-password-email');

			setEmailForPasswordReset(data.email);
			requestResetPasswordMail({ email: data.email, recaptchaToken });
		} catch (error) {
			console.error('reCAPTCHA error:', error);
			toast(<ToastErrorNotification message="reCAPTCHA is temporarily unavailable. Please try again later" />);
		}
	};

	return (
		<form onSubmit={handleSubmit(handleSendRecoveryCode)} className="w-full flex flex-col items-center gap-y-[16px]">
			<div className="flex flex-col mb-[10px]">
				<h1 className="text-white font-bold text-[26px] font-poppins">Recover Account</h1>
				<span className="text-[14px] text-white/80 font-semibold">
					Enter your email and we will send you a recovery code
				</span>
			</div>
			<div className="flex flex-col gap-y-[5px] w-full">
				<span className="text-white/80 font-semibold text-[14px] font-poppins">Email</span>
				<MobileEmailInput
					errors={errors}
					register={register}
					resetField={resetField}
					watch={watch}
					style={{ height: '50px' }}
				/>
			</div>
			<PrimaryBtn type="submit" content="Send" style={{ height: '46px', marginTop: '5px' }} isDisabled={isLoading} />
			<Link
				to="/auth/login"
				className="flex items-center gap-x-[10px] font-semibold text-[14px] text-white/80 mt-[10px]"
			>
				<FontAwesomeIcon icon={faChevronLeft} />
				<span>Back To Login</span>
			</Link>
		</form>
	);
}
