import React, { useState } from 'react';
import { Controller } from 'react-hook-form';

interface AmountInputProps {
	name: string;
	control: any;
	label?: string;
	selectedCurrency: string;
	onCurrencyChange: (name: string) => void;
	currencies?: string[];
	excludedCurrencies?: string[]; // New prop for excluded currencies
}

const AmountInput: React.FC<AmountInputProps> = ({
	name,
	control,
	label = 'Amount',
	selectedCurrency,
	onCurrencyChange,
	currencies = ['USD', 'EUR', 'GBP'],
	excludedCurrencies = [], // Default to an empty array
}) => {
	const [isFocused, setIsFocused] = useState(false);

	// Filtered list of currencies
	const filteredCurrencies = currencies.filter((currency) => !excludedCurrencies.includes(currency));

	return (
		<Controller
			name={name}
			control={control}
			rules={{
				required: 'Amount is required.', // Required validation
				min: { value: 15, message: 'Minimum amount is 15.' }, // Minimum value
				max: { value: 100000, message: 'Maximum amount is 100,000.' }, // Maximum value
				pattern: {
					value: /^[0-9]+(\.[0-9]{1,2})?$/, // Numeric with up to 2 decimals
					message: 'Invalid amount format.',
				},
			}}
			render={({ field, fieldState: { error } }) => (
				<div className="flex flex-col w-full">
					<div className="relative text-black w-full">
						{/* Label */}
						<span
							className={`absolute left-3 transition-all duration-300 font-semibold top-1/2 ${
								isFocused || field.value
									? 'text-[11px] text-[rgba(0,0,0,0.7)] bg-white px-1 translate-y-[-24px]'
									: '-translate-y-1/2'
							}`}
						>
							{label}
						</span>

						{/* Input Field */}
						<input
							{...field}
							type="text"
							onFocus={() => setIsFocused(true)}
							inputMode="numeric"
							onBlur={() => setIsFocused(false)}
							className={`rounded-[8px] w-full h-[60px] pt-[8px] font-semibold px-[20px] pl-4 outline-none border-[1px] ${
								error ? 'border-errorRed' : 'border-transparent'
							}`}
						/>

						{/* Currency Dropdown */}
						<select
							value={selectedCurrency}
							onChange={(e) => onCurrencyChange(e.target.value)} // Update currency in parent
							className="absolute right-3 top-1/2 -translate-y-1/2 font-semibold bg-transparent outline-none appearance-none"
						>
							{filteredCurrencies.map((cur) => (
								<option key={cur} value={cur}>
									{cur}
								</option>
							))}
						</select>
					</div>

					{/* Error Message */}
					{error && <span className="text-errorRed text-[13px] font-semibold mt-[5px]">{error.message}</span>}
				</div>
			)}
		/>
	);
};

export default AmountInput;
