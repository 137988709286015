import { G2ALogo } from 'assets/images';
import { SeparatorWithText } from 'components/Separator/SeparatorWithText';
import { useCurrencyDetection } from 'hooks/currency/useCurrencyPreferences';
import { CURRENCY_IMAGES } from 'pages/TransactionManager/assets';
import { useMemo } from 'react';

import { Header } from '../../components/Header';
import RedeemCodeCheck from './RedeemCodeCheck';

interface Props {
	onBack: () => void;
}

export function GiftCardView({ onBack }: Props) {
	const { data: detectedCurrency } = useCurrencyDetection();

	const amountOptions = useMemo(
		() =>
			Object.entries(CURRENCY_IMAGES[detectedCurrency ?? 'USD'] || []).map(([key, imgSrc]) => ({
				amount: Number(key),
				imgSrc,
			})),
		[detectedCurrency]
	);
	return (
		<div className="flex flex-col items-center gap-y-[30px] h-full justify-between min-h-fit pb-[12px] w-full px-[10px]">
			<Header title="Deposit with Gift Card" onBackClick={onBack} style={{ padding: '0 20px' }} />

			<div className="gap-y-[15px] flex flex-col items-center mt-[-5px] w-full">
				<h2 className="font-bold text-[18px] text-white">Enter your redeem code below</h2>
				<RedeemCodeCheck />
			</div>

			<SeparatorWithText text="or" lineColor="rgba(255, 255, 255, 0.7)" propStyle={{ color: 'white', width: '90%' }} />

			<div className="gap-y-[15px] flex flex-col items-center mt-[-5px]">
				<h2 className="font-bold text-[19px] text-white">Purchase a Gift Card</h2>
				<div className={`flex-wrap flex w-full pt-[11px] pb-[4px] pr-[4px] gap-[12px] items-center justify-center`}>
					{amountOptions.map(({ amount, imgSrc }) => (
						<button
							onClick={() => {}}
							type="button"
							className={`relative flex items-center justify-center min-w-[110px] h-[72px] border-[2px] border-darkBlue outline outline-white rounded-[20px] bg-primary-gradient outline-[2px]`}
						>
							<img src={G2ALogo} alt="G2A" className="h-[9px] absolute left-2 top-2" />
							<img src={imgSrc} alt={`${amount}`} className="h-[22px] w-auto object-contain mt-[6px]" />
						</button>
					))}
				</div>
			</div>
		</div>
	);
}
