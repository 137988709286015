import { faSearch, faSliders, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SecondaryBtn } from 'components/common/buttons/SecondaryBtn';
import { useWindowDimensions } from 'hooks/utility/layout-measurements/useWindowDimensions';
import { useState } from 'react';
import { shallow } from 'zustand/shallow';

import { screens } from '../../../constants';
import { useSearchStore } from '../store/SearchStore';
import { FilterPopup } from './FilterPopup';

export function SearchInputField() {
	const { screenWidth } = useWindowDimensions();

	const { searchQuery, setSearchQuery } = useSearchStore(
		(state) => ({
			searchQuery: state.searchQuery,
			setSearchQuery: state.setSearchQuery,
		}),
		shallow
	);

	const [showFilterPopup, setShowFilterPopup] = useState(false);

	return (
		<>
			<FilterPopup isVisible={showFilterPopup} onClose={() => setShowFilterPopup(false)} />

			<div className="grid grid-cols-[15%_70%_15%] items-center w-[100%] mt-[0px] mb-[40px] justify-items-center">
				<span></span>
				<div className="mx-auto relative w-full">
					<FontAwesomeIcon
						icon={faSearch}
						className="text-white text-[18px] absolute left-2 top-[50%] translate-y-[-50%]"
					/>
					<input
						value={searchQuery}
						// eslint-disable-next-line jsx-a11y/no-autofocus
						autoFocus
						onChange={(e) => setSearchQuery(e.target.value)}
						className="text-white focus-within:placeholder-transparent text-center border-b-[3px] border-mrLootBlue w-[100%] bg-transparent py-1 pl-3 pr-8 outline-none text-[1.4rem] font-semibold"
						placeholder={screenWidth > screens.sm.width ? 'Search WinBoxes, Products & Brands' : 'Search'}
					/>
					{searchQuery.length > 0 && (
						<button
							className="text-[rgba(255,255,255,0.7)] hover:text-white absolute right-2 top-[50%] translate-y-[-50%] p-1"
							onClick={() => setSearchQuery('')}
						>
							<FontAwesomeIcon icon={faXmark} className="text-[1.4rem]" />
						</button>
					)}
				</div>
				<SecondaryBtn
					style={{ width: '110px', height: '34px', outlineWidth: '4px' }}
					onClick={() => setShowFilterPopup(true)}
					content={
						<div className="flex items-center gap-x-[7px] text-[15px]">
							<FontAwesomeIcon icon={faSliders} />
							<span>Filter</span>
						</div>
					}
				/>
			</div>
		</>
	);
}
