import { useEffect, useState } from 'react';

interface UseDelayedUnblockProps {
	isBlocked: boolean; // Array of conditions that should block visibility
	delayMs?: number; // Delay duration in milliseconds
}

export function useDelayedUnblock({ isBlocked, delayMs = 4500 }: UseDelayedUnblockProps): boolean {
	// State to track if display is unblocked
	const [isUnblocked, setIsUnblocked] = useState(true);

	useEffect(() => {
		let timer: NodeJS.Timeout | null = null;

		if (isBlocked) {
			// Immediately block display
			setIsUnblocked(false);
		} else {
			// Introduce delay before unblocking display
			timer = setTimeout(() => setIsUnblocked(true), delayMs);
		}

		// Cleanup any active timer on unmount or dependency change
		return () => {
			if (timer) {
				clearTimeout(timer);
			}
		};
	}, [isBlocked, delayMs]); // Reacts to condition or delay changes

	return isUnblocked;
}
