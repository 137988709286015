import { MobileSearchInput } from 'components/common/inputs/MobileSearchInput';
import { useCallback, useState } from 'react';
import { shallow } from 'zustand/shallow';

import { FilterSlideupSheet } from './components/FilterSlideupSheet';
import { GridSearchBoxes } from './components/GridSearchBoxes';
import { SearchKeywordSuggestions } from './components/SearchKeywordSuggestions';
import { useSearchStore } from './store/SearchStore';

export function MobileSearch() {
	const [isFilterSlideupVisible, setIsFilterSlideupVisible] = useState(false);

	const { searchQuery, setSearchQuery } = useSearchStore(
		(state) => ({ searchQuery: state.searchQuery, setSearchQuery: state.setSearchQuery }),
		shallow
	);

	const handleFilterSlideupClose = useCallback(() => {
		setIsFilterSlideupVisible(false);
	}, []);

	return (
		<>
			<FilterSlideupSheet isVisible={isFilterSlideupVisible} onClose={handleFilterSlideupClose} />

			<div className="flex flex-col space-y-[15px] pb-[30px]">
				<div className="relative w-full h-[49px]">
					<MobileSearchInput
						value={searchQuery}
						onChange={setSearchQuery}
						placeholder="Search Products, Boxes, Brands .."
						onFilterClick={() => setIsFilterSlideupVisible(true)}
						showFilterBtn={true}
					/>
				</div>

				<SearchKeywordSuggestions />

				<GridSearchBoxes />
			</div>
		</>
	);
}
