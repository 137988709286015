import { MobileBannerSection } from 'components/common/MobileBanners';

import { LiveDrops } from './mobile/components/LiveDrops';
import { RecommendedBoxCarousels } from './mobile/RecommendedBoxCarousels/RecommendedBoxCarousels';

export function MobileHome() {
	return (
		<div className="flex flex-col gap-y-[35px]">
			<MobileBannerSection />

			<div className=" bg-black/30 relative -left-mobile-side-padding w-screen">
				<LiveDrops />
			</div>
			<RecommendedBoxCarousels />
		</div>
	);
}
