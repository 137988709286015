import { loadStripe } from '@stripe/stripe-js';
import axios from 'axios';
import { useMutation } from 'react-query';

import { API_DOMAIN } from '../../../constants';
import { PaymentMethod } from '../transaction.interface';

// Load Stripe.js
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_SITE_KEY!);

// API Request to Create Stripe Checkout Session
async function requestStripeCheckoutCreation(amount: number, currency = 'USD', method: PaymentMethod): Promise<string> {
	const usedMethod = method === PaymentMethod.APPLE_GOOGLE_PAY ? 'card' : method;
	try {
		const response = await axios.post(
			`${API_DOMAIN.mrlootBackend}/api/payment/stripe/create-checkout-session`,
			{ amount, currency, method: usedMethod },
			{ withCredentials: true }
		);

		if (response.data.status === 'success') {
			return response.data.data; // Ensure sessionId exists
		}

		throw new Error(response.data.message || 'Unable to create a checkout session. Please try again later.');
	} catch (err) {
		if (axios.isAxiosError(err)) {
			const message =
				err.response?.data?.message ||
				err.message ||
				'We encountered a network issue while creating your checkout session. Please check your connection and try again.';
			throw new Error(message);
		}
		throw new Error('An unexpected error occurred. Please try again.');
	}
}

interface CallbackProps {
	onSuccess?: (sessionId: string) => void;
	onError?: (error: Error) => void;
}

interface MutateProps {
	amount: number;
	currency?: string;
	method: PaymentMethod;
}

// Custom Hook
export function useStripeCheckoutCreator({ onError, onSuccess }: CallbackProps = {}) {
	return useMutation(
		async ({ amount, currency = 'USD', method }: MutateProps) => {
			const sessionId = await requestStripeCheckoutCreation(amount, currency, method);

			if (!sessionId) {
				throw new Error('Failed to retrieve Stripe Checkout session ID');
			}

			const stripe = await stripePromise;
			if (!stripe) {
				throw new Error('Stripe failed to load');
			}

			const result = await stripe.redirectToCheckout({ sessionId });

			if (result.error) {
				throw new Error(result.error.message);
			}

			return sessionId; // Returning for potential use in the onSuccess callback
		},
		{
			onSuccess: (sessionId) => {
				onSuccess?.(sessionId);
			},
			onError: (error: Error) => {
				onError?.(error);
			},
		}
	);
}
