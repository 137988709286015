import { useAutoAnimate } from '@formkit/auto-animate/react';
import { BoxIcon, FontClaim, FreeFont } from 'assets/images';
import { useWindowDimensions } from 'hooks';
import { useInventoryBoxes } from 'pages/inventory/hooks/useInventoryBoxes';
import { InventoryOrderOptions } from 'pages/inventory/inventoryUI.interface';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { usePopupStore } from 'store/usePopupStore';
import { getBoxLink, isMobile } from 'utils';
import { shallow } from 'zustand/shallow';

export function StickySignupBonusBoxLink() {
	const isBonusBoxToOpen = usePopupStore((state) => state.isBonusBoxToOpen, shallow);

	const { data: inventoryBoxes } = useInventoryBoxes({ order: InventoryOrderOptions.OLDEST });

	const location = useLocation();
	const { screenWidth } = useWindowDimensions();
	const [parent] = useAutoAnimate();

	const showStickyBtn =
		isBonusBoxToOpen &&
		!!inventoryBoxes &&
		inventoryBoxes.length > 0 &&
		!inventoryBoxes.map((box) => getBoxLink(box.boxId.name)).some((path) => location.pathname.includes(path));

	return (
		<div
			ref={parent}
			className={`${showStickyBtn ? 'block' : 'hidden'} fixed z-30 w-[155px] h-[65px] ${isMobile(screenWidth) ? 'bottom-[90px] right-2 ' : 'bottom-6  right-3'}`}
		>
			{showStickyBtn && (
				<Link
					className="w-full h-full bg-primary-gradient border-[2px] border-darkBlue outline outline-white outline-[2px] flex items-center justify-center gap-x-[8px] rounded-[20px]"
					to={getBoxLink(inventoryBoxes[0].boxId.name)}
				>
					<div className="flex flex-col">
						<img src={FreeFont} alt="" className="h-[18px] object-contain" />
						<img src={BoxIcon} alt="" className="h-[28px] object-contain mt-[1px]" />
					</div>
					<img src={FontClaim} className="h-[22px] object-contain" alt="Claim" />
				</Link>
			)}
		</div>
	);
}
