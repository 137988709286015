import { useUserContext } from 'hooks';
import { usePaymentTransactions } from 'hooks/data/transactions/usePaymentTransactions';
import { useCallback, useEffect, useRef } from 'react';
import { usePopupStore } from 'store/usePopupStore';
import { shallow } from 'zustand/shallow';

const SHOW_DEPOSIT_BONUS_POPUP_DELAY = 70000; // 70 seconds
// eslint-disable-next-line no-magic-numbers
const SHOW_DEPOSIT_BONUS_POPUP_INTERVAL = 8 * 60 * 1000; // 10 minutes in milliseconds

export const useDepositBonusPopupScheduler = () => {
	const { setIsDepositBonusPopupVisible } = usePopupStore(
		(state) => ({
			setIsDepositBonusPopupVisible: state.setIsDepositBonusPopupVisible,
		}),
		shallow
	);

	const { user } = useUserContext();
	const { data: paymentHistory, refetch: refetchPaymentHistory } = usePaymentTransactions();

	// Ref to prevent duplicate timers
	const timersSetRef = useRef(false);

	// Function to handle popup display logic
	const handlePopupDisplay = useCallback(async () => {
		// Refetch transactions shortly before showing the popup
		try {
			const { data } = await refetchPaymentHistory();
			if (data?.length === 0) {
				setIsDepositBonusPopupVisible(true);
			}
		} catch (err) {
			console.error('Failed to refetch payment transactions:', err);
		}
	}, [refetchPaymentHistory, setIsDepositBonusPopupVisible]);

	useEffect(() => {
		// Ensure user is logged in and payment history is fetched
		if (!!user && paymentHistory && paymentHistory.length === 0 && !timersSetRef.current) {
			// Mark timers as set to prevent re-creation
			timersSetRef.current = true;

			let initialTimer: number | null = null;
			let interval: number | null = null;

			// Initial delay of 70 seconds
			initialTimer = window.setTimeout(async () => {
				await handlePopupDisplay();
			}, SHOW_DEPOSIT_BONUS_POPUP_DELAY);

			// Set interval to show popup every 10 minutes
			interval = window.setInterval(async () => {
				await handlePopupDisplay();
			}, SHOW_DEPOSIT_BONUS_POPUP_INTERVAL);

			// Cleanup on component unmount
			return () => {
				if (initialTimer) {
					window.clearTimeout(initialTimer);
				}
				if (interval) {
					window.clearInterval(interval);
				}
			};
		}
	}, [setIsDepositBonusPopupVisible, user, paymentHistory, refetchPaymentHistory, handlePopupDisplay]);
};
