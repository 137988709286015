import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LogoBlue } from 'assets/images';
import { Link, useNavigate } from 'react-router-dom';

interface Props {
	onClose?: () => void;
}

export function LoginHeader({ onClose }: Props) {
	const navigate = useNavigate();

	function handleCloseClick() {
		if (onClose) {
			onClose();
			return;
		}
		navigate('/');
	}

	return (
		<div className="flex items-center justify-between">
			<Link to="/">
				<img src={LogoBlue} className="h-[29px]" />
			</Link>

			<button
				type="button"
				onClick={handleCloseClick}
				className="text-white/70 bg-[rgba(0,0,0,0.25)] rounded-[12px] flex items-center justify-center w-[33px] h-[33px]"
			>
				<FontAwesomeIcon icon={faXmark} />
			</button>
		</div>
	);
}
