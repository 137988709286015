import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

import { CookieMrloot, Cookies } from './assets';
import { CookieAcceptBtn } from './components/CookieAcceptBtn';
import { CookieDenyBtn } from './components/CookieDenyBtn';

interface Props {
	onAccept: () => void;
	onDecline: () => void;
}

export function DesktopCookieBanner({ onAccept, onDecline }: Props) {
	return (
		<div className="fixed z-40 bottom-[30px] max-w-[1100px] w-[90%] ml-[32px] left-[50%] translate-x-[-50%] bg-mrLootBlue border-[2px] border-white rounded-[20px] flex items-center py-[20px] justify-between px-[15px]">
			<img
				src={CookieMrloot}
				className="absolute left-[10px] top-[-2px] translate-y-[-100%] h-[150px] object-contain"
				alt=""
			/>

			<div className="flex items-center gap-x-[15px] lg:gap-x-[22px]">
				<img src={Cookies} alt="" className="h-[40px] object-contain" />
				<p className="text-white text-[14px] font-semibold max-w-[77%]">
					Our website uses cookies to improve user experience. See our{' '}
					<Link to="cookie-policy" className="underline">
						Privacy Policy
					</Link>
					.
				</p>
			</div>
			<div className="flex items-center gap-x-[15px] lg:gap-x-[18px]">
				<CookieAcceptBtn onClick={onAccept} />
				<CookieDenyBtn onClick={onDecline} />
				<button onClick={onDecline} className="text-[16px] text-white">
					<FontAwesomeIcon icon={faXmark} />
				</button>
			</div>
		</div>
	);
}
