import { MobileEmailInput } from 'components/common/inputs/MobileEmailInput';
import { MobilePasswordInput } from 'components/common/inputs/MobilePasswordInput';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useUserContext } from 'hooks';
import { PrimaryBtn } from 'components/common/buttons/PrimaryBtn';
import { MobileCheckBox } from 'components/common/inputs/MobileCheckbox';
import { useRegister } from '../../../hooks/useRegister';
import { ToastErrorNotification } from 'components/toasts/ToastErrorNotification';
import { FontRegister } from 'assets/images';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

interface RegisterFormCredentials {
	email: string;
	password: string;
	passwordRepeat: string;
	hasAcceptedTerms: string;
	acceptsMarketingEmails: boolean;
}

export function MobileRegisterForm() {
	const navigate = useNavigate();
	const { load: loadUser } = useUserContext();

	const { executeRecaptcha } = useGoogleReCaptcha();

	const { executeRegistrationWithBonusCheck, isLoading } = useRegister({
		onSuccess: () => {
			loadUser();
			navigate('/successful-register');
		},
		onError: (message) => {
			toast(<ToastErrorNotification message={message ?? 'Something went wrong'} />);
		},
	});

	const {
		register,
		setError,
		clearErrors,
		handleSubmit,
		formState: { errors },
		resetField,
		control,
		watch,
	} = useForm<RegisterFormCredentials>();

	const handleSignUp: SubmitHandler<RegisterFormCredentials> = async (data) => {
		if (data.password !== data.passwordRepeat) {
			setError('passwordRepeat', {
				type: 'manual',
				message: 'Passwords do not match',
			});
			return;
		}
		if (!data.hasAcceptedTerms) {
			setError('hasAcceptedTerms', {
				type: 'manual',
				message: 'You need to accept Terms and Conditions',
			});
			return;
		}
		clearErrors('passwordRepeat');

		// reCAPTCHA validation
		if (!executeRecaptcha) {
			toast(<ToastErrorNotification message="reCAPTCHA is temporarily unavailable. Please try again later" />);
			return;
		}

		try {
			const recaptchaToken = await executeRecaptcha('register');
			executeRegistrationWithBonusCheck({ ...data, recaptchaToken });
		} catch (error) {
			console.error('reCAPTCHA error:', error);
			toast(<ToastErrorNotification message="reCAPTCHA is temporarily unavailable. Please try again later" />);
		}
	};

	return (
		<>
			<form onSubmit={handleSubmit(handleSignUp)} className="w-full space-y-[40px]">
				<div className="space-y-[16px] flex flex-col">
					<div className="flex flex-col items-start mb-[10px]">
						<h1 className="text-white font-bold text-[26px] font-poppins ">Create your account</h1>
						<span className="text-white text-[14px] font-semibold">
							<span className="font-poppins text-white/80">Already have an account?</span>
							<Link to="/auth/login" className="text-[17px] font-bold ml-[5px] font-poppins">
								Login
							</Link>
						</span>
					</div>

					<div className="flex flex-col gap-y-[5px]">
						<span className="text-white/80 font-semibold text-[14px] font-poppins">Email</span>
						<MobileEmailInput<RegisterFormCredentials>
							register={register}
							resetField={resetField}
							watch={watch}
							errors={errors}
						/>
					</div>
					<div className="flex flex-col gap-y-[5px]">
						<span className="text-white/80 text-[14px] font-semibold font-poppins">Password</span>
						<MobilePasswordInput<RegisterFormCredentials> register={register} errors={errors} placeholder="Password" />
					</div>
					<div className="flex flex-col gap-y-[5px]">
						<span className="text-white/80 font-semibold text-[14px] font-poppins">Repeat Password</span>
						<MobilePasswordInput<RegisterFormCredentials>
							registerFieldName="passwordRepeat"
							register={register}
							errors={errors}
							placeholder="Repeat Password"
						/>
					</div>

					<div className="flex items-center gap-x-[16px] pt-[5px]">
						<MobileCheckBox<RegisterFormCredentials>
							register={register}
							control={control}
							errors={errors}
							fieldName="acceptsMarketingEmails"
							strokeColor="white"
						/>
						<label
							htmlFor="acceptsMarketingEmails"
							className="text-[14px] text-white/80 flex items-center justify-center font-semibold"
						>
							<span>I would like to receive free boxes, discounts, promotions and informations via email.</span>
						</label>
					</div>

					<div className="flex items-center gap-x-[16px]">
						<MobileCheckBox<RegisterFormCredentials>
							register={register}
							control={control}
							errors={errors}
							fieldName="hasAcceptedTerms"
							strokeColor="white"
						/>
						<label
							htmlFor="hasAcceptedTerms"
							className="text-[14px] text-white/80 flex items-center justify-center font-semibold"
						>
							<span className="font-poppins">I accept the </span>
							<Link className="text-white text-[16px] ml-[5px] font-poppins font-bold" to="/terms-of-service">
								Terms of Service*
							</Link>
						</label>
					</div>
				</div>
				<PrimaryBtn
					content={<img src={FontRegister} alt="register" className="h-[24px] object-contain" />}
					type="submit"
					isDisabled={isLoading}
					style={{ borderRadius: '40px' }}
					innerContentStyle={{ borderRadius: '40px' }}
				/>
			</form>
		</>
	);
}
