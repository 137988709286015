export function MenuIcon() {
	return (
		<div className="grid grid-cols-[7px_7px] lgx:grid-cols-[13px_13px] gap-[3px] lgx:gap-[5px] items-center justify-center">
			{Array.from({ length: 4 }, (_, index) => (
				<div
					key={index}
					className="rounded-[2px] lgx:rounded-[3px] border-[1px] border-[#9d9d9d] w-[7px] lgx:w-[13px] h-[7px] lgx:h-[13px]"
				></div>
			))}
		</div>
	);
}
