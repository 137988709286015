import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	Boxes25DollarBonus,
	Boxes50DollarBonus,
	Boxes100DollarBonus,
	Boxes250DollarBonus,
	Boxes500DollarBonus,
	Boxes1000DollarBonus,
} from 'assets/images';
import { FontAvailableBonus } from 'pages/TransactionManager/assets';
import { useDepositBonus } from 'pages/TransactionManager/hooks/useDepositBonus';
import { useEffect, useState } from 'react';

import { BonusCard } from '../../components/BonusCard';
import { WelcomeBonusCard } from '../../components/WelcomeBonusCard';

const welcomeBonusBoxMap = {
	25: {
		img: Boxes25DollarBonus,
		heading: '2 FREE WinBoxes',
		content: 'Unbox iPhones, Playstations, Gift Cards, and More',
	},
	50: {
		img: Boxes50DollarBonus,
		heading: '3 FREE WinBoxes',
		content: 'Unbox Jordans, Gift Cards, Looties, and More',
	},
	100: {
		img: Boxes100DollarBonus,
		heading: '5 FREE WinBoxes',
		content: 'Unbox a Next-Gen Console, University Tuition Pay-Off, Gold, and More',
	},
	250: {
		img: Boxes250DollarBonus,
		heading: '12 FREE WinBoxes',
		content: 'Unbox iPhones, Gaming Equipment, Gift Cards, and More',
	},
	500: {
		img: Boxes500DollarBonus,
		heading: '5 FREE WinBoxes',
		content: 'Unbox Luxury Cars, Designer Watches, Crypto, and More',
	},
	1000: {
		img: Boxes1000DollarBonus,
		heading: '12 FREE WinBoxes',
		content: 'Unbox a Mansion, Limited-Edition Nikes, Looties, and More',
	},
};

const getNextSmallerWelcomeBonusBox = (amount: number | null) => {
	if (!amount) {
		return undefined;
	}

	const keys = Object.keys(welcomeBonusBoxMap).map(Number);
	const maxKey = Math.max(...keys.filter((key) => key <= amount));
	return maxKey ? welcomeBonusBoxMap[maxKey as keyof typeof welcomeBonusBoxMap] : null;
};

interface Props {
	amount: number | null;
}

export function BoxBonusDisplay({ amount }: Props) {
	const { data: bonus, isLoading: isBonusLoading } = useDepositBonus({ amount: amount ?? 0 });

	// Track if we've completed the first fetch
	const [areBoniAvailable, setAreBoniAvailable] = useState(false);

	useEffect(() => {
		if (!isBonusLoading && !!bonus) {
			console.log(bonus);
			setAreBoniAvailable(true);
		}
	}, [isBonusLoading, bonus]);

	// Get the matching bonus box
	const bonusBox = getNextSmallerWelcomeBonusBox(amount);

	if (!areBoniAvailable && isBonusLoading) {
		return (
			<div className="w-full flex items-center justify-center h-[50px]">
				<FontAwesomeIcon icon={faSpinner} className="text-[24px] text-white animate-spin" />
			</div>
		);
	}

	// Hide component initially before fetching is complete and no bonus exists
	if (!areBoniAvailable) {
		return null;
	}

	return (
		<div className="flex flex-col gap-y-[15px]">
			<div className="flex items-center gap-x-[8px]">
				<div className="flex flex-col items-start gap-y-[5px] w-full">
					<img src={FontAvailableBonus} alt="Available Bonus" className="h-[36px] object-contain mr-auto" />
				</div>
			</div>

			<div className="w-full flex items-center justify-start max-h-[280px]">
				{isBonusLoading ? (
					<FontAwesomeIcon icon={faSpinner} className="text-white text-[24px] animate-spin mx-auto" />
				) : bonus?.boxes ? ( // Use optional chaining
					bonus.type === 'Welcome Bonus' && bonusBox ? (
						<WelcomeBonusCard content={bonusBox.content} heading={bonusBox.heading} img={bonusBox.img} />
					) : (
						<BonusCard bonus={bonus} />
					)
				) : (
					<div className="flex items-center gap-x-[8px] tracking-wide rounded-[20px] justify-center text-[#ffd653] font-semibold w-full px-[7px] py-[20px] bg-[#ffd7533c]">
						<span className="text-center text-[15px] ms:text-[16px] font-semibold">
							Increase Your Deposit to Unlock a Bonus of FREE WinBoxes!
						</span>
					</div>
				)}
			</div>
		</div>
	);
}
