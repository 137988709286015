import { useKeywordSuggestionBoxSearch } from 'hooks/data/box/useKeywordSuggestionBoxSearch';
import React from 'react';
import { shallow } from 'zustand/shallow';

import { useSearchStore } from '../store/SearchStore';

export function SearchKeywordSuggestions() {
	const { searchQuery, setSearchQuery } = useSearchStore(
		(state) => ({
			searchQuery: state.searchQuery,
			setSearchQuery: state.setSearchQuery,
		}),
		shallow
	);

	const { data: keywordSuggestions } = useKeywordSuggestionBoxSearch({
		query: searchQuery,
	});

	return (
		<div className="min-h-[50px] sm:min-h-[65px] ms:flex items-start mb-[23px] space-x-[12px]">
			<h2 className="hidden sm:block text-[16px] text-[rgba(255,255,255,0.7)] font-semibold min-w-fit">
				More to discover:
			</h2>
			<div className="flex items-center flex-wrap gap-y-[1px] gap-x-[6px] sm:gap-x-[8px] sm:gap-y-[8px]">
				{keywordSuggestions?.map((keyword, index) => (
					<React.Fragment key={keyword}>
						<button
							onClick={() => setSearchQuery(keyword)}
							className="text-white text-[13px] sm:text-[16px] break-words font-semibold"
						>
							{keyword}
						</button>
						{index !== keywordSuggestions.length - 1 && <span className="text-[rgba(255,255,255,0.7)]">|</span>}
					</React.Fragment>
				))}
			</div>
		</div>
	);
}
