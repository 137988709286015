import { FontClaim, FontClaimFreeWinbox, FontQuestionMark } from 'assets/images';
import { AmountDisplayWithCurrency } from 'components/common/AmountDisplayWithCurrency';
import { PrimaryBtn } from 'components/common/buttons/PrimaryBtn';
import { Counter } from 'components/common/Counter';
import { useBoxOpeningStoreMobile } from 'pages/OpenBox/store/useBoxOpeningStoreMobile';
import { useCallback, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { usePopupStore } from 'store/usePopupStore';
import { shallow } from 'zustand/shallow';

import { CLOUDFRONT_IMG_URL } from '../../../constants';
import { WithPrimaryPopup } from '../../common/popups/WithPrimaryPopup';
import { useDelayedUnblock } from '../hooks/useDelayedUnblock';

export function MobileSignupBoxBonusPopup() {
	const { isSignupBonusPopupVisible, setIsSignupBonusPopupVisible, signupBonusExpiredAt } = usePopupStore(
		(state) => ({
			isSignupBonusPopupVisible: state.isSignupBonusPopupVisible,
			setIsSignupBonusPopupVisible: state.setIsSignupBonusPopupVisible,
			signupBonusExpiredAt: state.signupBonusExpiredAt,
		}),
		shallow
	);

	const navigate = useNavigate();
	const location = useLocation();

	const isBoxOpening = useBoxOpeningStoreMobile((state) => state.isBoxOpening, shallow);

	// block display with a delay after box open and auth exit
	const isPopupUnblocked = useDelayedUnblock({ isBlocked: location.pathname.includes('auth') || isBoxOpening });

	const isComponentVisible = useMemo(
		() => isSignupBonusPopupVisible && isPopupUnblocked,
		[isPopupUnblocked, isSignupBonusPopupVisible]
	);

	const handlePopupClose = useCallback(() => {
		setIsSignupBonusPopupVisible(false);
	}, [setIsSignupBonusPopupVisible]);

	return (
		<WithPrimaryPopup
			onClose={handlePopupClose}
			isExternallyVisible={isComponentVisible}
			useAnimation={true}
			childrenWrapperStyle={{ maxHeight: '85svh' }}
		>
			<div className="flex flex-col items-center gap-y-[25px] w-[280px]">
				<img src={FontClaimFreeWinbox} alt="claim your free box" className="h-[110px] w-auto object-contain" />
				<div className="relative aspect-[0.65/1] w-[190px] rounded-[18px] outline outline-[1px] outline-white overflow-hidden">
					{signupBonusExpiredAt && (
						<div className="absolute z-10 flex items-center justify-center right-0 top-0 w-[100px] h-[45px] bg-mrLootBlue rounded-[0_0_0_24px]">
							<Counter expirationDate={signupBonusExpiredAt} />
						</div>
					)}
					<img
						src={FontQuestionMark}
						className="absolute z-20 left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2 h-[85px]"
						alt="?"
					/>

					<div className="blur-[10px]">
						<img
							src={`${CLOUDFRONT_IMG_URL}/boxes/MrLoots Favorites/withoutTitlePortrait_qualityMedium_7e2ae27a3765830925c51407ad1339f0d295277c27c1f93f683f3b9dc73a1ad1.webp?v=0`}
							alt=""
							className="h-full w-full object-cover"
						/>
					</div>
					<div className="absolute px-[18px] h-[40px] min-w-[120px] w-fit rounded-[24px_24px_0_0] left-[50%] bottom-0 translate-x-[-50%] bg-[#2377F4] flex items-center pt-[5px] justify-center space-x-[5px] text-white font-semibold">
						<AmountDisplayWithCurrency
							amount={22}
							amountStyle={{
								letterSpacing: '0.5px',
								fontSize: '20px',
								fontWeight: 'bold',
								textDecoration: 'line-through',
							}}
							imgStyle={{ height: '22px' }}
							useDivWrapper={false}
						/>
					</div>
				</div>

				<div className="flex flex-col items-center gap-y-[12px]">
					<PrimaryBtn
						style={{ height: '55px', borderRadius: '24px' }}
						onClick={() => {
							setIsSignupBonusPopupVisible(false);
							navigate('/auth/register');
						}}
						innerContentStyle={{ borderRadius: '24px' }}
						content={<img src={FontClaim} alt="claim your bonus now!" className="h-[25px] object-contain" />}
					/>
					<span className="text-[14px] font-semibold text-center text-[rgba(255,255,255,0.7)]">
						Register now and get your bonus instantly
					</span>
				</div>
			</div>
		</WithPrimaryPopup>
	);
}
