import { SortIcon } from 'assets/icons/SortIcon';
import { DropdownOption } from 'components/common/mobile/Dropdowns/MobileDropdown';
import { DBUserItemWithItemObj } from 'interfaces/InventoryInterface';
import { ItemInterface } from 'interfaces/ItemInterfaces';
import { createWithEqualityFn } from 'zustand/traditional';

import { InventoryOrderOptions } from '../inventoryUI.interface';

interface InventoryStoreStateInterface {
	currentSortByOption: DropdownOption;
	selectedUserItems: Map<string, DBUserItemWithItemObj>;
	numLoadedInventoryItems: number;
	searchValue: string;
	selectedItemBalance: number;
	hasRedeemableOnlyItems: boolean;
	activeIndexPresentationMode: number;
	isBoxView: boolean;
	isPresentationMode: boolean;
	itemOfInspectPopup: ItemInterface | null;
	isUnlockAllFeaturePopupVisible: boolean;

	setCurrentSortByOption: (orderby: DropdownOption) => void;
	setSearchValue: (value: string) => void;
	setIsPresentationMode: (isPresentationMode: boolean) => void;
	setNumLoadedInventoryItems: (newNum: number) => void;
	tooglePresentationMode: () => void;
	toogleBoxView: () => void;
	setIsBoxView: (isBoxView: boolean) => void;
	setActiveIndexPresentationMode: (newIndex: number) => void;
	setItemOfInspectPopup: (item: ItemInterface | null) => void;
	setIsUnlockAllFeaturePopupVisible: (isVisible: boolean) => void;

	toggleUserItemSelection: (userItem: DBUserItemWithItemObj) => void;
	resetSelectedUserItems: () => void;
}

export const useInventoryStore = createWithEqualityFn<InventoryStoreStateInterface>(
	(set) => ({
		currentSortByOption: { label: 'Newest', value: InventoryOrderOptions.NEWEST, icon: SortIcon },
		selectedUserItems: new Map(),
		searchValue: '',
		selectedItemBalance: 0,
		hasRedeemableOnlyItems: false,
		isPresentationMode: false,
		activeIndexPresentationMode: 0,
		numLoadedInventoryItems: 0,
		itemOfInspectPopup: null,
		isBoxView: false,
		isUnlockAllFeaturePopupVisible: false,

		setItemOfInspectPopup: (item) => set({ itemOfInspectPopup: item }),
		setCurrentSortByOption: (orderBy: DropdownOption) => set({ currentSortByOption: orderBy }),
		setSearchValue: (value: string) => set({ searchValue: value }),
		setIsPresentationMode: (isPresentationMode) => set({ isPresentationMode }),
		setNumLoadedInventoryItems: (newNum) => set({ numLoadedInventoryItems: newNum }),
		tooglePresentationMode: () =>
			set((state) => ({
				isPresentationMode: !state.isPresentationMode,
			})),
		toogleBoxView: () =>
			set((state) => ({
				isBoxView: !state.isBoxView,
			})),
		setIsBoxView: (isBoxView) => set({ isBoxView }),
		setActiveIndexPresentationMode: (newIndex) => set({ activeIndexPresentationMode: newIndex }),
		setIsUnlockAllFeaturePopupVisible: (isVisible) => set({ isUnlockAllFeaturePopupVisible: isVisible }),

		resetSelectedUserItems: () =>
			set({
				selectedUserItems: new Map(),
				selectedItemBalance: 0,
			}),

		toggleUserItemSelection: (userItem: DBUserItemWithItemObj) =>
			set((state) => {
				const newSelectedItems = new Map(state.selectedUserItems);
				let newSelectedItemBalance = state.selectedItemBalance;

				if (newSelectedItems.has(userItem._id)) {
					newSelectedItems.delete(userItem._id);
					newSelectedItemBalance -= userItem.itemId.price;
				} else {
					newSelectedItems.set(userItem._id, userItem);
					newSelectedItemBalance += userItem.itemId.price;
				}

				const hasRedeemableOnlyItems = Array.from(newSelectedItems.values()).some((item) => item.itemId.isRedeemable);

				return {
					selectedUserItems: newSelectedItems,
					selectedItemBalance: newSelectedItemBalance,
					hasRedeemableOnlyItems,
				};
			}),
	}),
	Object.is
);
