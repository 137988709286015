import { useAutoAnimate } from '@formkit/auto-animate/react';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ItemCardSkeleton } from 'components/cards/item-cards/ItemCardSkeleton';
import { useInfiniteScroll, useUserContext, useWindowDimensions } from 'hooks';
import { InventoryBoxCard } from 'pages/inventory/components/inventoryCards/InventoryBoxCard';
import { useInventoryBoxes } from 'pages/inventory/hooks/useInventoryBoxes';
import { useCallback, useRef } from 'react';

import { screens } from '../../../../constants';
import EmptyInventory from '../../components/EmptyInventory';
import {
	MOBILE_NUM_OF_CARDS_PER_PAGE,
	MOBILE_NUM_OF_PLACEHOLDERS,
	TABLET_NUM_OF_CARDS_PER_PAGE,
	TABLET_NUM_OF_PLACEHOLDERS,
} from '../../inventory.constant';
import { InventoryOrderOptions } from '../../inventoryUI.interface';
import { useInventoryStore } from '../../store/InventoryStore';

export function MobileInventoryBoxGrid() {
	const { searchValue, currentSortByOption } = useInventoryStore((state) => ({
		searchValue: state.searchValue,
		currentSortByOption: state.currentSortByOption,
	}));

	const { screenWidth } = useWindowDimensions();
	const numOfPlaceholders = screens.xs.width < screenWidth ? TABLET_NUM_OF_PLACEHOLDERS : MOBILE_NUM_OF_PLACEHOLDERS;
	const itemsPerPage = screens.xs.width < screenWidth ? TABLET_NUM_OF_CARDS_PER_PAGE : MOBILE_NUM_OF_CARDS_PER_PAGE;

	const lastBoxRef = useRef<HTMLDivElement>(null);
	const [autoAnimateParent] = useAutoAnimate();

	const { user } = useUserContext();

	const {
		data: inventoryBoxes,
		isLoading: isInventoryLoading,
		hasNextPage,
		isFetchingNextPage,
		loadMore,
	} = useInventoryBoxes({
		isDisabled: !user,
		limit: itemsPerPage,
		order: currentSortByOption.value as InventoryOrderOptions,
		query: searchValue,
	});

	const handleLoadOnInfiniteScroll = useCallback(() => {
		if (hasNextPage && !isInventoryLoading) {
			loadMore();
		}
	}, [hasNextPage, isInventoryLoading, loadMore]);

	useInfiniteScroll(lastBoxRef, handleLoadOnInfiniteScroll, inventoryBoxes?.length);

	const isInventoryEmpty = !isInventoryLoading && inventoryBoxes?.length === 0;

	return (
		<div className="flex flex-col space-y-[10px]">
			{isInventoryEmpty && <EmptyInventory needToDeposit={!!user && user.balance === 0} />}

			<div
				ref={autoAnimateParent}
				className="grid grid-cols-2 xs:grid-cols-3 ss:grid-cols-4 gap-x-[30px] gap-y-[30px] pb-[40px]"
			>
				{inventoryBoxes && inventoryBoxes.map((box) => <InventoryBoxCard key={box._id} box={box} />)}
				{isInventoryLoading &&
					Array.from({ length: numOfPlaceholders }, (_, index) => (
						<ItemCardSkeleton key={index} style={{ width: '100%' }} />
					))}
			</div>
			{isFetchingNextPage && <FontAwesomeIcon icon={faSpinner} className="animate-spin text-white text-[18px]" />}
		</div>
	);
}
