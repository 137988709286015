/* eslint-disable max-depth */
/* eslint-disable complexity */
import {
	Font1X,
	Font2X,
	Font3X,
	Font4X,
	Font5X,
	Font6X,
	Font7X,
	Font8X,
	Font9X,
	Font10X,
	NumEight,
	NumEighteen,
	NumEleven,
	NumFifteen,
	NumFifty,
	NumFive,
	NumForty,
	NumFortyEight,
	NumFortyFive,
	NumFortyFour,
	NumFortyNine,
	NumFortyOne,
	NumFortySeven,
	NumFortySix,
	NumFortyThree,
	NumFortyTwo,
	NumFour,
	NumFourteen,
	NumNine,
	NumNineteen,
	NumOne,
	NumSeven,
	NumSeventeen,
	NumSix,
	NumSixteen,
	NumTen,
	NumThirteen,
	NumThirty,
	NumThirtyEight,
	NumThirtyFive,
	NumThirtyFour,
	NumThirtyNine,
	NumThirtyOne,
	NumThirtySeven,
	NumThirtySix,
	NumThirtyThree,
	NumThirtyTwo,
	NumThree,
	NumTwelve,
	NumTwenty,
	NumTwentyEight,
	NumTwentyFive,
	NumTwentyFour,
	NumTwentyNine,
	NumTwentyOne,
	NumTwentySeven,
	NumTwentySix,
	NumTwentyThree,
	NumTwentyTwo,
	NumTwo,
	NumZero,
	Plus1,
	Plus2,
	Plus3,
	Plus4,
	Plus5,
	Plus6,
	Plus7,
	Plus8,
	Plus9,
	Plus10,
	Plus11,
	Plus12,
	Plus13,
	Plus14,
	Plus15,
	Plus16,
	Plus17,
	Plus18,
	Plus19,
	Plus20,
	Plus21,
	Plus22,
	Plus23,
	Plus24,
	Plus25,
	Plus26,
	Plus27,
	Plus28,
	Plus29,
	Plus30,
	Plus31,
	Plus32,
	Plus33,
	Plus34,
	Plus35,
	Plus36,
	Plus37,
	Plus38,
	Plus39,
	Plus40,
	Plus41,
	Plus42,
	Plus43,
	Plus44,
	Plus45,
	Plus46,
	Plus47,
	Plus48,
	Plus49,
	Plus50,
} from 'assets/images';

export function getImageByNumber(num: number): string | undefined {
	const imageMap: { [key: number]: string } = {
		0: NumZero,
		1: NumOne,
		2: NumTwo,
		3: NumThree,
		4: NumFour,
		5: NumFive,
		6: NumSix,
		7: NumSeven,
		8: NumEight,
		9: NumNine,
		10: NumTen,
		11: NumEleven,
		12: NumTwelve,
		13: NumThirteen,
		14: NumFourteen,
		15: NumFifteen,
		16: NumSixteen,
		17: NumSeventeen,
		18: NumEighteen,
		19: NumNineteen,
		20: NumTwenty,
		21: NumTwentyOne,
		22: NumTwentyTwo,
		23: NumTwentyThree,
		24: NumTwentyFour,
		25: NumTwentyFive,
		26: NumTwentySix,
		27: NumTwentySeven,
		28: NumTwentyEight,
		29: NumTwentyNine,
		30: NumThirty,
		31: NumThirtyOne,
		32: NumThirtyTwo,
		33: NumThirtyThree,
		34: NumThirtyFour,
		35: NumThirtyFive,
		36: NumThirtySix,
		37: NumThirtySeven,
		38: NumThirtyEight,
		39: NumThirtyNine,
		40: NumForty,
		41: NumFortyOne,
		42: NumFortyTwo,
		43: NumFortyThree,
		44: NumFortyFour,
		45: NumFortyFive,
		46: NumFortySix,
		47: NumFortySeven,
		48: NumFortyEight,
		49: NumFortyNine,
		50: NumFifty,
	};

	return imageMap[num];
}

export function getXTimesImgByNumber(num: number): string | undefined {
	const imageMap: { [key: number]: string } = {
		1: Font1X,
		2: Font2X,
		3: Font3X,
		4: Font4X,
		5: Font5X,
		6: Font6X,
		7: Font7X,
		8: Font8X,
		9: Font9X,
		10: Font10X,
	};

	return imageMap[num];
}

export function getPlusImageByNumber(num: number): string | undefined {
	const imageMap: { [key: number]: string } = {
		1: Plus1,
		2: Plus2,
		3: Plus3,
		4: Plus4,
		5: Plus5,
		6: Plus6,
		7: Plus7,
		8: Plus8,
		9: Plus9,
		10: Plus10,
		11: Plus11,
		12: Plus12,
		13: Plus13,
		14: Plus14,
		15: Plus15,
		16: Plus16,
		17: Plus17,
		18: Plus18,
		19: Plus19,
		20: Plus20,
		21: Plus21,
		22: Plus22,
		23: Plus23,
		24: Plus24,
		25: Plus25,
		26: Plus26,
		27: Plus27,
		28: Plus28,
		29: Plus29,
		30: Plus30,
		31: Plus31,
		32: Plus32,
		33: Plus33,
		34: Plus34,
		35: Plus35,
		36: Plus36,
		37: Plus37,
		38: Plus38,
		39: Plus39,
		40: Plus40,
		41: Plus41,
		42: Plus42,
		43: Plus43,
		44: Plus44,
		45: Plus45,
		46: Plus46,
		47: Plus47,
		48: Plus48,
		49: Plus49,
		50: Plus50,
	};

	return imageMap[num];
}

/**
 * Calculates the sizes of transparent borders for images that have a visible border surrounding a transparent area.
 *
 * This function is designed for images where the central part is transparent and is surrounded by a potentially
 * opaque border. It takes an `HTMLImageElement` to analyzes the image's pixels from the edges inward to determine
 * the extent of the transparent border area on each side * (top, bottom, left, and right).
 *
 * If the pixels on the edges are completely opaque or the image does not have a transparent area in the middle,
 * the returned border sizes will be `0`.
 *
 * Note: The image must be fully loaded before calling this function. If the image is not loaded, the promise will
 * be rejected with an error stating "The image is not loaded yet". Any other processing errors during execution will
 * also cause the promise to reject with an appropriate error message.
 *
 * @param {HTMLImageElement} htmlImageElement - The image element to analyze for transparent border sizes.
 * @returns {Promise<{top: number; bottom: number; left: number; right: number}>} A promise that, upon successful resolution, returns an object detailing the sizes of the transparent borders around the central area of the image, measured in pixels.
 */
export function calculateBorderSizesOfImg(
	htmlImageElement: HTMLImageElement
): Promise<{ top: number; bottom: number; left: number; right: number }> {
	return new Promise((resolve, reject) => {
		const img = htmlImageElement;

		if (img.complete) {
			try {
				const canvas = document.createElement('canvas');
				const ctx = canvas.getContext('2d', { willReadFrequently: true });
				if (!ctx) {
					reject(new Error('Unable to get canvas drawing context'));
					return;
				}
				canvas.width = img.width;
				canvas.height = img.height;
				ctx.drawImage(img, 0, 0, img.width, img.height);

				let topBorderHeight = 0;
				let bottomBorderHeight = 0;
				let leftBorderWidth = 0;
				let rightBorderWidth = 0;

				let stopCountingTop = false;
				let stopCountingBottom = false;
				let stopCountingLeft = false;
				let stopCountingRight = false;

				const halfWidth = img.width / 2;
				const halfHeight = img.height / 2;

				for (let i = 0; i < Math.max(halfWidth, halfHeight); i++) {
					if (i < halfWidth) {
						if (!stopCountingLeft) {
							const leftData = ctx.getImageData(i, halfHeight, 1, 1).data[3];
							if (leftData !== 0) {
								leftBorderWidth += 1;
							} else {
								stopCountingLeft = true;
							}
						}

						if (!stopCountingRight) {
							const rightData = ctx.getImageData(img.width - i - 1, halfHeight, 1, 1).data[3];
							if (rightData !== 0) {
								rightBorderWidth += 1;
							} else {
								stopCountingRight = true;
							}
						}
					}

					if (i < halfHeight) {
						if (!stopCountingTop) {
							const topData = ctx.getImageData(halfWidth, i, 1, 1).data[3];
							if (topData !== 0) {
								topBorderHeight += 1;
							} else {
								stopCountingTop = true;
							}
						}

						if (!stopCountingBottom) {
							const bottomData = ctx.getImageData(halfWidth, img.height - i - 1, 1, 1).data[3];
							if (bottomData !== 0) {
								bottomBorderHeight += 1;
							} else {
								stopCountingBottom = true;
							}
						}
					}

					if (stopCountingTop && stopCountingBottom && stopCountingLeft && stopCountingRight) {
						break;
					}
				}

				resolve({
					top: topBorderHeight,
					bottom: bottomBorderHeight,
					left: leftBorderWidth,
					right: rightBorderWidth,
				});
			} catch (error) {
				reject(new Error('An error occurred while processing the image'));
			}
		} else {
			reject(new Error('The image is not loaded yet'));
		}
	});
}
